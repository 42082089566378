import React from "react"

import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"
import Accordion from "../../common/components/accordion/accordion"
import AnimatedIllustration from "../../common/components/animated-illustration/animated-illustration"

import TaxesWhatElse from "../taxes-what-else-menu"
import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

import "./close-loopholes-page.css"
import CloseLoopholesPNG from "./illustrations/9.1.png"
import Model from "../../../config/tour/taxes/tour-taxes-close-loopholes-model"

const CloseLoopholesLanding = () => {
	return (
		<ScrollIndicatorWrapper label={Model.LandingSection.scrollIndicatorLabel}>
			<section id="close-loopholes-landing" className="full-page-w-menu is-first-page has-scroll-indicator v-center">
				<div className="split-layout">
					<div className="split-layout-column">
						{Model.LandingSection.headline}
						{Model.LandingSection.body}
					</div>
					<div className="split-layout-column">
						<AnimatedIllustration src={CloseLoopholesPNG} alt={Model.LandingSection.illustrationAltDescription} />
					</div>
				</div>
			</section>
		</ScrollIndicatorWrapper>
	)
}

const CloseLoopholesAccordion = () => {
	return (
		<ScrollIndicatorWrapper label="The effects of closing loopholes">
			<section id="close-loopholes-accordion" className="full-page has-scroll-indicator row-content v-center">
				<div className="content">
					<div className="scroll-anchor scroll-anchor-impl" />
					<h2>{Model.LoopholesAccordionTitle}</h2>
					<Accordion 
						className="scroll-anchor scroll-middle"
						rows={Model.LoopholesAccordionRows}
					/>
				</div>
			</section>
		</ScrollIndicatorWrapper>
	)
}

const CloseLoopholesEffects = () => {
	return (
		<ScrollIndicatorWrapper label={Model.EffectsSection.scrollIndicatorLabel}>
			<section id="close-loopholes-effects" className="full-page has-scroll-indicator row-content v-center is-landing">
				<div className="content">
					<div className="scroll-anchor scroll-anchor-impl" />
					{Model.EffectsSection.headline}
					{Model.EffectsSection.body}
				</div>
			</section>
		</ScrollIndicatorWrapper>
	)
}

const CloseLoopholesPage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="close-loopholes-page-wrapper">
			<div id="tour-taxes-container">
				<div id="close-loopholes-page">
					<CloseLoopholesLanding />
					<CloseLoopholesAccordion />
					<CloseLoopholesEffects />
					<div className="tour-poll-container">
						<TaxesWhatElse isMobile={isMobile}/>
					</div>
				</div>
			</div>
		</SideMenuWrapper>
	)
}

export default CloseLoopholesPage


import React from "react"

import MainMenu from "./components/main-menu/main-menu"
import ResponsiveWrapper from "./components/responsive-wrapper/responsive-wrapper"

import "./static-pages-container.css"
import "./resources-page.css"

const ResourcesPage = ({isMobile}) => (
  <div id="static-pages-container">
    <div id="resources-page">
      <MainMenu isMobile={isMobile} />
      <div className="content">
        <h2>Additional Resources</h2>
        <p>
          Budget and economic data for America Off Balance are from a variety of government sources including:
        </p>
        <ul>
          <li>
            <p>Congressional Budget Office (CBO)</p>
            <ul>
              <li>
                <p>
                  <a href="https://www.cbo.gov/about/products/budget-economic-data#1" target="_blank" rel="noopener noreferrer">Long Term Budget Projections</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.cbo.gov/about/products/budget-economic-data#3" target="_blank" rel="noopener noreferrer">10-Year Budget Projections</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.cbo.gov/about/products/budget-economic-data#7" target="_blank" rel="noopener noreferrer">Detailed Revenue Projections</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.cbo.gov/about/products/budget-economic-data#4" target="_blank" rel="noopener noreferrer">10-Year Economic Projections</a>
                </p>
              </li>
            </ul>
          </li>

          <li>
            <p>Office of Management and Budget (OMB)</p>
            <ul>
              <li>
                <p>
                  <a href="https://www.whitehouse.gov/omb/historical-tables/" target="_blank" rel="noopener noreferrer">Historical Tables</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.whitehouse.gov/omb/supplemental-materials/" target="_blank" rel="noopener noreferrer">Supplemental Materials</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.whitehouse.gov/omb/analytical-perspectives/" target="_blank" rel="noopener noreferrer">Analytical Perspectives</a>
                </p>
              </li>
            </ul>
          </li>

          <li>
            <p>
              Social Security Administration: <a href="https://www.ssa.gov/OACT/TR/2018/lrIndex.html" target="_blank" rel="noopener noreferrer">The OASDI Trustees Report Single-Year Tables</a>
            </p>
          </li>

          <li>
            <p>
              Centers for Medicare and Medicaid Services: <a href="https://www.cms.gov/Research-Statistics-Data-and-Systems/Statistics-Trends-and-Reports/ReportsTrustFunds/index.html" target="_blank" rel="noopener noreferrer">Medicare Trustees Report Expanded and Supplementary Tables and Figures</a>
            </p>
          </li>

          <li>
            <p>
              Census Department: <a href="https://www.census.gov/programs-surveys/cps.html" target="_blank" rel="noopener noreferrer">Current Population Survey (CPS)</a>
            </p>
          </li>

          <li>
            <p>
              Internal Revenue Service: <a href="https://www.irs.gov/statistics/soi-tax-stats-individual-income-tax-return-form-1040-statistics" target="_blank" rel="noopener noreferrer">Statistics of Income (IRS SOI)</a>
            </p>
          </li>

          <li>
            <p>
            Federal Reserve Bank of St. Louis: <a href="https://fred.stlouisfed.org/" target="_blank" rel="noopener noreferrer">Federal Reserve Economic Data (FRED)</a>
            </p>
          </li>

        </ul>
        <p>
          You can learn more about many of the proposed reforms in the Hoover Institution's <a href="https://www.hoover.org/research/blueprint-america" target="_blank" rel="noopener noreferrer">Blueprint for America</a>. Budget estimates for Social Security proposal are from the <a href="https://www.cbo.gov/publication/51011" target="_blank" rel="noopener noreferrer">CBO's Social Security Budget Options, 2015</a>. Estimates for most other reform proposals are derived from CBO's <a href="https://www.cbo.gov/publication/52142" target="_blank" rel="noopener noreferrer">Options to Reduce the Federal Deficit: 2017 to 2026</a>.
        </p>
        <p>
          An overview of the Budget Calculator's estimations is <a href="https://www.dropbox.com/s/ejjhw8dh4kccmtu/America%20Off%20Balance%20Budget%20Calculator%20Documentation.docx?dl=0" target="_blank" rel="noopener noreferrer">available here</a>.
        </p>
        <p>
          Facts and calculations for the Balancing Act are <a href="https://www.dropbox.com/s/5sntldf7a0czuj7/Balancing%20Act%20Documentation.pdf?dl=0" target="_blank" rel="noopener noreferrer">available here</a>. 
        </p>
      </div>
    </div>
  </div>
)

const ResponsiveResourcesPage = () => (
  <ResponsiveWrapper>
    <ResourcesPage />
  </ResponsiveWrapper>
)

export default ResponsiveResourcesPage
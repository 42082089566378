import {round, last} from "lodash"

let Facts = {}

Facts.const = {}
Facts.const.billion  = 10**9
Facts.const.trillion = 10**12

// Static facts

Facts.econ = {}

Facts.econ.projectedDeficitsTrillions = [
   0.779,
   0.891,
   0.878,
   0.952,
   1.05,
   1.11,
   1.14,
   1.2,
   1.18,
   1.16,
   1.32,
   1.4,
   1.52,
   1.65,
   1.79,
   1.94,
   2.1,
   2.27,
   2.46,
   2.63,
   2.8,
   3.02,
   3.21,
   3.45,
   3.67,
   3.93,
   4.18,
   4.44,
   4.76,
   5.07,
   5.44,
   5.79,
]

Facts.econ.projectedDebtTrillions = [
  15.8,
  16.6,
  17.6,
  18.6,
  19.7,
  20.8,
  22.0,
  23.2,
  24.5,
  25.7,
  27.0,
  28.4,
  30.0,
  31.6,
  33.4,
  35.3,
  37.4,
  39.7,
  42.2,
  44.8,
  47.6,
  50.6,
  53.8,
  57.3,
  61.0,
  64.9,
  69.1,
  73.5,
  78.3,
  83.3,
  88.8,
  94.6,
]

Facts.econ.projectedDebtPercentages = [
  78,
  78,
  79,
  81,
  83,
  85,
  86,
  88,
  89,
  90,
  91,
  93,
  94,
  96,
  97,
  99,
  101,
  103,
  105,
  108,
  110,
  113,
  116,
  119,
  121,
  124,
  127,
  130,
  133,
  137,
  140,
  143,
]

Facts.econ.projectedSpendingTrillions = [
  4.11,
  4.40,
  4.56,
  4.79,
  5.06,
  5.31,
  5.58,
  5.84,
  6.13,
  6.40,
  6.75,
  7.07,
  7.42,
  7.79,
  8.20,
  8.61,
  9.06,
  9.51,
  10.0,
  10.5,
  11.0,
  11.5,
  12.1,
  12.7,
  13.3,
  14.0,
  14.7,
  15.3,
  16.2,
  17.0,
  17.9,
  18.8,
]

Facts.econ.projectedRevenueTrillions = [
  3.33,
  3.51,
  3.68,
  3.83,
  4.0,
  4.20,
  4.44,
  4.64,
  4.95,
  5.24,
  5.44,
  5.67,
  5.90,
  6.14,
  6.40,
  6.67,
  6.96,
  7.25,
  7.55,
  7.86,
  8.19,
  8.52,
  8.89,
  9.26,
  9.65,
  10.1,
  10.5,
  11.0,
  11.4,
  11.9,
  12.5,
  13.0,
]


// Source: https://data.worldbank.org/indicator/NY.GDP.MKTP.CD?end=2017&locations=US&start=1970
Facts.econ.historicalGDPTrillions = [
	1.076,
	1.168,
	1.282,
	1.429,
	1.549,
	1.689,
	1.878,
	2.086,
	2.357,
	2.632,
	2.863,
	3.211,
	3.345,
	3.638,
	4.041,
	4.347,
	4.59,
	4.87,
	5.253,
	5.658,
	5.98,
	6.174,
	6.539,
	6.879,
	7.309,
	7.664,
	8.1,
	8.609,
	9.089,
	9.661,
	10.285,
	10.622,
	10.978,
	11.511,
	12.275,
	13.094,
	13.856,
	14.478,
	14.719,
	14.419,
	14.964,
	15.518,
	16.155,
	16.692,
	17.428,
	18.121,
	18.624,
	19.391,
  20.236,
]

Facts.historicalGDPFromYear = 1970
Facts.historicalGDPToYear = 2017

Facts.econ.year = 2018
Facts.econ.projectedYear = 2049
Facts.econ.defenseSpending = 622 * Facts.const.billion
Facts.econ.amtrakBudget = 2 * Facts.const.billion

// Conputed facts

Facts.econ.projectedOverflowYear = (() => {
	const debtP = Facts.econ.projectedDebtPercentages
	for(let i = 0; i < debtP.length; i++) {
		const p = debtP[i]
		if (p >= 100) {
			return Facts.econ.year + i
		}
	}
})()

Facts.econ.income = Facts.econ.projectedRevenueTrillions[0] * Facts.const.trillion
Facts.econ.spent = Facts.econ.projectedSpendingTrillions[0] * Facts.const.trillion

Facts.econ.deficit = Facts.econ.projectedDeficitsTrillions[0] * Facts.const.trillion
Facts.econ.projectedDeficit = last(Facts.econ.projectedDeficitsTrillions) * Facts.const.trillion

Facts.econ.netDebt = Facts.econ.projectedDebtTrillions[0] * Facts.const.trillion
Facts.econ.netDebtGDP = round(Facts.econ.projectedDebtPercentages[0] / 100, 2)

Facts.econ.projectedNetDebt = last(Facts.econ.projectedDebtTrillions) * Facts.const.trillion
Facts.econ.projectedNetDebtGDP = round(last(Facts.econ.projectedDebtPercentages) / 100, 2)
Facts.econ.projectedExcessNetDebt = Facts.econ.projectedNetDebt * (1- 100/last(Facts.econ.projectedDebtPercentages))

Facts.econ.projectedTaxRevenue = last(Facts.econ.projectedRevenueTrillions) * Facts.const.trillion

export default Facts

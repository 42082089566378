// Reference for scroll performance:
// https://gist.github.com/Warry/4254579 found by way of https://gist.github.com/paulmillr/3118943

import React, { Component } from "react"
import { throttle, debounce } from "lodash"
import { Spring, animated, config } from "react-spring"
import { TimingAnimation, Easing } from "react-spring/dist/addons"
import cx from "classnames"
import PropTypes from "prop-types"
import innerHeight from "ios-inner-height"
import remap from "./utils/remap-number"
import ReactTooltip from "react-tooltip"
import safeAreaInsets from "safe-area-insets"

import Const from "../config/constants"
import Facts from "../config/facts"
import Format from "../common/utils/format"
import FormatCounter from "../common/utils/format-counter"
import StepsModel from "./models/steps"
import Circle from "./models/circle"
import SVGUtil from "./utils/svg-util"
import EnclosingCircleForRect from "./utils/enclosing-circle-for-rect"
import SVGSubstractedCircle from "./utils/svg-substracted-circle"
import SVGMeltingCircle from "./utils/svg-melting-circle"
import SVGSteepSlope from "./utils/svg-steep-slope"
import Tooltip from "../common/components/tooltip/tooltip"
import MainMenu from "./main-menu/main-menu"
import ConclusionSection from "./conclusion/conclusion-section"
import CircleLabel from "./components/circle-label"
import GraphYearLegend from "../common/components/graph/graph-year-legend"
import GraphLabel from "../common/components/graph/graph-label"
import FederalDebtLabel from "./components/federal-debt-label"
import SplashIllustration from "./components/splash-illustration"

import SplashLogo from "./splash-logo"
import ScrollIndicator from "./components/scroll-indicator/scroll-indicator"

import ScrollIndicatorIconSVG from "../common/components/scroll-indicator-icon/scroll-indicator-icon"
import RotateDeviceIconSVG from "./components/rotate-device-icon-svg"
import StartIllustrationPNG from "./illustrations/1.1.png"

import Copy from "../config/intro/intro-model"

import "./intro-page.css"

const DEBUG = false

class FixedSplashSection extends Component {

	static propTypes = {
		model: PropTypes.object.isRequired,
		onClick: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
			this.state = {}

			this.remapper = remap({
				from: { low: 0, high: 0.7 },
				to: { low: 0, high: 1 }
			})


			this.topRemapper = remap({
				from: { low: 0, high: 20.0 },
				to: { low: 0, high: 1 }
			})
		}

	render() {
		const model = this.props.model
		const targetOpacity = Math.min(1 - this.remapper(model.getProgress(0)), 1.0)
		const targetTop = Math.min(this.topRemapper(model.getProgress(0)), 1.0) * model.h
		return (
			<Spring native key="part-fixed-splash-spring"
				immediate={true}
				from={{ opacity: 1, top: 0}}
				to={{ opacity: targetOpacity, top: targetTop}}
				config={config.wobbly}>
					{ ({opacity, top}) => (
						<animated.div
							key="fixed-start-section"
							id="fixed-splash-section"
							style={{
								opacity: opacity,
								top: top,
							}}>
							<div className="splash-illustration-container">
								<SplashIllustration alt={Copy.SplashSection.illustrationAltDescription} />
								<div className="splash-logo-container">
									<div className="splash-logo-wrapper">
										<SplashLogo />
										<p>One Nation Under Debt</p>
									</div>
									<div className="splash-scroll-indicator should-bounce" onClick={this.props.onClick}>
										<ScrollIndicatorIconSVG style={{stroke: "white"}} />
									</div>
								</div>
							</div>
						</animated.div>
					)}
			</Spring>
		)
	}
}

class FixedStartSection extends Component {

	static propTypes = {
		model: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {}

		this.remapper = remap({
			from: { low: 0.4, high: 0.9 },
			to: { low: 0, high: 1 }
		})

		this.opacityRemapper = remap({
			from: { low: 0, high: 0.6 },
			to: { low: 0, high: 1 }
		})

		this.illuRemapper = remap({
			from: { low: 0, high: 0.4 },
			to: { low: 0, high: 1 }
		})
	}

	getTargetTop(model) {
		return model.h - model.getProgress(0) * model.h
	}

	getTargetOpacity(model) {
		return 1 - Math.max(this.opacityRemapper(model.getProgress(1), 0))
	}

	getTargeIllutOpacity(model) {
		return 1 - Math.max(this.illuRemapper(model.getProgress(1), 0))
	}

	render() {
		const model = this.props.model
		const targetTop = this.getTargetTop(model)
		const targetOpacity = this.getTargetOpacity(model)
		const targetIlluOpacity = this.getTargeIllutOpacity(model)

		return (
			<Spring native key="part-fixed-start-spring"
				from={{ top: 0, opacity: 1, illuOpacity: 1}}
				immediate={true}
				to={{ top: targetTop, opacity: targetOpacity, illuOpacity: targetIlluOpacity }}
				config={config.wobbly}>
					{ ({top, opacity, illuOpacity}) => (
						<animated.div
							key="fixed-start-section"
							id="fixed-start-section"
							className="part fixed"
							style={{
								top: top,
								opacity: opacity,
							}}>
								<animated.div id="intro-illustration" style={{
									opacity: illuOpacity,
								}}>
									<img
										src={StartIllustrationPNG}
										alt={Copy.StartSection.illustrationAltDescription}
									/>
								</animated.div>
								<div className="copy-wrapper">
									<div className="copy">
										{Copy.StartSection.headline}
										{Copy.StartSection.body}
									</div>
								</div>
						</animated.div>
					)}
			</Spring>
			)
	}
}
class FixedTodaySection extends Component {

	static propTypes = {
		model: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {}

		this.fixedThreshold = 0.5
		this.remapper = remap({
			from: { low: this.fixedThreshold, high: 1 },
			to: { low: 0, high: 1 }
		})
	}

	getTargetTop(model) {
		if (model.step <= 1) {
			return model.h - model.getProgress(1) * model.h
		} else if (model.step < 5) {
			return 0
		} else if(model.step === 5) {
			return 0 - model.getProgress(5) * model.h
		} else {
			return 0 - model.h
		}
	}

	getIncomeProperties(model, offScreenTop) {
		let opacity = 0
		let top = 60
		const progress1 = model.getProgress(1)

		if (model.step === 1 && progress1 > this.fixedThreshold) {
			opacity = this.remapper(progress1)
		} else if (model.step >= 2) {
			const progress2 = model.getProgress(2)
			if (progress2 > 0) {
				top -= (progress2 * offScreenTop)
				opacity = this.remapper(1 - progress2)
			} else {
				opacity = 1
			}
		}

		return {
			top: top,
			opacity: opacity,
		}
	}

	getSpendingProperties(model, offScreenTop) {
		let opacity = 0
		let top = offScreenTop
		const progress2 = model.getProgress(2)

		if (model.step >= 2 && progress2 > 0) {
			opacity = progress2
			top = offScreenTop - (progress2 * offScreenTop)
		}

		return {
			top: top,
			opacity: opacity,
		}
	}

	render() {
		const model = this.props.model
		const targetTop = this.getTargetTop(model)
		const offScreenTop = model.h / 3

		const { top: incomeTop, opacity: incomeOpacity} = this.getIncomeProperties(model, offScreenTop)
		const { top: spendingTop, opacity: spendingOpacity} = this.getSpendingProperties(model, offScreenTop)

		const t = 100000000000
		const incomeProgress = (model.income - t) + (t * model.getProgress(1))
		const spentProgress = (model.getTargetDollarValue() - t) + (t * model.getProgress(2))

		return (
			<Spring key="part-2-fixed-spring" from={{ top: model.h + 1, opacity: 1 - model.getProgress(5)}} to={{ top: targetTop, opacity: 1 - model.getProgress(5) }} config={config.stiff}>
					{ ({top, opacity})  => (
						<div key="part-2-fixed" id="part-2-fixed" className="part fixed" style={{transform: `translate3d(0, ${top}px, 0)`, opacity: opacity}}>
								<div className="copy">
									<h1>In {model.year}</h1>
										<div className="content-wrapper">
											<Spring from={{ top: incomeTop - 1, opacity: incomeOpacity}} to={{ top: incomeTop, opacity: incomeOpacity }} config={config.default}>
												{ ({top, opacity})  => (
													<div className="content" style={{opacity: opacity, top: top}}>
														<Spring key="counter" impl={TimingAnimation} from={{ value: 0 }} to={{ value: incomeProgress }} config={{ duration: 250, easing: Easing.linear }}>
																{({ value })  => (
																	<h1 key="digits" className="digits" style={{ color: Const.GREEN_COLOR}}>{FormatCounter(value)}</h1>
																)}
														</Spring>
														<div className="desc-container-bottom">
																<p className="desc" style={{
																	opacity: model.step >= 1 && model.getProgress(1) > 0.8 ? 1 : 0,
																	pointerEvents: model.step >= 1 && model.getProgress(1) > 0.8 ? "auto" : "none",
																}}>was collected in <Tooltip value={Copy.AnimationSection.taxRevenueTooltip}>Tax Revenue</Tooltip> by the U.S. Government.</p>
														</div>
													</div>
												)}
											</Spring>

											<Spring
												immediate={true}
												from={{ top: spendingTop - 1, opacity: spendingOpacity}}
												to={{ top: spendingTop, opacity: spendingOpacity }}
												config={config.default}>
													{ ({top, opacity})  => (
														<div className="content" style={{opacity: opacity, top: top}}>
															<div className="desc-container-top">
																	<p
																		className="desc"
																		style={{opacity: (model.step === 3 && model.getProgress(3) > 0) || (model.step === 4 && model.getProgress(4) <= 0.2) ? 1 : 0}}>
																		Leaving us with
																	</p>
																	<p
																		className="desc red"
																		style={{
																			opacity: model.step >= 4 && model.getProgress(4) > 0 ? 1 : 0,
																			pointerEvents: model.step >= 4 && model.getProgress(4) > 0 ? "auto" : "none",
																		}}>
																		Leaving us with a <Tooltip value={Copy.AnimationSection.deficitTooltip}>deficit</Tooltip> of
																	</p>
															</div>
															<Spring
																key="counter"
																impl={TimingAnimation}
																from={{ value: 0, color: Const.GREEN_COLOR }}
																to={{ value: spentProgress, color: model.getTargetDollarColor() }}
																config={{ duration: 250, easing: Easing.linear }}>
																	{({ value, color })  => (
																		<h1 key="digits" className="digits" style={{ color: color}}>{FormatCounter(value)}</h1>
																	)}
															</Spring>
															<div className="desc-container-bottom">
																	<p className="desc blue" style={{
																		opacity: ((model.step === 2 && model.getProgress(2) > 0.3) || (model.step === 3 && model.getProgress(3) === 0)) ? 1 : 0,
																		pointerEvents: ((model.step === 2 && model.getProgress(2) > 0.3) || (model.step === 3 && model.getProgress(3) === 0)) ? "auto" : "none",
																	}}>
																		was spent on <Tooltip value={Copy.AnimationSection.spendingCategoriesTooltip}>Healthcare, Defense, Social Security, interest payments & all other expenses</Tooltip>.
																	</p>
															</div>
														</div>
													)}
											</Spring>
										</div>
								</div>
						</div>
					)}
			</Spring>
			)
	}
}

class FixedFutureSection extends Component {

	static propTypes = {
		model: PropTypes.object.isRequired,
		isMobile: PropTypes.bool.isRequired,
	}

	constructor(props) {
		super(props)

		this.fixedThreshold = 1
		this.remapper = remap({
			from: { low: 0, high: this.fixedThreshold },
			to: { low: 0, high: 1 }
		})

		this.opacityRemapper = remap({
			from: { low: 0, high: 0.4 },
			to: { low: 0, high: 1 }
		})

		this.finalSlideRemapper = remap({
			from: { low: 0, high: 0.3},
			to: { low: 0, high: 1 }
		})

		this.titleOpacityRemapper = remap({
			from: { low: 0, high: 0.25 },
			to: { low: 0, high: 1 }
		})

		this.yearRemapper = remap({
			from: { low: 0, high: 3.0 },
			to: { low: this.props.model.year + 1, high: this.props.model.projectedYear }
		})
	}

	getTargetTop(model) {
		const initialPos = model.h + 1

		if (model.step < 5) {
			return initialPos
		} else if (model.step === 5) {
			let p = this.remapper(model.getProgress(5))
			if (p > 1.0) { p = 1.0 }
			return initialPos - p * initialPos
		} else if (model.step >= 8) {
			const mMenuHeight = 48
			const menuHeight = 60
			let tt = 0
			if (this.props.isMobile) {
				tt = 0.16 * model.h - mMenuHeight
			} else {
				tt = this.props.model.isPortrait ?  0.16 * model.h - menuHeight : 0.3 * model.h - menuHeight
			}
			const p = Math.min(this.finalSlideRemapper(model.getProgress(8)), 1.0)
			return 0 - p * tt
		} else {
			return 0
		}
	}

	getTargetYear(model) {
		const y = this.yearRemapper(model.getProgress(5) + model.getProgress(6) + model.getProgress(7))
		return Math.round(y)
	}

	render() {
		const model = this.props.model
		const p5 = model.getProgress(5)
		const targetTop = this.getTargetTop(model)
		const opacity = this.opacityRemapper(p5)
		const contentOpacity = this.remapper(p5)
		const p8 = model.getProgress(8)
		const rP8 = Math.max((1 - this.titleOpacityRemapper(p8)), 0)
		const titleOpacity = rP8
		const targetYear = this.getTargetYear(model)

		return (
			<Spring
				immediate={true}
				from={{ top: targetTop, opacity: opacity, contentOpacity: contentOpacity}}
				to={{ top: targetTop, opacity: opacity, contentOpacity: contentOpacity }}
				config={config.stiff}>
					{ ({top, opacity, contentOpacity})  => (
						<div className="part fixed" style={{
							transform: `translate3d(0, ${top}px, 0)`,
							opacity: opacity,
						}}>
								<div className="copy final">
									<h1 style={{opacity: titleOpacity}}>By {targetYear}</h1>
									<div className="content-wrapper">
										<div className="content" style={{opacity: contentOpacity}}>
											<div className="desc-container-top">
													<p
														className="desc"
														style={{opacity: titleOpacity, transition: "none"}}
													>
														Our deficit will rise to
													</p>
													<p
														className="desc"
														style={{opacity: model.step >= 8 && p8 > 0.35 ? 1 : 0}}
													>
														By {model.projectedYear} we'll be left with a deficit of
													</p>
											</div>
											<Spring
												key="counter"
												impl={TimingAnimation}
												from={{
													value: 0,
													color: Const.GREEN_COLOR,
												}}
												to={{
													value: this.props.model.getTargetDeficitDollarValue(),
													color: model.getTargetDollarColor(),
												}}
												config={{ duration: 250, easing: Easing.linear }}
											>
												{({ value, color })  => (
													<h1 key="digits" className="digits" style={{ color: color}}>{FormatCounter(value)}</h1>
												)}
											</Spring>
											<div className="desc-container-bottom">
												<p
													className="desc-rel red"
													style={{opacity: model.step >= 8 && p8 > 0.5 ? 1 : 0}}>
														Every year's deficit adds to the nation's <Tooltip value={Copy.AnimationSection.federalDebtTooltip}>debt</Tooltip> creating a very large problem. By {Facts.econ.projectedOverflowYear}, debt will surpass 100% of <Tooltip value={Copy.AnimationSection.gdpTooltip}>GDP</Tooltip> and by {this.props.model.projectedYear} it will hit {this.props.model.projectedNetDebtGDP * 100}%.
												</p>
											</div>
										</div>
									</div>
								</div>
						</div>
					)}
			</Spring>
		)
	}
}

class Stage extends Component {
	static propTypes = {
		model: PropTypes.object.isRequired,
		w: PropTypes.number,
		h: PropTypes.number,
		step: PropTypes.number.isRequired,
		progress: PropTypes.number.isRequired,
		isPortrait: PropTypes.bool,
		isMobile: PropTypes.bool,
	}

	constructor(props) {
		super(props)

		this.state = {}

		this.line1Ref = React.createRef()
		this.line2Ref = React.createRef()
		this.line3Ref = React.createRef()
		this.line4Ref = React.createRef()
		this.line5Ref = React.createRef()
		this.line6Ref = React.createRef()

		this.safeAreaInsets = {}
	}

	componentDidMount() {
		if (safeAreaInsets.support) {
			safeAreaInsets.onChange((style) => {
				this.safeAreaInsets = style
			})
		}
	}

	formatShortTrillions = (dollars) => {
		return Format(dollars, true, 2)
	}

	formatShortBillionsRounded = (dollars) => {
		return Format(dollars, true, 2)
	}

	render() {
		if (!this.props.w) {
			return null
		}

		const centerX = this.props.model.w / 2
		const centerY = this.props.model.h / 2

		// Notes:
		// fullScreenCircleRad is 0%
		// minCircleRad is 100%

		// Step 1
		const fullScreenCircleRad = EnclosingCircleForRect(this.props.model.w, this.props.model.h)

		const minDim = Math.min(this.props.model.w, this.props.model.h)
		const minCircleRad = this.props.isMobile ? 0.35 * minDim : 0.6 * (minDim / 2)

		const reverseProgress1 = Math.max((1 - this.props.model.getProgress(1)), 0)
		const circleRad = remap({
			from: { low: 0, high: fullScreenCircleRad },
			to: { low: minCircleRad, high: fullScreenCircleRad }
		})(reverseProgress1 * fullScreenCircleRad)

		const l1P = SVGUtil.getPointAtPercent(this.line1Ref.current, this.props.model.getProgress(1))
		const {x: xOffset, y: yOffset} = this.props.model.pointToRelativePoint(l1P)

		const line1 = this.props.model.grid.line1()
		const line1Path = SVGUtil.toString(line1.commands)

		// Step 2
		const scaleFactor2 = 0.579
		const minCircleRad2 = minCircleRad * scaleFactor2
		const reverseProgress2 = Math.max((1 - this.props.model.getProgress(2)), 0)

		const circleRad2 = remap({
			from: { low: 0, high: minCircleRad },
			to: { low: minCircleRad2, high: minCircleRad }
		})(reverseProgress2 * minCircleRad)

		const l2P = SVGUtil.getPointAtPercent(this.line2Ref.current, this.props.model.getProgress(2))
		const {x: xOffset2, y: yOffset2} = this.props.model.pointToRelativePoint(l2P)

		const line2 = this.props.model.grid.line2()
		const line2Path = SVGUtil.toString(line2.commands)

		// - blue
		const minCircleRad3 = minCircleRad2 * this.props.model.econRatio
		const maxCircleRad3 = minCircleRad3 * 2 // because scale factor 2 is .5

		const circleRad3 = remap({
			from: { low: 0, high: maxCircleRad3 },
			to: { low: minCircleRad3, high: maxCircleRad3 }
		})(reverseProgress2 * maxCircleRad3)

		const l3P = SVGUtil.getPointAtPercent(this.line3Ref.current, this.props.model.getProgress(2))
		const {x: xOffset3, y: yOffset3} = this.props.model.pointToRelativePoint(l3P)

		const line3 = this.props.model.grid.line3()
		const line3Path = SVGUtil.toString(line3.commands)

		// Step 3
		const l4P = SVGUtil.getPointAtPercent(this.line4Ref.current, this.props.model.getProgress(3))
		const {x: xOffset4, y: yOffset4} = this.props.model.pointToRelativePoint(l4P)

		const line4 = this.props.model.grid.line4()
		const line4Path = SVGUtil.toString(line4.commands)

		const l5P = SVGUtil.getPointAtPercent(this.line5Ref.current, this.props.model.getProgress(3))
		const {x: xOffset5, y: yOffset5} = this.props.model.pointToRelativePoint(l5P)

		const line5 = this.props.model.grid.line5()
		const line5Path = SVGUtil.toString(line5.commands)

		const scaleFactor3 = 0.65
		const targetR = scaleFactor3 * circleRad2 // TODO rename, don't make any sense
		const maxDivider = 14 // TODO rename
		const roundCornerRadius = Math.max(0.016 * this.props.model.w, 20)

		// Step 4
		const pr4 = this.props.model.getProgress(4)
		const pr = remap({ // TODO rename
			from: { low: 0, high: 1},
			to: { low: 0, high: 1.5}
		})(pr4)
		const minCircleRad4 = targetR + pr4 * (targetR * (scaleFactor3 * 2) - targetR)
		const rPr = (1 - Math.min(pr, 1))
		const circleRad4 = rPr * minCircleRad4

		// These are used in step 4 so we can fade in the bottom bar shape
		// They really belong in Step 5 though
		let barHeight = this.props.isMobile? Const.MOBILE_BAR_HEIGHT : Const.DESKTOP_BAR_HEIGHT
		if (this.safeAreaInsets.bottom) {
			barHeight += this.safeAreaInsets.bottom
		}

		const meltedPlaceholder = SVGMeltingCircle(this.props.model.w, this.props.model.h, new Circle(0, 0, 0), roundCornerRadius, barHeight)
		const meltedPlaceholderPath = SVGUtil.toString(meltedPlaceholder.commands)
		const meltedPlaceholderPathOpacity = ((this.props.step === 4 && this.props.model.getProgress(4) === 1) || (this.props.step === 5 && this.props.model.getProgress(5) === 0)) ? 1 : 0

		// Step 5
		// Projected year text comes in, red circle growing to 10%
		const pr5 = this.props.model.getProgress(5)

		const meltMultiplier = 1.15
		const meltTargetRad = meltMultiplier * minCircleRad4
		const meltCircleRad = minCircleRad4 + pr5 * (meltTargetRad - minCircleRad4)

		// Step 6
		// Ball drops, growing to 20%
		const pr6 = this.props.model.getProgress(6)
		// Move ball across step 5 AND 6
		const dropP = Math.max(remap({
			from: { low: 0.4, high: 2},
			to: { low: 0, high: 1 }
		})(pr5 + pr6), 0)

		const l6P = SVGUtil.getPointAtPercent(this.line6Ref.current, dropP)
		const {x: xOffset6, y: yOffset6} = this.props.model.pointToRelativePoint(l6P)

		const line6 = this.props.model.grid.line6()
		const line6Path = SVGUtil.toString(line6.commands)

		const meltMultiplier2 = 1.3
		const meltTargetRad2 = meltMultiplier2 * meltCircleRad
		const meltCircleRad2 = meltCircleRad + pr6 * (meltTargetRad2 - meltCircleRad)

		const federalDebtLabelY = barHeight * 0.5

		// Step 7
		// Zoom out
		const pr7 = this.props.model.getProgress(7)

		// Step 8
		// Transition to conclusion
		const pr8 = this.props.model.getProgress(8)
		const blueBarP = remap({
			from: { low: 0, high: 0.3},
			to: { low: 0, high: 1 }
		})(pr8)
		const barHeightProgress = barHeight * Math.min(blueBarP, 1)

		// Graph labels
		const graphLabelPadding = this.props.isMobile ? 10 : 20
		const graphLabelBottomPadding = (15 + barHeight) * -1

		const stageClasses = cx({
			"is-mobile": this.props.isMobile,
			"is-desktop": !this.props.isMobile,
		})

		return (
			<div
				id="stage"
				className={stageClasses}
			>
				<div className="focus-plane">
					<svg width={this.props.w} height={this.props.h} preserveAspectRatio="xMinYMax meet">
						<g> { this.props.step <= 1 &&
									<Spring key="spring-green"
										immediate={true}
										from={{ r: fullScreenCircleRad, xOffset: xOffset, yOffset: yOffset }}
										to={{ r: circleRad, xOffset: xOffset, yOffset: yOffset }}
										config={config.wobbly}>
									{({ r, xOffset, yOffset})  => (
											<g key="g" transform={`translate(${xOffset}, ${yOffset})`}>
												<circle key="circle" id="circle" cx={centerX} cy={centerY} r={r} />
												<CircleLabel
													title="Revenue"
													value={this.formatShortTrillions(this.props.model.income)}
													style={{opacity: this.props.model.getProgress(1) > 0.8 ? 1 : 0}}
												/>
											</g>
											)}
									</Spring>
								}

							{ this.props.step >= 2 && this.props.step < 3 &&
								<Spring key="spring-green"
									immediate={true}
									from={{ r: circleRad2, xOffset: xOffset2, yOffset: yOffset2 }}
									to={{ r: circleRad2, xOffset: xOffset2, yOffset: yOffset2 }}
									config={config.wobbly}>
								{({ r, xOffset, yOffset })  => (
										<g key="g" transform={`translate(${xOffset}, ${yOffset})`}>
											<circle key="circle" id="circle" cx={centerX} cy={centerY} r={r} />
											<CircleLabel
												title="Revenue"
												value={this.formatShortTrillions(this.props.model.income)}
												style={{opacity: 1}}
											/>
										</g>
										)}
								</Spring>
							}

							{ this.props.step === 2 &&
								<Spring key="spring-blue"
									from={{ r: maxCircleRad3, xOffset: xOffset3, yOffset: yOffset3 }}
									to={{ r: circleRad3, xOffset: xOffset3, yOffset: yOffset3 }}
									config={config.default}>
								{({ r, xOffset, yOffset })  => (
										<g key="gb" transform={`translate(${xOffset}, ${yOffset})`}>
											<circle key="circle-blue" id="circle-blue" cx={centerX} cy={centerY} r={r} />
											<CircleLabel
												title="Spending"
												value={this.formatShortTrillions(this.props.model.spent)}
												style={{opacity: 1}}
											/>
										</g>
										)}
								</Spring>
							}

							{ this.props.step === 3 &&
								<Spring key="spring-blue"
									from={{ r: circleRad3, xOffset: xOffset4, yOffset: yOffset4, xOffset2: xOffset5, yOffset2: yOffset5 }}
									to={{ r: circleRad3, xOffset: xOffset4, yOffset: yOffset4, xOffset2: xOffset5, yOffset2: yOffset5 }}
									config={config.wobbly}>
								{({ r, xOffset, yOffset, xOffset2, yOffset2 })  => {
									const oC1 = new Circle(xOffset2, yOffset2, circleRad2)
									const oC2 = new Circle(xOffset, yOffset, circleRad3)
									const {c1, c2, blueC, circlesOverlap, commands} = SVGSubstractedCircle(oC1, oC2, targetR, roundCornerRadius, maxDivider) // eslint-disable-line no-unused-vars
									const overlapPath = SVGUtil.toString(commands)
									return (
										<g>
											{circlesOverlap &&
												<g key="g-overlap" transform={`translate(${centerX}, ${centerY})`}>
													<path key="path-overlap" id="circle" d={overlapPath} />
												</g>
											}
											<g key="g" transform={`translate(${c1.x}, ${c1.y})`}>
												<circle key="circle" id="circle" cx={centerX} cy={centerY} r={c1.r} style={{ opacity: circlesOverlap ? "0.0" : "1.0" }} />
												<CircleLabel
													title="Revenue"
													value={this.formatShortTrillions(this.props.model.income)}
													style={{opacity: this.props.model.getProgress(3) > 0.3 ? 0 : 1}}
												/>
											</g>
											<g key="gb" transform={`translate(${xOffset}, ${yOffset})`}>
												<circle key="circle-blue" id="circle-blue" cx={centerX} cy={centerY} r={blueC.r} />
												<CircleLabel
													title="Spending"
													value={this.formatShortTrillions(this.props.model.spent)}
													style={{opacity: this.props.model.getProgress(3) > 0.5 ? 0 : 1}}
												/>
											</g>
										</g>
										)}}
								</Spring>
							}

							{ this.props.step >= 4 && this.props.step <= 5 && this.props.model.getProgress(5) === 0 &&
								<Spring key="spring-red"
									immediate={true}
									from={{ r: circleRad4, xOffset: xOffset5, yOffset: yOffset5 }}
									to={{ r: circleRad4, xOffset: xOffset5, yOffset: yOffset5 }}
									config={config.wobbly}>
								{({ r, xOffset, yOffset })  => (
									<g key="gs">
										<g key="gms" id="melt-shape" transform={`translate(${centerX}, ${centerY})`} style={{ opacity: meltedPlaceholderPathOpacity }}>
											<path d={meltedPlaceholderPath} />
										</g>
										<g key="gb" transform={`translate(${xOffset}, ${yOffset})`}>
											<circle key="circle-red" id="circle-red" cx={centerX} cy={centerY} r={minCircleRad4} />
											<circle key="circle-blue" id="circle-blue" cx={centerX} cy={centerY} r={Math.max(r, 0)} />
											<CircleLabel
												key="circle-deficit"
												title="Deficit"
												value={this.formatShortBillionsRounded((this.props.model.income - this.props.model.spent) * -1)}
												style={{ opacity: this.props.model.getProgress(4) >= 0.6 ? 1 : 0 }}
											/>
										</g>
									</g>
										)}
								</Spring>
							}

							{ this.props.step >= 5 && this.props.model.getProgress(5) > 0 && this.props.step <= 6 &&
								<Spring key="spring-red"
									immediate={true}
									from={{ r: this.props.step === 5 ? meltCircleRad : meltCircleRad2, xOffset: xOffset6, yOffset: yOffset6 }}
									to={{ r: this.props.step === 5 ? meltCircleRad: meltCircleRad2, xOffset: xOffset6, yOffset: yOffset6 }}
									config={config.wobbly}>
								{({ r, xOffset, yOffset })  => {
										const oC1 = new Circle(xOffset, yOffset, r)
										const {intersect, intersectProgress, commands} = SVGMeltingCircle(this.props.model.w, this.props.model.h, oC1, roundCornerRadius, barHeight)  // eslint-disable-line no-unused-vars
										const federalDebtStartPr = 0.7
										const federalDebtPr = Math.min(Math.max(remap({ // TODO rename
											from: { low: federalDebtStartPr, high: 1},
											to: { low: 0, high: 1}
										})(pr6), 0), 1)
										const federalDebtLabelY6 = -1 * (federalDebtLabelY + (0.05 * this.props.model.h) * federalDebtPr)
										const path = SVGUtil.toString(commands)
										return (
											<g key="gs">
												<g key="gms" id="melt-shape" transform={`translate(${centerX}, ${centerY})`} style={{ opacity: 1 }}>
													<path d={path} />
												</g>
												<g key="gb" transform={`translate(${xOffset}, ${yOffset})`}>
													<circle key="circle-red" id="circle-red" cx={centerX} cy={centerY} r={Math.max(r, 0)} />
													<Spring
														key="counter"
														impl={TimingAnimation}
														from={{
															value: this.props.model.getTargetDeficitDollarValue()
														}}
														to={{
															value: this.props.model.getTargetDeficitDollarValue()
														}}
														config={{ duration: 250, easing: Easing.linear }}
													>
														{({ value })  => (
															<CircleLabel
																key="circle-deficit"
																title="Deficit"
																value={this.formatShortBillionsRounded(value)}
																style={{ opacity: intersectProgress >= 0.7 ? 0 : 1 }}
															/>
														)}
													</Spring>
												</g>
												<g key="federal-debt-labels" transform={`translate(0, ${this.props.model.h})`}>
													<Spring
														key="debt-counter"
														impl={TimingAnimation}
														from={{value: 0}}
														to={{value: this.props.model.getTargetDebtDollarValue()}}
														config={{ duration: 250, easing: Easing.linear }}
													>
														{ ({value}) => (
															<FederalDebtLabel
																style={{ opacity: intersectProgress >= federalDebtStartPr ? 1 : 0 }}
																className="federal-debt-label-group"
																year={null}
																label={FormatCounter(value)}
																x={this.props.model.w / 2}
																y={federalDebtLabelY6}
															/>
														)}
													</Spring>
												</g>
											</g>
										)
										}}
								</Spring>
							}

							{ this.props.step >= 7 &&
								<Spring key="spring-red"
									immediate={true}
									from={{ percent: pr7 }}
									to={{ percent: pr7 }}
									config={config.wobbly}>
								{({ percent })  => {
										const h = this.props.model.h/2 + barHeight
										const {commands} = SVGSteepSlope(this.props.model.w, h, meltCircleRad2, barHeight, percent)
										const path = SVGUtil.toString(commands)

										const federalDebtLabelY6 = -1 * (federalDebtLabelY + (0.05 * this.props.model.h))
										const federalDebtLabelY7 = federalDebtLabelY6 - ((0.13 * this.props.model.h) * pr7)
										return (
											<g key="gs">
												<linearGradient id="flame-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
													<stop offset="0%" stopColor="var(--debt-gradient-start-color)"/>
													<stop offset="20%" stopColor="var(--debt-gradient-start-color)"/>
													<stop offset="100%" stopColor="var(--debt-gradient-end-color)"/>
												</linearGradient>
												<g key="gss" id="slope-shape" transform={`translate(0, ${this.props.model.h/2 - barHeight})`}>
													<path d={path} />
													<path d={path} fill="url(#flame-gradient)" fillOpacity={percent} />
												</g>
												<g key="bs" id="purple-bar-shape" transform={`translate(0, ${this.props.model.h - barHeightProgress})`}>
													<rect width={this.props.model.w} height={barHeightProgress} />
												</g>
												<g key="labels" id="slope-shape-labels" transform={`translate(0, ${this.props.model.h})`}>
													<GraphYearLegend
														startYear={this.props.model.year}
														endYear={this.props.model.projectedYear}
														canvasWidth={this.props.model.w}
														isMobile={this.props.isMobile}
														bottomPadding={graphLabelBottomPadding}
														sidePadding={graphLabelPadding}
														className="slope-shape-label-group"
														style={{opacity: pr8 >= 0.3 ? 1 : 0}}
													/>
													<GraphLabel
														style={{opacity: pr8 > 0 ? 1 : 0}}
														className="slope-shape-label-group inverted"
														label="100% of GDP"
														x={this.props.model.w}
														y={h * -0.7}
														isMobile={this.props.isMobile}
													/>
													<GraphLabel
														style={{opacity: pr8 >= 0.4 ? 1 : 0}}
														className="slope-shape-label-group peak"
														label={`${this.props.model.projectedNetDebtGDP * 100}% of GDP`}
														x={this.props.model.w}
														y={-h}
														isMobile={this.props.isMobile}
													/>
												</g>
												<g key="federal-debt-labels" transform={`translate(0, ${this.props.model.h})`}>
													<Spring
														key="debt-counter"
														impl={TimingAnimation}
														from={{
															value: this.props.model.getTargetDebtDollarValue(),
														}}
														to={{
															value: this.props.model.getTargetDebtDollarValue(),
														}}
														config={{ duration: 250, easing: Easing.linear }}
													>
														{ ({value}) => (
															<FederalDebtLabel
																style={{opacity: 1}}
																className="federal-debt-label-group"
																year={this.props.model.getProgress(8) > 0.35 ? this.props.model.projectedYear : null}
																label={FormatCounter(value)}
																x={this.props.model.w / 2}
																y={federalDebtLabelY7}
															/>
														)}
													</Spring>
												</g>
											</g>
										)
										}}
								</Spring>
							}
						</g>
						<g>
						<path id="line1" ref={this.line1Ref} d={line1Path} stroke={DEBUG ? "cyan" : "none"} fill="none"/>
						<path id="line2" ref={this.line2Ref} d={line2Path} stroke={DEBUG ? "red" : "none"} fill="none"/>
						<path id="line3" ref={this.line3Ref} d={line3Path} stroke={DEBUG ? "green" : "none"} fill="none"/>
						<path id="line4" ref={this.line4Ref} d={line4Path} stroke={DEBUG ? "magenta" : "none"} fill="none"/>
						<path id="line5" ref={this.line5Ref} d={line5Path} stroke={DEBUG ? "purple" : "none"} fill="none"/>
						<path id="line6" ref={this.line6Ref} d={line6Path} stroke={DEBUG ? "cyan" : "none"} fill="none"/>
						</g>
					</svg>
				</div>
			</div>
		)
	}

}

class IntroPage extends Component {

	constructor(props) {
		super(props)

		this.state = {
			scrollProgress: 0,
			step: 1,
			windowHeight: undefined,
			windowWidth: undefined,
			pageHeight: undefined,
			isPortrait: undefined,
			isMobile: undefined,
		}

		this.model = new StepsModel()
		this.model.setEconomicFacts(Facts.econ)
		this.pageRef = React.createRef()
		this.lastScrollPosition = -1
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResizeThrottled)
		window.addEventListener("resize", this.handleResizeDebounced)

		window.addEventListener("keydown", this.handleKeyDown)

		// Always scroll to top?
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}
		window.scrollTo(0, 0)

		this.updateDimensions(() => {
			// Kickstart animation loop
			this.loop()
		});
	}

	componentDidUpdate() {
		ReactTooltip.rebuild()
	}

	componentWillUnmount() {
		if (this.loopFrameId){
			cancelAnimationFrame(this.loopFrameId)
		}
		window.removeEventListener("resize", this.handleResizeThrottled)
		window.removeEventListener("resize", this.handleResizeDebounced)

		window.removeEventListener("keydown", this.handleKeyDown)
	}

	handleResizeThrottled = throttle(() => {
		this.updateDimensions()
	}, 200)

	handleResizeDebounced = debounce(() => {
		this.updateDimensions()
	}, 10)

	updateDimensions = (callback) => {
		const w = window.innerWidth
		const h = innerHeight()
		this.model.setCanvasSize(w, h)
		let pageHeight = h

		const isPortrait = w <= h ? true : false
		let isMobile = ((w < Const.MOBILE_BREAKPOINT) || (h < 600) ) ? true : false

		this.setState({
			windowWidth: w,
			windowHeight: h,
			isPortrait: isPortrait,
			isMobile: isMobile,
			pageHeight: pageHeight,
		}, callback)
	}

	render() {

		const introPageClasses = cx({
			"is-portrait": this.state.isPortrait,
			"is-landscape": !this.state.isPortrait,
			"is-mobile": this.state.isMobile,
			"is-desktop": !this.state.isMobile,
		})

		return (
			<div id="intro-page-wrapper">
				<MainMenu
					step={this.state.step}
					progress={this.state.scrollProgress}
					isMobile={this.state.isMobile || false}
					isPortrait={this.state.isPortrait || false}
					history={this.props.history}
				/>
				<div id="rotate-device-overlay">
					<RotateDeviceIconSVG />
					<p>Please rotate your device for the best experience</p>
				</div>
				<div id="intro-page" className={introPageClasses} ref={this.pageRef}>
					<ScrollIndicator
						step={this.state.step}
						progress={this.model.getProgress(this.state.step)}
						onClick={this.scrollToNextStep}
						isMobile={this.state.isMobile}
						model={this.model}
					/>

					{ DEBUG &&
					<div id="debug-menu">
						<div className="info">{this.state.step} - {this.state.scrollProgress}%</div>
					</div>
					}

					<div id="part-0" className="part part-0-handler" style={this.layoutStyle(0)}/>
					<div id="part-1" className="part part-1-handler" style={this.layoutStyle(1)}/>
					<div id="part-2" className="part part-2-handler" style={this.layoutStyle(2)}/>
					<div id="part-3" className="part part-3-handler" style={this.layoutStyle(3)}/>
					<div id="part-4" className="part part-4-handler" style={this.layoutStyle(4)}/>
					<div id="part-5" className="part part-5-handler" style={this.layoutStyle(5)}/>
					<div id="part-6" className="part part-6-handler" style={this.layoutStyle(6)}/>
					<div id="part-7" className="part part-7-handler" style={this.layoutStyle(7)}/>
					<div id="part-8" className="part part-8-handler" style={this.layoutStyle(8)}/>
					<div id="part-9" className="part part-9-handler" style={this.layoutStyle(9)}/>

					<div id="front-stage">
					{ this.state.step <= 1 &&
						<FixedSplashSection
							w={this.state.windowWidth}
							h={this.state.windowHeight}
							step={this.state.step}
							progress={this.state.scrollProgress}
							model={this.model}
							isMobile={this.state.isMobile || false}
							onClick={this.scrollToNextStep}
						/>
					}

					{ this.state.step <= 2 &&
						<FixedStartSection
							model={this.model}
							w={this.state.windowWidth}
							h={this.state.windowHeight}
							step={this.state.step}
							progress={this.model.getProgress(this.state.step)}
						/>
					}

					{ this.state.step >= 1 && this.state.step <= 5 &&
						<FixedTodaySection
							model={this.model}
							w={this.state.windowWidth}
							h={this.state.windowHeight}
							step={this.state.step}
							progress={this.model.getProgress(this.state.step)}
							isMobile={this.state.isMobile || false}
						/>
					}

					{ this.state.step >= 5 && this.state.step <= 9 &&
						<FixedFutureSection
							model={this.model}
							w={this.state.windowWidth}
							h={this.state.windowHeight}
							step={this.state.step}
							progress={this.model.getProgress(this.state.step)}
							isMobile={this.state.isMobile || false}
						/>
					}
					</div>

					<ConclusionSection
						w={this.state.windowWidth}
						h={this.state.windowHeight}
						step={this.state.step}
						progress={this.state.scrollProgress}
						model={this.model}
            toggleVideo={this.props.toggleVideo}
						isMobile={this.state.isMobile || false}
					/>
				</div>
				<Stage
					model={this.model}
					w={this.state.windowWidth}
					h={this.state.windowHeight}
					step={this.state.step}
					progress={this.model.getProgress(this.state.step)}
					isPortrait={this.state.isPortrait}
					isMobile={this.state.isMobile || false}
				/>
				<ReactTooltip className="tooltip-bubble" effect="float" multiline border offset={{top: "20px"}}/>
			</div>
		);
	}

	layoutStyle = (step) => {
		const l = this.model.scrollLayout.layouts[step]
		if (!l) {
			return null
		}
		return { height: l.h, marginBottom: l.bP }
	}

	loop = () => {
		// Avoid calculations if not needed
		const yOffset = window.pageYOffset
		if (this.lastScrollPosition === yOffset) {
			this.loopFrameId = window.requestAnimationFrame(this.loop)
			return false
		} else {
			this.lastScrollPosition = yOffset
		}

		const pageOffset = Math.max(yOffset, 0)
		this.model.setScrollPageOffset(pageOffset)

		this.setState({
			step: this.model.step,
			scrollProgress: Math.round(this.model.getProgress(this.model.step) * 100)
		}, function() {
			window.requestAnimationFrame(this.loop)
		}.bind(this))
	}

	handleKeyDown = (e) => {
		if (e.code === "ArrowLeft") {
			e.preventDefault()
			this.scrollToPreviousStep()
		} else if (e.code === "ArrowRight") {
			e.preventDefault()
			this.scrollToNextStep()
		}
	}

	scrollToNextStep = () => {
		const stepCount = this.model.scrollLayout.stepCount()

		if (stepCount && this.state.step >= stepCount - 1) {
			return
		}

		let nextStep = this.state.step + 1

		if (nextStep === 6) {
			nextStep = 8
		} else if (nextStep === 4) {
			nextStep = 5
		} else if (nextStep === 17) {
			// Scroll to bottom of the screen when we're at the end
			window.scrollTo({
				top: window.document.scrollingElement.scrollHeight,
				behavior: "smooth"
			})
			return
		}

		const pageY = this.model.scrollLayout.pageYOffsetForStep(nextStep)

		window.scrollTo({
			top: pageY,
			behavior: "smooth"
		})
	}

	scrollToPreviousStep = () => {
		let prevStep = this.state.step > 0 ? this.state.step - 1 : 1

		if (prevStep === 7) {
			prevStep = 5
		} else if (prevStep === 4) {
			prevStep = 3
		}

		const pageY = this.model.scrollLayout.pageYOffsetForStep(prevStep)
		window.scrollTo({
			top: pageY,
			behavior: "smooth"
		})
	}
}

export default IntroPage

import React from "react"

import { PollContainer, PollContainerType } from "../../common/components/poll-container/poll-container"
import PollSection from "../../common/components/poll-container/poll-section"
import WrongAnswerSection from "../../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../../common/components/poll-container/answer-section"

import MedicaidReformModel from "../../../config/tour/spending/tour-medicaid-reform-model"
const Model = MedicaidReformModel.MedicaidReformPoll

const Poll = (props) => (
	<PollSection {...props}>
    {Model.promptHeadline}
    {Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
        return (
          <React.Fragment>
            {Model.wrongAnswerHeadline(props.options[props.answer])}
            {Model.wrongAnswerBody(props.options[props.answer])}
          </React.Fragment>
        )
			}
		}
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel="What else can we try cutting?" {...props}>
		{ gotItRight => (
			<React.Fragment>
        {Model.correctAnswerHeadline(gotItRight, props.options[props.correctAnswer])}
        {Model.correctAnswerBody(props.options[props.correctAnswer])}
			</React.Fragment>
		)}
	</AnswerSection>
)

const ReformMedicaidStart = ({isMobile, nextSection}) => (
  <PollContainer 
    isMobile={isMobile}
    options={Model.options}
    nominalDebtForOptions={Model.nominalDebtForOptions}
    correctAnswer={Model.answer}
    debtGDPPercentForOptions={Model.GDPPercentForOptions}
    componentForType={ type => {
      switch(type) {
        case PollContainerType.POLL:
          return Poll
        case PollContainerType.WRONGANSWER:
          return WrongAnswer
        case PollContainerType.ANSWER:
          return Answer
        case PollContainerType.WHATELSE:
          return null
        default:
          throw new Error("Unknown poll container type: " + type);
      }
    }}
    nextSection={nextSection}
  />
)
export default ReformMedicaidStart

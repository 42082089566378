import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import "./accordion.css"

class Accordion extends React.Component {
  static propTypes = {
    rows: PropTypes.array.isRequired,
  }

  constructor(props) {
		super(props)

		this.state = {
      activeIndex: 0,
		}
  }
  
  buildOnClick = (i) => {
    return (e) => {
      e.preventDefault()
      this.setState({
        activeIndex: i !== this.state.activeIndex ? i : null,
      })
    }
  }

  render() {
    const rows = this.props.rows.map((r , i) => {
      const isActive = i === this.state.activeIndex
      const rowClasses = cx({
        "accordion-row": true,
        "is-expanded": isActive,
        "is-collapsed": !isActive,
      })
      return (
        <div key={"row-" + i} className={rowClasses}>
          <button className="header" onClick={this.buildOnClick(i)}>
            <h3 className="label">
              <span className="disclosure-indicator">{isActive ? "▾" : "▸" }</span>
              <span className="title">{r.title}</span>
            </h3>
          </button>
          <div className="body">
            {r.body}
          </div>
        </div>
      )
    })

    return (
      <div className="accordion">
        {rows}
      </div>
    )
  }

}
export default Accordion
import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import Const from "../../../config/constants"
import ScrollIndicatorIconSVG from "../../../common/components/scroll-indicator-icon/scroll-indicator-icon"

import "./scroll-indicator.css"

class ScrollIndicatorIcon extends React.Component {
	static propTypes = {
		step: PropTypes.number.isRequired,
		progress: PropTypes.number.isRequired,
	}

	getStrokeColor() {
		if (this.props.step === 0) {
			if (this.props.progress < 1) {
				return "transparent"
			} else {
				return Const.GREEN_COLOR
			}
		}

		if (this.props.step === 1) {
			return Const.GREEN_COLOR
		}

		if (this.props.step === 2 || this.props.step === 3) {
			return Const.BLUE_COLOR
		}

		if (this.props.step === 4) {
      if (this.props.progress === 1) {
        return Const.WHITE_COLOR 
      }
			return Const.RED_COLOR
		}

		if (this.props.step >= 5) {
			return Const.WHITE_COLOR
		}

		return Const.BLACK_COLOR
	}

	render() {
		let animate = false
		if (this.props.progress === 0 && (this.props.step === 1 || 
				this.props.step === 5 || 
				this.props.step === 9)) {
			animate = true
		}

		let isHidden = false
		if ((this.props.step === 16 && this.props.progress > 0) || 
					this.props.step >= 17) {
			isHidden = true 
		}

		const classes = cx({
			"scroll-indicator-icon": true,
			"should-bounce": animate,
			"is-hidden": isHidden,
		})

		return (
				<div className={classes}>
					<ScrollIndicatorIconSVG style={{stroke: this.getStrokeColor()}}/>
				</div>
			);
	}
}

const ScrollIndicator = ({model, isMobile, onClick}) => {
  const whiteFooterVisible = ((model.step === 0 && model.getProgress(0) === 1) || (model.step === 1 && model.getProgress(1) === 0))
  const redFooterVisible = ((model.step === 4 && model.getProgress(4) === 1) || model.step === 5)
  const purpleFooterVisible = (model.step === 8 && model.getProgress(8) >= 0.6) || (model.step === 9 && model.getProgress(9) === 0)
  const visible = whiteFooterVisible || redFooterVisible || purpleFooterVisible

  const barClasses = cx({
    "scroll-indicator-bar": true,
    "white": whiteFooterVisible,
    "red": redFooterVisible,
    "purple": purpleFooterVisible,
  })

  const titleClasses = cx({
    "scroll-indicator-title": true,
    "hidden": !visible,
  })

  return (
    <footer className="scroll-indicator" onClick={onClick}>
      <div className={barClasses}>
        <ScrollIndicatorIcon 
          step={model.step} 
          progress={model.getProgress(model.step)}
        />
        <div className={titleClasses}>
          {	whiteFooterVisible && isMobile &&
            <p>What did debt look like in {model.year}?</p>
          }
          {	whiteFooterVisible && !isMobile &&
            <p>What did the U.S. debt look like in {model.year}?</p>
          }
          { redFooterVisible &&
            <p>Fast forward 30 years ahead</p>
          }
          { purpleFooterVisible &&
            <p>How does that impact you?</p>
          }
        </div>
      </div>
    </footer>
  )
}
export default ScrollIndicator
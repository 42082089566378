
import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

const YearDebtExceedsGDP = [2034, 2035, 2035, 2035]
const Options = ["25%", "50%", "75%", "100%"]

const CutEPA = {
  options: Options,

  answer: 3,

  GDPPercentForOptions: [142, 140, 138, 137],

  nominalDebtForOptions: [93.6, 92.7, 91.7, 90.7],

  // Prompt

  promptHeadline: (
    <h2>
      Cut the Environmental Protection Agency budget
    </h2>
  ),

  promptBody: (
    <p>
      How big would the cuts need to be to reach our goal?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Nope. A {answer} cut wouldn't do it.
    </h2>
  ),

  wrongAnswerBody: (answer) => (
    <p>
      In {Facts.econ.year} the EPA's budget was a little over $8 billion. A {answer} cut won't have a huge impact on the national debt.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer, diffP) => (
    <h2>
      Cutting the entire EPA budget would only lower debt by {diffP}% of GDP.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
      This means closing the EPA and eliminating all federal enforcement of clean air and waters law, research, and assessments. The agency consumes only a tiny share of the federal budget - roughly $8 billion out of over $4 trillion. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default CutEPA

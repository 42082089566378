import React from "react"

const GraphYearLegend = ({startYear, endYear, canvasWidth, isMobile, bottomPadding, sidePadding, ...rest}) => {
  const count = isMobile ? 1 : 6
  const increment = (endYear - startYear) / count
  
  let years = []
  for (let i = 0; i <= count; i++) {
    years.push(startYear + increment * i)
  }

  const insetWidthIncrement = canvasWidth / count

  const textRows = years.map((year, index) => {
    let textAnchor = "middle"
    let x = insetWidthIncrement * index
    if (index === 0) {
      textAnchor = "start"
      x = sidePadding
    } else if (index === count) {
      textAnchor = "end"
      x = canvasWidth - sidePadding
    }

    const label = Math.round(year)
  
    return (
      <text 
        key={label} 
        x={x} 
        y={bottomPadding} 
        textAnchor={textAnchor} 
        dy="1"
      >
        {label}
      </text>
    )
  })

  return (
    <g {...rest}>
      {textRows}
    </g>
  )
}

export default GraphYearLegend
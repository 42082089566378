
import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

const YearDebtExceedsGDP = [2034, 2035, 2035, 2036]
const Options = ["25%", "50%", "75%", "100%"]

const CutForeignAid = {
  options: Options,

  answer: 3,

  GDPPercentForOptions: [141, 139, 137, 135],

  nominalDebtForOptions: [93.3, 92.1, 90.8, 89.6],

  // Prompt

  promptHeadline: (
    <h2>
      Cut Foreign Aid and International Affairs
    </h2>
  ),

  promptBody: (
    <p>
      How big would the cuts need to be to reach our goal?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Nope. A {answer} cut wouldn't do it.
    </h2>
  ),

  wrongAnswerBody: (answer) => (
    <p>
      The U.S. only spent $49 billion on international programs last year. A {answer} cut won't have a huge impact on the national debt.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer, diffP) => (
    <h2>
      Eliminating international programs would only reduce the {Facts.econ.projectedYear} debt by {diffP}% of GDP.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
      This would require closing the State Department, as well as ending all security, health, disaster, and economic development programs. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default CutForeignAid

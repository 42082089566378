import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let DiscretionarySpendingModel = {}

DiscretionarySpendingModel.LandingSection = {
  headline: (
    <h2>
      What are discretionary programs?
    </h2>
  ),

  body: (
    <React.Fragment>
      <p>
        Government discretionary spending comes from appropriations bills, the annual budgetary amount Congress puts aside to fund federal programs. The Senate and House vote on where the money should be spent, such as national defense, foreign aid, and the EPA.
      </p>
      <p>
        These appropriations make up nearly one-third of the total federal budget.
      </p>
    </React.Fragment>
  ),

  scrollIndicatorLabel: "Cut all non-defense discretionary programs",
}

const YearDebtExceedsGDP = [2038, 2041, 2045, 2050]
const Options = ["18%", "28%", "38%", "48%"]

DiscretionarySpendingModel.CutDiscretionarySpendingPoll = {

  options: Options,

  answer: 3,

  GDPPercentForOptions: [127, 117, 108, 99],

  nominalDebtForOptions: [84.3, 78.6, 72.9, 67.2],

  // Prompt

  promptHeadline: (
    <h2>
      Cut all non-defense discretionary programs.
    </h2>
  ),

  promptBody: (
    <p>
			This would include cutting the Justice Department, State Department, and a host of other government agencies and programs. How much would we have to cut from their budgets?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Nope. A {answer} cut wouldn't be enough.
    </h2>
  ),

  wrongAnswerBody: (answer) => (
    <p>
      Non-defense discretionary programs only make up about 1/6 of non-interest federal spending. Cutting these programs may slow the growth in debt, but not enough to meet our goal. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }Non-defense discretionary spending would need to be cut by {answer}.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
      A {answer} cut would bring spending on these programs back to levels not experienced since the 1970s (adjusted for inflation).  We would spend billions of dollars less on federal law enforcement, international affairs, environmental protection, education, transportation, and several other programs.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default DiscretionarySpendingModel

import React from "react"
import MainMenu from "./components/main-menu/main-menu"
import ResponsiveWrapper from "./components/responsive-wrapper/responsive-wrapper"

import "./static-pages-container.css"
import "./about-page.css"

const AboutPage = ({isMobile}) => (
  <div id="static-pages-container">
    <div id="about-page">
      <MainMenu isMobile={isMobile}/>
      <div className="content">
        <h2>About America Off Balance</h2>
        <p>
          The nation is quickly approaching a fiscal disaster.  Without major reforms, future government spending will far exceed future revenues. This fiscal imbalance threatens future generations.  It will mean fewer economic opportunities and resources available for national security and infrastructure. Despite this reality, few Americans seem concerned. Many are unaware of the problem. Others wrongly conclude that minor reforms, enacted in the distant future, will be sufficient. 
        </p>
        <p>
          America Off Balance seeks to fill this knowledge gap, providing visitors with up-to-date facts on the future of the federal budget. It encourages visitors to rethink their assumptions about the federal budget and to be skeptical of painless budget solutions often proposed by policymakers and partisan activists. 
        </p>
        <p>
          America Off-Balance is inspired by <a href="https://www.kpcb.com/blog/2011-usa-inc-full-report" target="_blank" rel="noopener noreferrer">USA Inc.</a>, a comprehensive 2011 report covering the federal budget created by Mary Meeker. The website was designed and built by <a href="http://www.hyperakt.com" target="_blank" rel="noopener noreferrer">Hyperakt</a> with illustrations by <a href="https://sebastienthibault.com/" target="_blank" rel="noopener noreferrer">Sebastien Thibault</a>.
        </p>
        <p>
          The project is now part of <a href="https://www.hoover.org/" target="_blank" rel="noopener noreferrer">Hoover Institution's</a> <a href="https://www.policyed.org/" target="_blank" rel="noopener noreferrer">Educating Americans in Public Policy</a>. 
        </p>
        <p>
          Since its founding nearly 100 years ago, Stanford University's Hoover Institution has sought to promote economic opportunity and prosperity, secure peace, and improve the human condition. Unique among policy research organizations, Hoover is part of a world-renowned university. In addition to being scholars, our fellows are educators.
        </p>
        <p>
          Seeking to become the foremost source of policy knowledge, wisdom, and insights, we have launched the Mary Jo and Dick Kovacevich Initiative at the Hoover Institution, Educating Americans in Public Policy. The initiative seeks to:
        </p>
        <ul>
          <li>
            <p>
              Equip Americans with accurate facts and information, as well as a discerning analytical perspective, so they can better perform their civic duties, hold their elected leaders accountable, and "secure the blessings of liberty to ourselves and our posterity."
            </p>
          </li>
          <li>
            <p>
              Provide political leaders with reliable knowledge and analysis-tools with which they might assess alternatives in the shaping and execution of public policy.
            </p>
          </li>
        </ul>
        <p>
          This effort will build on our legacy of substantive policy inquiry where partisan advocacy has become the norm.
        </p>
      </div>
    </div>
  </div>
)

const ResponsiveAboutPage = () => (
  <ResponsiveWrapper>
    <AboutPage />
  </ResponsiveWrapper>
)

export default ResponsiveAboutPage
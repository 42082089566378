import React from "react"

import ReformMedicaidLanding from "./reform-medicaid-landing"
import ReformMedicaidStart from "./reform-medicaid-start"
import MedicaidReformAccordion from "./medicaid-reforms-accordion"
import SpendingWhatElse from "../spending-what-else"
import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

const ReformSocialSecurityPage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="cut-medicaid-page-wrapper">
			<div id="tour-spending-container">
				<div id="tour-reform-medicaid-page">
					<ReformMedicaidLanding />
					<MedicaidReformAccordion key="medicaid-reform-accordion" isMobile={isMobile}/>
					<ReformMedicaidStart isMobile={isMobile} nextSection={[
						<SpendingWhatElse key="spending-what-else" isMobile={isMobile}/>
					]}/>
				</div>
			</div>
		</SideMenuWrapper>
	)
}

export default ReformSocialSecurityPage

import React from "react"
import ReactDOM from "react-dom"

// Polyfills
import "babel-polyfill"
import smoothscroll from "smoothscroll-polyfill"
import IntersectionObserver from "intersection-observer" // eslint-disable-line no-unused-vars
import App from "./app/app"

smoothscroll.polyfill()
// window.__forceSmoothScrollPolyfill__ = true

ReactDOM.render(<App />, document.getElementById("root"))

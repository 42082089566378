import React from "react"

import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

import IllustrationSidebarWrapper from "../illustration-sidebar-wrapper"
import WhatElseMenu from "../economy-what-else-menu"
import TaxReformPNG from "./illustrations/10.1.png"

import TourEconomyModel from "../../../config/tour/economy/tour-economy"
const Model = TourEconomyModel.TaxReform

const TaxReformPage = ({isMobile}) => {
	return (
      <SideMenuWrapper key="tax-reform-page-wrapper">
        <IllustrationSidebarWrapper key="tax-reform-wrapper" src={TaxReformPNG} alt={Model.illustrationAltDescription} isMobile={isMobile}>
          <div id="tour-economy-container">
            <div className="full-page-w-menu is-first-page row-content v-center">
              <div className="content">
                {Model.headline}
                {Model.body}
                <WhatElseMenu isMobile={isMobile}/>
              </div>
            </div>
          </div>
        </IllustrationSidebarWrapper>
      </SideMenuWrapper>
	)
}

export default TaxReformPage


import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let TourSpendingIntro = {}

// Landing Section

TourSpendingIntro.LandingSection = {
  headline: (
    <h2>
      Let's take a look at federal spending over the next 30 years
    </h2>
  ),

  body: (
    <p>
      Cutting spending sounds straightforward, but is actually quite complicated.
    </p>
  ),

  scrollIndicatorLabel: "What do we mean by cuts?",
}

// What Do We Mean By Cuts Section

TourSpendingIntro.WhatDoWeMeanByCutsSection = {
  headline: (
    <h2>
      What do we mean by cuts?
    </h2>
  ),

  body: (
    <React.Fragment>
      <p>
        In this section, a cut refers to a reduction in spending based on the Congressional Budget Office's baseline projections, which assumes expenditures will grow over time.
      </p>
      <p>
        This means that spending may continue to grow, just not as fast as CBO currently projects. In some cases, these organizations might receive billions less in funding than the prior years. In all, this will require many sacrifices.
      </p>
    </React.Fragment>
  ),

  scrollIndicatorLabel: "Let's cut spending",

  illustrationAltDescription: "",
}

// What Next Section

TourSpendingIntro.WhatNextSection = {
  headline: (
    <h2>
      Balance the budget by reducing spending. Let's explore some more options:
    </h2>
  )
}

export default TourSpendingIntro

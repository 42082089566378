import React from "react"

const GraphLabel = ({label, x, y, isMobile, ...rest}) => {
  const lineLength = isMobile ? 5 : 40
  const textPadding = isMobile ? 10 : lineLength + 5
  return (
    <g {...rest}>
			<text x={x - textPadding} y={y} textAnchor="end" alignmentBaseline="middle" dy="1">{label}</text>
      <line x1={x - lineLength} y1={y} x2={x} y2={y} />
    </g>
  )
}

export default GraphLabel
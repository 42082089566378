import React, { Component } from "react"
import {throttle} from "lodash"
import innerHeight from "ios-inner-height"
import PropTypes from "prop-types"

import SVGBackgroundGraph from "./svg-background-graph"
import SVGUtil from "../../../../intro/utils/svg-util"
import GraphYearLegend from "../../../../common/components/graph/graph-year-legend"
import GraphLabel from "../../../../common/components/graph/graph-label"


import "./background-graph.css"

const SideMenuWidth = 40;

class BackgroundGraph extends Component {
	static propTypes = {
		points: PropTypes.array.isRequired,
		fromYear: PropTypes.number.isRequired,
		toYear: PropTypes.number.isRequired,
		graphLabel: PropTypes.string,
		isMobile: PropTypes.bool,
		hasNoSidebar: PropTypes.bool,
		goalLabel: PropTypes.string,
		toValueLabel: PropTypes.string,
		goalValueLabel: PropTypes.string,
  }

	constructor(props) {
		super(props)

		this.state = {
			windowWidth: 0,
			windowHeight: 0,
		}
	}

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    this.updateDimensions()
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize)
  }

  handleResize = throttle(() => {
		this.updateDimensions()
  }, 20)

	updateDimensions = (callback) => {
		const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
		const h = innerHeight()

		let windowWidth = w
		if (this.props.isMobile || this.props.hasNoSidebar) {
			// noop
		} else {
			windowWidth -= SideMenuWidth
		}

		this.setState({
			windowWidth: windowWidth,
			windowHeight: h,
		}, callback)
	}

	render() {
    const w = this.state.windowWidth;
    const h = 1/2 * this.state.windowHeight;
    const {commands} = SVGBackgroundGraph(w, h, this.props.points)
		const path = SVGUtil.toString(commands)
		
		const graphLabelPadding = w < 600 ? 10 : 15
		const graphLabelBottomPadding = -20

		return (
      <aside className="background-graph">
        <svg 
          width={w} 
          height={h}
          viewBox={`0 0 ${w} ${h}`} 
          preserveAspectRatio="xMinYMax meet">
          <g>
            <g>
              <path d={path} />
							<g transform={`translate(0, ${h})`}>
								<g className="background-graph-label-group">
									<text x={w - graphLabelPadding} y={-h * 45/100} textAnchor="end" dy="1">{this.props.graphLabel}</text>
								</g>
								{this.props.goalValueLabel &&
									<GraphLabel 
									className="background-graph-label-group goal"
									label={this.props.goalValueLabel}
									x={w}
									y={h * -0.7}
									isMobile={this.props.isMobile}
									/>
								}
								{this.props.toValueLabel &&
									<GraphLabel 
									className="background-graph-label-group top"
									label={this.props.toValueLabel}
									x={w}
									y={-h}
									isMobile={this.props.isMobile}
									/>
								}
							
								<GraphYearLegend 
									startYear={this.props.fromYear}
									endYear={this.props.toYear}
									canvasWidth={w}
									isMobile={this.props.isMobile}
									bottomPadding={graphLabelBottomPadding}
									sidePadding={graphLabelPadding}
									className="background-graph-label-group"
								/>
							</g>
            </g>
          </g>
        </svg>
      </aside>
		)
	}
}

export default BackgroundGraph

import React from "react"

import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"
import Accordion from "../../common/components/accordion/accordion"
import SocialSecurityReformModel from "../../../config/tour/spending/tour-social-security-reform-model"

const SocialSecurityReformsAccordion = () => {
	return (
		<ScrollIndicatorWrapper 
			id="social-security-reforms-accordion-wrapper" 
			label="What if we enacted these proposed reforms?">
			<section id="social-security-reforms-accordion" className="full-page-w-menu row-content v-center">
				<div className="content">
					<div className="scroll-anchor scroll-anchor-impl" />
					<h2>{SocialSecurityReformModel.ReformsAccordionTitle}</h2>
					<Accordion 
						className="scroll-anchor scroll-middle"
						rows={SocialSecurityReformModel.ReformsAccordionRows}
					/>
				</div>
			</section>
		</ScrollIndicatorWrapper>
	)
}

export default SocialSecurityReformsAccordion


import React from "react"

import { PollContainer, PollContainerType } from "../common/components/poll-container/poll-container"
import PollSection from "../common/components/poll-container/poll-section"
import WrongAnswerSection from "../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../common/components/poll-container/answer-section"
import WhatElse from "./economy-what-else"

import EconomyModel from "../../config/tour/economy/tour-economy-intro"
const Model = EconomyModel.Poll

const Poll = (props) => (
	<PollSection {...props}>
		{Model.promptHeadline}
		{Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
				if (notEnough) {
					return (
						<div>
							{Model.wrongAnswerTooLittleHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooLittleBody(props.options[props.answer])}	
						</div>
					)
				} else {
					return (
						<div>
							{Model.wrongAnswerTooMuchHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooMuchBody(props.options[props.answer])}	
						</div>
					)
				}
			}
		}
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel="How can we increase economic growth?" {...props}>
		{ gotItRight => (
			<div>
				{Model.correctAnswerPromptHeadline(gotItRight, props.options[props.correctAnswer])}
				{Model.correctAnswerPromptBody}
			</div>
		)}
	</AnswerSection>
)

const WhatElseNoExplore = (props) => (
  <WhatElse {...props} />
)

const EconomyStart = ({isMobile}) => (
  <PollContainer 
    isMobile={isMobile}
		options={Model.options}
		nominalDebtForOptions={Model.nominalDebtForOptions}
    correctAnswer={Model.answer}
    debtGDPPercentForOptions={Model.GDPPercentForOptions}
    componentForType={ type => {
      switch(type) {
        case PollContainerType.POLL:
          return Poll
        case PollContainerType.WRONGANSWER:
          return WrongAnswer
        case PollContainerType.ANSWER:
          return Answer
        case PollContainerType.WHATELSE:
					return WhatElseNoExplore
				default:
					throw new Error("Unknown poll container type: " + type);
      }
    }}
  />
)
export default EconomyStart

import React from "react"

const CircleLabel = ({title, value, style}) => {
  return (
    <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" dy="1" style={style} className="circle-label">
      <tspan x="50%" dy="-12px" className="title">{title}</tspan>
      <tspan x="50%" dy="+26px">{value}</tspan>
    </text>
  )
}

export default CircleLabel
import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import SplashIllustrationJPG from "../illustrations/1.2.jpg"

class SplashIllustration extends React.Component {

  static propTypes = {
		alt: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  onLoad = () => {
    this.setState({
      loaded: true,
    })
  }

  render() {
    const classes = cx({
      "splash-illustration": true,
      "is-loaded": this.state.loaded,
    })
    return (
      <div className={classes}>
        <img src={SplashIllustrationJPG} alt={this.props.alt} onLoad={this.onLoad} />
      </div>
    )
  }
}

export default SplashIllustration
import React from "react"
import ScrollPercentage from "react-scroll-percentage"
import WhatElseMenu from "./economy-what-else-menu"

const EconomyWhatElse = ({ onViewPercentageChange, ...rest }) => (
  <ScrollPercentage onChange={onViewPercentageChange || null}>
    <section className="row-content full-page-w-menu v-center w-sidebar">
      <div>
        <div className="content">
          <div className="scroll-anchor scroll-anchor-impl"/>
          <h2>What policies do you think could increase economic growth?</h2>
        </div>
        <WhatElseMenu {...rest} />
      </div>
    </section>
  </ScrollPercentage>
)

export default EconomyWhatElse


function partialCircle (cx, cy, r, startAngle, endAngle) {
	const length = endAngle - startAngle
	if (length === 0) return []

	const fromX = r * Math.cos(startAngle) + cx
	const fromY = r * Math.sin(startAngle) + cy
	const toX = r * Math.cos(endAngle) + cx
	const toY = r * Math.sin(endAngle) + cy

	let large, sweep = 0
	if (length < 0) {
		large = Math.abs(length) <= Math.PI ? 1 : 0
		sweep = length < 0 ? 1 : 0
	} else {
		// Flip when we cross the negative axis
		large = Math.abs(length) <= Math.PI ? 0 : 1
		sweep = length < 0 ? 0: 1
	}

	return [
		['M', fromX, fromY],
		['A', r, r, 0, large, sweep, toX, toY],
	]
}

export default partialCircle

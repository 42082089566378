import React from "react"

import { PollContainer, PollContainerType } from "../../common/components/poll-container/poll-container"
import PollSection from "../../common/components/poll-container/poll-section"
import WrongAnswerSection from "../../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../../common/components/poll-container/answer-section"

import MedicareReformModel from "../../../config/tour/spending/tour-medicare-reform-model"
const Model = MedicareReformModel.MedicareReformPoll

const Poll = (props) => (
	<PollSection {...props}>
		{Model.promptHeadline}
		{Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
				if (notEnough) {
					return (
						<React.Fragment>
							{Model.wrongAnswerTooLittleHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooLittleBody(props.options[props.answer])}
						</React.Fragment>
					)
				} else {
					return (
						<React.Fragment>
							{Model.wrongAnswerTooMuchHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooMuchBody(props.options[props.answer])}
						</React.Fragment>
					)
				}
			}
		}
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel={Model.answerScrollIndicatorLabel} {...props}>
		{ gotItRight => (
			<div>
				{Model.correctAnswerHeadline(gotItRight, props.options[props.correctAnswer])}
				{Model.correctAnswerBody(props.options[props.correctAnswer])}
			</div>
		)}
	</AnswerSection>
)

const ReformMedicareStart = ({isMobile, nextSection}) => (
  <PollContainer 
    isMobile={isMobile}
		options={Model.options}
		nominalDebtForOptions={Model.nominalDebtForOptions}
    correctAnswer={Model.answer}
    debtGDPPercentForOptions={Model.GDPPercentForOptions}
    componentForType={ type => {
      switch(type) {
        case PollContainerType.POLL:
          return Poll
        case PollContainerType.WRONGANSWER:
          return WrongAnswer
        case PollContainerType.ANSWER:
          return Answer
        case PollContainerType.WHATELSE:
					return null
				default:
					throw new Error("Unknown poll container type: " + type);

      }
    }}
    nextSection={nextSection}
  />
)
export default ReformMedicareStart

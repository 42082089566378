
import React from "react"

import Facts from "../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let IntroModel = {}

// Splash Section

IntroModel.SplashSection = {
  illustrationAltDescription: "Person looking down a hole in the shape a bag leaking dollar bills",
}

// Start Section

IntroModel.StartSection = {
  headline: (
    <h1>America is Facing a Financial Crisis</h1>
  ),

  body: (
    <React.Fragment>
      <p>
        Let's chat about the national debt. You know it's a problem. The current deficit and its more dire future projections are hard to fathom. The numbers are enormous, in the trillions, and all Americans will have to pay their share.
      </p>
      <p>
        Do you know the true cost?
      </p>
    </React.Fragment>
  ),

  illustrationAltDescription: "Person sitting under an anvil held up by a string made up of dollar bills",
}

// Animation Section

// We're only exposing tooltip values for the animated section because copy changes
// in this section are size sensitive and the rest of the animation elements
// are carefully positioned around existing copy.
// See main facts.js model if you're looking to edit numbers in this section.

IntroModel.AnimationSection = {

  taxRevenueTooltip: "Tax revenue is the total amount the government collects in taxes. In 2019, the federal government will collect $1.64 trillion in personal income taxes, $1.18 trillion in payroll taxes, $243 billion in corporate income taxes, and $278 billion from other taxes.",

  // Note: <br /> string will be converted to a new line by the Tooltip component
  spendingCategoriesTooltip: `
    $1,040 billion: Healthcare<br />
    $622 billion: Defense<br />
    $984 billion: Social Security<br />
    $317 billion: Interest payments
  `,

  deficitTooltip: "The amount that spending exceeds revenue.",

  gdpTooltip: "Gross Domestic Product (GDP) is the total amount of goods and services produced in a country during the year. In 2018, U.S. GDP was $20.2 trillion.",

  federalDebtTooltip: "The federal debt is the amount of money the federal government has borrowed from the public. It does not include money owed to federal trust funds or other intergovernmental debt since the government owes this money to itself.",
}

// Conclusion Section

// Note: careful with making any of the conclusion copy longer as it must fit
// on a single screen across all devices.

IntroModel.ConclusionSection = {}

IntroModel.ConclusionSection.shareOfDebt = {
  headline: (
    <h1>Your share of the debt?</h1>
  ),

  body: (
    <p>
      Right now, $50,000. That's for every person, of any age. A person born today will bear $134,000 in federal debt when they turn 30.
   </p>
  ),

  illustrationAltDescription: "Person carrying a single weight with a shadow that shows three weights",
}

IntroModel.ConclusionSection.lowerWages = {
  headline: (
    <h1>Your wages will be lower</h1>
  ),

  body: (
    <p>
      The government will have to borrow more money, making it more expensive for businesses to expand and hire more workers. Wages will be lower as a result.
   </p>
  ),

  illustrationAltDescription: "A large snail having a tug of war with a small bull",
}

IntroModel.ConclusionSection.safetyAtRisk = {
  headline: (
    <h1>On top of that, your safety will be at risk</h1>
  ),

  body: (
    <p>
      Growing deficits make it harder for the government to react to national emergencies and foreign conflicts. If your area is hit by a hurricane or terrorist attack, the response would be compromised.
   </p>
  ),

  illustrationAltDescription: "Sharks circling a sunken US Capitol building",
}

IntroModel.ConclusionSection.spendingHarderToControl = {
  headline: (
    <h1>Spending will be harder to control</h1>
  ),

  body: (
    <p>
      With large debt levels, small increases in interest rates will mean large increases in interest spending. A 1 percentage point increase in long-run interest rates will increase interest outlays by over 50%.
   </p>
  ),

  illustrationAltDescription: "A person walking off the edge of a staircase in the shape of 0%",
}

IntroModel.ConclusionSection.direOutlook = {
  headline: (
    <h1>All in all, it's a pretty dire outlook, wouldn't you say?</h1>
  ),

  illustrationAltDescription: "A person's hands forming a crystal ball with a dynamite string",
}

IntroModel.ConclusionSection.headInTheSand = {
  headline: (
    <h1>So, why haven't we heard more about this!?</h1>
  ),

  body: (
    <p>
      Politicians avoid the topic. Some government spending is supported by powerful interest groups like farmers, corporations, or federal workers. And big policies that might fix the issue are unpopular, regardless of party affiliation: reducing healthcare, Social Security, welfare, etc. They usally opt for policies that look good now, but could have dire consequences in the long run.
    </p>
  ),

  illustrationAltDescription: "An ostrige decorated with the American flag with it's head in the sand",
}

IntroModel.ConclusionSection.thingsNeedToChange = {
  headline: (
    <h1>Things need to change, and fast</h1>
  ),

  body: (
    <p>
      Politicians need to be held accountable. We need to take charge of our future. To better understand the issue, examine the ways that trade-offs of spending cuts, tax increases, and growing the economy - among many other policy changes - could help reduce the debt over the next 30 years. It will be eye-opening and tough, but it is necessary.
    </p>
  ),

  illustrationAltDescription: "People filling a hole in the shape of a down arrow",
}

IntroModel.ConclusionSection.tourButton = {
  headline: (
    <h2>
      Tour the Budget
    </h2>
  ),

  body: (
    <p>
      The challenge is more daunting than you can imagine. Is there a way to fix this? Explore some popular options for balancing the budget.
    </p>
  ),

  title: "The Balancing Act",
}

IntroModel.ConclusionSection.calculatorButton = {
  headline: (
    <h2>
      Fix the Budget
    </h2>
  ),

  body: (
    <p>
      Have what it takes to suggest the ultimate budget? We want to see it.
    </p>
  ),

  title: "The Budget Calculator",
}

export default IntroModel

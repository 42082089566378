import React from "react"
import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"

import "./economy-productivity-employment.css"

import EconomyModel from "../../config/tour/economy/tour-economy-intro"
const Model = EconomyModel.EconomyProductivitySection

const EconomyProductivityEmployment = () => (
	<ScrollIndicatorWrapper id="economy-productivity-employment-wrapper" label={Model.scrollIndicatorLabel}>
		<section id="economy-productivity-employment" className="row-content full-page-w-menu v-center">
			<div className="content">
        <div className="scroll-anchor scroll-anchor-impl" />
				{Model.headline}
        <div className="row-wrapper">
          <div className="column-50">
            {Model.productivity.headline}
            {Model.productivity.body}
          </div>
          <div className="column-50">
            {Model.employment.headline}
            {Model.employment.body}
          </div>
        </div>
			</div>
		</section>
	</ScrollIndicatorWrapper>
)
export default EconomyProductivityEmployment

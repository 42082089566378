class ScrollLayoutModel {
	constructor(w, h) {
		this.w = w
		this.h = h

		this.l0 = {
			h: this.h,
			bP: Math.round(0.25 * this.h),
			y: 0,
		}

		this.l1 = {
			h: this.h,
			bP: Math.round(0.5 * this.h),
			y: this._cY(this.l0),
		}

		this.l2 = {
			h: this.h,
			bP: Math.round(0.7 * this.h),
			y: this._cY(this.l1),
		}

		this.l3 = {
			h: this.h,
			bP: 0,
			y: this._cY(this.l2),
		}

		this.l4 = {
			h: this.h,
			bP: Math.round(0.5 * this.h),
			y: this._cY(this.l3),
		}

		this.l5 = {
			h: this.h,
			bP: 0,
			y: this._cY(this.l4),
		}

		this.l6 = {
			h: this.h,
			bP: 0,
			y: this._cY(this.l5),
		}

		this.l7 = {
			h: this.h,
			bP: 0,
			y: this._cY(this.l6),
		}

		this.l8 = {
			h: this.h,
			bP: Math.round(0.5 * this.h),
			y: this._cY(this.l7),
		}

		this.l9 = {
			h: this.h,
			bP: 0,
			y: this._cY(this.l8),
		}

		// 10 is the space the conclusion-content is holding
		this.l10 = {
			h: this.h,
			bP: 0,
			y: this._cY(this.l9),
		}

		this.l11 = {
			h: this.h,
			bP: Math.round(0.5 * this.h),
			y: this._cY(this.l10),
		}

		this.l12 = {
			h: this.h,
			bP: Math.round(0.5 * this.h),
			y: this._cY(this.l11),
		}

		this.l13 = {
			h: this.h,
			bP: Math.round(0.5 * this.h),
			y: this._cY(this.l12),
		}

		this.l14 = {
			h: this.h,
			bP: Math.round(0.5 * this.h),
			y: this._cY(this.l13),
		}

		this.l15 = {
			h: this.h,
			bP: 0,
			y: this._cY(this.l14),
		}

		this.l16 = {
			h: Math.round(0.5 * this.h),
			bP: 0,
			y: this._cY(this.l15),
		}

		this.l17 = {
			h: Math.round(0.5 * this.h),
			bP: 0,
			y: this._cY(this.l16),
		}

		this.layouts = [
			this.l0,
			this.l1,
			this.l2,
			this.l3,
			this.l4,
			this.l5,
			this.l6,
			this.l7,
			this.l8,
			this.l9,
			this.l10,
			this.l11,
			this.l12,
			this.l13,
			this.l14,
			this.l15,
			this.l16,
			this.l17,
		] 
	}

	calculateProgress(pageOffset, prevPageOffset) {
		const step = this.stepForPageYOffset(pageOffset)
		const scrollingUp = (prevPageOffset > pageOffset) ? true : false
		
		const i = step
		const layout = this.layouts[i]
		const nextLayout = this.layouts[scrollingUp ? i - 1 : i + 1]

		let progress = (pageOffset - layout.y) / layout.h || 0
		if (progress > 1.0) {
			progress = 1.0
		}

		if ((scrollingUp && nextLayout && nextLayout.bP > 0) || layout.bP > 0) {
			progress = this._ease(progress)
		}

		return {
			step: step,
			progress: progress,
		}
	}

	stepForPageYOffset(yOffset) {
		const stepsCount = this.layouts.length

		if (yOffset <= 0) {
			return 0
		}

		for (var i = 0; i < stepsCount - 1; i++) {
			let nextStepIndex = i + 1
			// If our pageY offset is less than the next step's 
			// yOffset return current index
			let nextL = this.layouts[nextStepIndex]
			if (yOffset < nextL.y) {
				return i
			}
		}

		// We're on the last step, return current index
		return stepsCount - 1
	}

	pageYOffsetForStep(step) {
		const l = this.layouts[step]
		return l.y
	}

	stepCount() {
		return this.layouts.length
	}

	// Utils
	_cY(prevL) {
		return prevL.y + prevL.h + prevL.bP
	}

	_ease(t) {
		//https://gist.github.com/gre/1650294
		//easeInOutQuad
		return t<.5 ? 2*t*t : -1+(4-2*t)*t
		// return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1
		// return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t
	}
}

export default ScrollLayoutModel

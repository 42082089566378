import React  from "react"
import cx from "classnames"

import { PickerMenu, PickerMenuButton } from "../picker-menu/picker-menu"

const WrongAnswerSection = ({ isMobile, options, answer, correctAnswer, debtGDPPercent, debtGDPPercentForOptions, nominalDebtForOptions, onClick, className, children, ...rest}) => {
	const rowClasses = [cx({
		"row-content": true,
		"full-page-w-menu": true,
		"w-sidebar": true,
	}), className].join(" ")

	return (
		<section className={rowClasses} {...rest}>
			<div className="content">
				<div className="scroll-anchor scroll-anchor-impl" />
        { children(answer < correctAnswer) }
				<PickerMenu isMobile={isMobile}>
					<PickerMenuButton label="See the answer" onClick={onClick}/>
				</PickerMenu>
			</div>
		</section>
	)
}

export default WrongAnswerSection
import Point from "./point"

import { SVGLine1Landscape, SVGLine1Portrait } from "../utils/svg-line-1"
import { SVGLine2Landscape, SVGLine2Portrait } from "../utils/svg-line-2"
import { SVGLine3Landscape, SVGLine3Portrait } from "../utils/svg-line-3"
import { SVGLine4Landscape, SVGLine4Portrait } from "../utils/svg-line-4"
import { SVGLine5Lanscape, SVGLine5Portrait } from "../utils/svg-line-5"
import SVGLine6 from "../utils/svg-line-6"


class GridModelLandscape {
	constructor(w, h, offsetX, offsetY) {
		this.w = w
		this.h = h
		this.offsetX = offsetX
		this.offsetY = offsetY

		this.p1 = new Point(this.x(0), this.y(.5)) // center stage
		this.p2 = new Point(this.x(.68), this.y(.54)) // big green
		this.p3 = new Point(this.x(.45), this.y(.75)) // small green
		this.p4 = new Point(this.x(.8), this.y(.37)) // blue
		this.p5 = new Point(this.x(.5), this.y(.45)) // joined
		this.p6 = new Point(this.x(.5), this.y(1.0)) // melted
	}

	// Step 1
	line1Points() {
		return [this.p1, this.p2]
	}

	line1() {
		return SVGLine1Landscape(this.line1Points())
	}

	line
	// Step 2
	line2Points() {
		return [this.p2, this.p3]
	}

	line2() {
		return SVGLine2Landscape(this.line2Points())
	}

	line3Points() {
		const offScreenP = new Point(this.x(1.5), this.y(-1.47))
		return [offScreenP, this.p4]
	}

	line3() {
		return SVGLine3Landscape(this.line3Points())
	}

	// Step 3
	line4Points() {
		return [this.p4, this.p5]
	}

	line4() {
		return SVGLine4Landscape(this.line4Points())
	}

	line5Points() {
		return [this.p3, this.p5]
	}

	line5() {
		return SVGLine5Lanscape(this.line5Points())
	}

	// Step 5
	line6Points() {
		return [this.p5, this.p6]
	}

	line6() {
		return SVGLine6(this.line6Points())
	}

	// Util
	x(pX) { return this.offsetX + (pX * this.w) }
	y(pY) { return this.offsetY + (pY * this.h) }
}

class GridModelPortrait {
	constructor(w, h, offsetX, offsetY) {
		this.w = w
		this.h = h
		this.offsetX = offsetX
		this.offsetY = offsetY

		this.p1 = new Point(this.x(0), this.y(.5)) // center stage
		this.p2 = new Point(this.x(.25), this.y(.7)) // big green
		this.p3 = new Point(this.x(-0.35), this.y(.85)) // small green
		this.p4 = new Point(this.x(0.45), this.y(.6)) // blue
		this.p5 = new Point(this.x(0), this.y(.6)) // joined
		this.p6 = new Point(this.x(0), this.y(1.0)) // melted
	}

	// Step 1
	line1Points() {
		return [this.p1, this.p2]
	}

	line1() {
		return SVGLine1Portrait(this.line1Points())
	}

	// Step 2
	line2Points() {
		return [this.p2, this.p3]
	}

	line2() {
		return SVGLine2Portrait(this.line2Points())
	}

	line3Points() {
		const offScreenP = new Point(this.x(2.5), this.y(0))
		return [offScreenP, this.p4]
	}

	line3() {
		return SVGLine3Portrait(this.line3Points())
	}

	// Step 3
	line4Points() {
		return [this.p4, this.p5]
	}
	
	line4() {
		return SVGLine4Portrait(this.line4Points())
	}

	line5Points() {
		return [this.p3, this.p5]
	}

	line5() {
		return SVGLine5Portrait(this.line5Points())
	}

	// Step 5
	line6Points() {
		return [this.p5, this.p6]
	}

	line6() {
		return SVGLine6(this.line6Points())
	}

	// Util
	x(pX) { return this.offsetX + (pX * this.w) }
	y(pY) { return this.offsetY + (pY * this.h) }

}
export { 
	GridModelLandscape, 
	GridModelPortrait
} 

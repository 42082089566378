
import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

const YearDebtExceedsGDP = [2038, 2043, 2050, 2050]
const Options = ["2%", "4%", "6%", "8%"]

const CutAllSpendingPoll = {
  options: Options,

  answer: 2,

  GDPPercentForOptions: [128, 113, 99, 84],

  nominalDebtForOptions: [85.4, 76.1, 66.8, 57.5],

  // Prompt

  promptHeadline: (
    <h2>
      Cut spending across the board
    </h2>
  ),

  promptBody: (
    <p>
      To meet our goal of keeping the national debt under 100% GDP how much would all spending need to be reduced by?
    </p>
  ),

  // Wrong Answer

  // - too little
  wrongAnswerTooLittleHeadline: (answer) => (
    <h2>
      Nope. {answer} wouldn't be enough.
    </h2>
  ),

  wrongAnswerTooLittleBody: (answer, diffP) => (
    <p>
      A {answer} overall budget cut would reduce {Facts.econ.projectedYear} debt by {diffP}% of GDP. While significant, this isn't enough. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // - too much
  wrongAnswerTooMuchHeadline: (answer) => (
    <h2>
      A {answer} cut would do it but it is more than we need.
    </h2>
  ),

  wrongAnswerTooMuchBody: (answer, diffP) => (
    <p>
      A {answer} overall budget cut would reduce {Facts.econ.projectedYear} debt by {diffP}% of GDP. This would likely entail cuts in healthcare, public assistance programs, the EPA and NIH, and domestic law enforcement resources at the FBI and DOJ.
    </p>
  ),

  // Correct Answer

  correctAnswerPromptHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }Overall spending would need to be reduced by {answer}.
    </h2>
  ),

  correctAnswerPromptBody: (answer) => (
    <p>
			This means that every year spending will be {answer} lower than CBO currently projects. These reductions would apply to Social Security, Medicare, defense spending, and all other federal programs. We could avoid cutting some of these programs but that would mean even larger cuts to others.
    </p>
  ),

  answerScrollIndicatorLabel: "Let's explore some more options",
}

export default CutAllSpendingPoll

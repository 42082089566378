
import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let TourEconomyIntro = {}

// Landing Section

TourEconomyIntro.LandingSection = {
  headline: (
    <h2>
      Let's take a look at growing the economy
    </h2>
  ),

  body: (
    <p>
      Growing the economy is accomplished by policies that encourage investment, innovation, and hiring. This will help businesses grow and create more job opportunities for all of us. A larger economy means more tax revenue and makes the debt more manageable. Here are some of the options to grow the economy, and their drawbacks.
    </p>
  ),

  scrollIndicatorLabel: "Productivity and Employment",
}

// Economy & Productivity Section

TourEconomyIntro.EconomyProductivitySection = {
  headline: (
    <h2>
      The size of the economy can be broken into two components:
    </h2>
  ),

  scrollIndicatorLabel: "Let's grow the economy",
}

TourEconomyIntro.EconomyProductivitySection.productivity = {
  headline: (
    <h2>
      Productivity
    </h2>
  ),

  body: (
    <p>
      Productivity tells us how much economic output an average worker can generate, which depends on factors such as education levels and the provided equipment. <a href="https://www.cbo.gov/publication/53919" target="_blank" rel="noopener noreferrer">CBO's projections</a> expect productivity rates to be close to their longtime average.
    </p>
  ),
}

TourEconomyIntro.EconomyProductivitySection.employment = {
  headline: (
    <h2>
      Employment
    </h2>
  ),

  body: (
    <p>
      Employment measures how much Americans are working. <a href="https://www.cbo.gov/publication/53919" target="_blank" rel="noopener noreferrer">CBO expects</a> the rate to be far lower than past generations. Long-term declines in birth rates and baby boomers retiring mean a smaller share of the population will be working.
    </p>
  ),
}

// Poll Section


const YearDebtExceedsGDP = [2035, 2039, 2050, 2050]
const Options = ["2.0%", "2.2%", "2.6%", "3.0%"]

TourEconomyIntro.Poll = {
  options: Options,

  answer: 2,

  GDPPercentForOptions: [136, 121, 99, 72],

  nominalDebtForOptions: [92.5, 88.1, 80.2, 67.3],

  // Prompt

  promptHeadline: (
    <h2>Growing the economy</h2>
  ),

  promptBody: (
    <p>
      The economy is expected to grow by an average of 1.9% over the next 30 years. How fast does the economy need grow to reach our goal?
    </p>
  ),

  // Wrong Answer

  // - too little
  wrongAnswerTooLittleHeadline: (answer) => (
    <h2>
      Nope. {answer} wouldn't be enough.
    </h2>
  ),

  wrongAnswerTooLittleBody: (answer) => (
    <p>
      This modest increase won't offset the debt. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // - too much
  wrongAnswerTooMuchHeadline: (answer) => (
    <h2>
      Nope. {answer} would be more than enough.
    </h2>
  ),

  wrongAnswerTooMuchBody: (answer) => (
    <p>
      A sustained economic growth rate of 3% would be great but is unlikely to happen. It would require productivity rates far above historic averages or a large increase in the share of the population in the workforce.
    </p>
  ),

  // Correct Answer

  correctAnswerPromptHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }The economy will have to grow by at least {answer}.
    </h2>
  ),

  correctAnswerPromptBody: (
    <p>
			Growth rates would need to be 35% higher than we currently predict. Let's explore some ways we could increase growth.
    </p>
  ),

  answerScrollIndicatorLabel: "How can we increase economic growth?",

}

export default TourEconomyIntro

import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let MandatorySpendingModel = {}

MandatorySpendingModel.LandingSection = {
  headline: (
    <h2>
      What are mandatory programs?
    </h2>
  ),

  body: (
    <React.Fragment>
      <p>
        As opposed to discretionary programs which are funded through annual appropriations bills, mandatory spending is authorized by Congress through separate laws that determine how much a  program spends and how it will operate. Mandatory spending includes Social Security, Medicare, Medicaid, and many other entitlement programs.
      </p>
      <p>
        Mandatory spending makes up nearly two-thirds of the total federal budget.
      </p>
    </React.Fragment>
  ),

  scrollIndicatorLabel: "Cut mandatory programs",
}

const YearDebtExceedsGDP = [2036, 2040, 2044, 2050]
const Options = ["11%", "26%", "41%", "56%"]

MandatorySpendingModel.CutMandatorySpendingPoll = {

  options: Options,

  answer: 3,

  GDPPercentForOptions: [134, 123, 111, 99],

  nominalDebtForOptions: [89.2, 81.8, 74.5, 67.1],

  // Prompt

  promptHeadline: (
    <h2>
		  Cut all mandatory programs except Social Security and Health Care programs.
    </h2>
  ),

  promptBody: (
    <p>
			This would include cuts to nutritional assistance programs like food stamps, and income support to poor seniors, the disabled, and veterans. How much would we have to cut to reach our goal?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Nope. A {answer} cut wouldn't be enough.
    </h2>
  ),

  wrongAnswerBody: (answer) => (
    <p>
      Cutting these programs may slow the growth in debt, but not enough to meet our goal. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }We would need to cut mandatory programs excluding Social Security and Health Care by {answer}.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
      These programs only account for a sixth of current federal spending and largely go to poor and middle-income Americans. Cash assistance for low-income seniors, the disabled, and veterans would need to be cut immediately. We would also need to cut billions of dollars from nutritional assistance programs.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default MandatorySpendingModel

import React  from "react"
import cx from "classnames"

import Poll from "../poll/poll"

const PollSection = ({ 
	isMobile, 
	isFirstPage, 
	options, 
	correctAnswer, 
	checkedOption, 
	debtGDPPercent, 
	debtGDPPercentForOptions, 
	nominalDebtForOptions,
	onChange, 
	className, 
	children,
	...rest }) => {

	const rowClasses = [cx({
		"row-content": true,
		"full-page-w-menu": true,
		"is-first-page": isFirstPage,
		"w-sidebar": true,
	}), className].join(" ")

	return (
		<section className={rowClasses} {...rest}>
			<div className="content">
				<div className="scroll-anchor scroll-anchor-impl" />
        {children}
				<Poll options={options} checkedOption={checkedOption} onChange={onChange} />
			</div>
		</section>
	)
}

export default PollSection
import React from "react"

import Tooltip from "../../../common/components/tooltip/tooltip"

let CloseLoopholesModel = {}

// Landing Section

CloseLoopholesModel.LandingSection = {
	headline: (
		<h2>
			What if we close tax loopholes?
		</h2>
	),

	body: (
		<p>
			Taxes exist to fund the government but over time Congress has used the tax code for many other social purposes. Taxpayers reduce what they owe with the help of tax expenditures - basically write-offs with a wide range of criteria, such as mortgage interest and investing in new machinery. Tax expenditures will lower federal revenue by as much as $1.35 trillion in 2019. Depending on who you ask, each expenditure is either a "loophole" or an essential part of the tax code that achieves an important social goal. Eliminating some could raise billions of dollars.
		</p>
	),

	illustrationAltDescription: "Bandaids covering a leaking upside down US Capitol building being filled from a faucet",

	scrollIndicatorLabel: "Let's consider a few big ones",
}

// Loopholes

CloseLoopholesModel.LoopholesAccordionTitle = "Let's consider a few big ones:"

CloseLoopholesModel.LoopholesAccordionRows = [
	{
		title: "Exclusion of employer-paid health premiums",
		body: (
			<React.Fragment>
				<p>
					Employer contribution exclusions for medical insurance is the largest tax expenditure, resulting in a $203 billion revenue loss in 2019 alone. Many healthcare economists from the right and the left argue this encourages generous insurance plans, which in turn drives up medical costs.
				</p>
				<p>
					Bipartisanship aside, eliminating it would impact middle class families the most.
				</p>
			</React.Fragment>
		)
	},
	{
		title: "Tax deferred retirement accounts",
		body: (
			<p>
				Preferential treatment on retirement savings will lower federal revenue by $191 billion in 2019. Taxing IRA or 401k contributions would raise billions of dollars but workers wouldn't save as much for retirement.
			</p>
		)
	},
	{
		title: "Mortgage interest deductions",
		body: (
			<p>
				Homeowners may deduct a portion of their mortgage interest payments. In 2019, taxpayers will reduce their federal tax liability by $26 billion. Eliminating the deduction would increase revenue, but it would also hurt homeowners by raising their taxes and lowering their property values. As a consequence, any discussion to limit the deduction yields considerable opposition from homeowners, the mortgage industry, and realtors.
			</p>
		)
	},
	{
		title: "Preferred tax rates on capital gains",
		body: (
			<p>
				<Tooltip value="A capital gain is the income made from selling an asset for more than its purchase price.">Capital gain</Tooltip> income receives preferential tax treatment relative to other income sources. In 2019, the preferred treatment will lower federal revenue by $104 billion. Some argue the preferential treatment merely benefits wealthy filers. The preferred rates, however, encourage greater investment, which increases economic growth. The preferred rates also offset the fact that capital gains are not indexed to inflation.
			</p>
		)
	},
]

// Effects Section

CloseLoopholesModel.EffectsSection = {
	headline: (
		<h2>
			The effects of closing tax loopholes
		</h2>
	),

	body: (
		<p>
			Eliminating tax loopholes would help solve the debt crisis, but most of the largest expenditures further important social aims or are loved by many taxpayers.
		</p>
	),

	scrollIndicatorLabel: "What else can we do?",
}

export default CloseLoopholesModel

import React from "react"

import { PollContainer, PollContainerType } from "../../common/components/poll-container/poll-container"
import PollSection from "../../common/components/poll-container/poll-section"
import WrongAnswerSection from "../../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../../common/components/poll-container/answer-section"
import WhatElse from "../taxes-what-else-menu"
import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

import Model from "../../../config/tour/taxes/tour-tax-top-1-model"

const Poll = (props) => (
	<PollSection isFirstPage {...props}>
		{Model.promptHeadline}
		{Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
				if (notEnough) {
					return (
						<div>
							{Model.wrongAnswerTooLittleHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooLittleBody(props.options[props.answer])}
						</div>
					)
				} else {
					return (
						<div>
							{Model.wrongAnswerTooMuchHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooMuchBody(props.options[props.answer])}
						</div>
					)
				}
			}
		}
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel={Model.answerScrollIndicatorLabel} {...props}>
		{ gotItRight => (
			<div>
				{Model.correctAnswerHeadline(gotItRight, props.options[props.correctAnswer])}
				{Model.correctAnswerBody(props.options[props.correctAnswer])}
				<ul className="is-inset">
					<li>{Model.correctAnswerBodyGoal1}</li>
					<li>{Model.correctAnswerBodyGoal2}</li>
				</ul>
			</div>
		)}
	</AnswerSection>
)

const TaxTop1Page = ({isMobile}) => (
	<SideMenuWrapper key="tax-top-1-page-wrapper">
		<div id="tour-taxes-container">
			<PollContainer 
				isMobile={isMobile}
				options={Model.options}
				nominalDebtForOptions={Model.nominalDebtForOptions}
				correctAnswer={Model.answer}
				debtGDPPercentForOptions={Model.GDPPercentForOptions}
				componentForType={ type => {
					switch(type) {
						case PollContainerType.POLL:
							return Poll
						case PollContainerType.WRONGANSWER:
							return WrongAnswer
						case PollContainerType.ANSWER:
							return Answer
						case PollContainerType.WHATELSE:
							return WhatElse
						default:
							throw new Error("Unknown poll container type: " + type);		
					}
				}}
			/>
		</div>
	</SideMenuWrapper>
)

export default TaxTop1Page

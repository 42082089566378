import React from "react"

import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

import IllustrationSidebarWrapper from "../illustration-sidebar-wrapper"
import WhatElseMenu from "../economy-what-else-menu"
import ImmigrationPNG from "./illustrations/13.2.png"

import TourEconomyModel from "../../../config/tour/economy/tour-economy"
const Model = TourEconomyModel.IncreaseImmigration

const IncreaseImmigrationPage = ({isMobile}) => {
	return (
    <SideMenuWrapper key="increase-immigration-page-wrapper">
      <IllustrationSidebarWrapper key="increase-immigration-wrapper" src={ImmigrationPNG} alt={Model.illustrationAltDescription} isMobile={isMobile}>
        <div id="tour-economy-container">
          <div className="full-page-w-menu is-first-page row-content v-center">
            <div className="content">
              {Model.headline}
              {Model.body}
              <WhatElseMenu isMobile={isMobile}/>
            </div>
          </div>
        </div>
      </IllustrationSidebarWrapper>
    </SideMenuWrapper>
	)
}

export default IncreaseImmigrationPage

import Point from "../models/point"

const SVGUtil = {
	toString(commandsAr) {
		// Sample input: [['M', fromX, fromY], ['A', r, r, 0, large, sweep, toX, toY]]
		return commandsAr.map((command) => command.join(' '))
				.join(' ')
	},

	getPointAtPercent(el, percent) {
		if (el) {
			const len = el.getTotalLength()
			const point = el.getPointAtLength(percent * len)
			return new Point(point.x, point.y)
		}
		return new Point(0, 0)
	}
}

export default SVGUtil

import React from "react"
import ScrollPercentage from "react-scroll-percentage"
import WhatElseMenu from "./spending-what-else-menu"

const SendingWhatElse = ({ onViewPercentageChange, ...rest }) => (
  <ScrollPercentage onChange={onViewPercentageChange || null}>
    <section className="row-content full-page-w-menu v-center w-sidebar">
      <div className="content">
        <div>
          <div className="scroll-anchor scroll-anchor-impl"/>
          <h2>What else can we try cutting?</h2>
        </div>
        <WhatElseMenu {...rest} />
      </div>
    </section>
  </ScrollPercentage>
)

export default SendingWhatElse

import React from "react"
import { Link } from "react-router-dom"

import "./options-menu.css"

const TaxesIconSVG = () => (
	<svg height="18" viewBox="0 0 14 18" width="14">
		<path d="m7 4.75735931-4.65685425 4.65685425-1.41421356-1.41421356 7.07106781-7.07106781 7.0710678 7.07106781-1.4142136 1.41421356-4.6568542-4.65685425v13.45880499h-2z" transform="translate(-1 -1)"/>
	</svg>
)

const SpendingIconSVG = () => (
	<svg height="20" viewBox="0 0 20 20" width="20">
		<path d="m42 22.5857864 1.4142136 1.4142136-17.9023055 17.9023054-1.4142135-1.4142135z" transform="translate(-24 -22)"/>
	</svg>
)

const EconomyIconSVG = () => (
	<svg height="14" viewBox="0 0 24 14" width="24">
		<path d="m22 7.58578644 1.4142136 1.41421356-4.9023055 4.9023054-1.4142135-1.4142135zm-20.48809186 0 4.90230542 4.90230546-1.41421356 1.4142135-4.90230542-4.9023054zm10.99999996-6.61183544v7.04296669h-2v-7.04296669z" />
	</svg>
)

// const ConclusionIconSVG = () => (
// 	<svg height="15" viewBox="0 0 18 15" width="18">
// 		<path d="m7 4.75735931-4.65685425 4.65685425-1.41421356-1.41421356 7.07106781-7.07106781 7.0710678 7.07106781-1.4142136 1.41421356-4.6568542-4.65685425v13.45880499h-2z" transform="matrix(0 1 -1 0 18.572548 -.427452)"/>
// 	</svg>
// )

const OptionsMenu = () => (
	<div className="options-menu">
		<div className="options-menu-row">
			<Link to="/tour/taxes" className="taxes btn">
				<div className="icon"><TaxesIconSVG /></div>
				<div className="label">Raise Taxes</div>
			</Link>
			<Link to="/tour/spending" className="spending btn">
				<div className="icon"><SpendingIconSVG /></div>
				<div className="label">Cut Spending</div>
			</Link>
			<Link to="/tour/economy" className="economy btn">
				<div className="icon"><EconomyIconSVG /></div>
				<div className="label">Grow the Economy</div>
			</Link>
			{/* <Link to="/tour/a-way-forward" className="conclusion btn">
			<div className="icon"><ConclusionIconSVG /></div>
			<div className="label">A Way Forward</div>
			</Link> */}
		</div>
	</div>
)


export default OptionsMenu

import React, { Component } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import IntroPage from "./intro/intro-page"
import TourPage from "./tour/tour-page"
import AboutPage from "./about/about-page"
import ResourcesPage from "./about/resources-page"
import VideoModal from "./common/components/video-modal";
import "./common/css/reset.css"
import "./common/css/base.css"

class App extends Component {
  constructor() {
    super();
    this.state = {
      showVideo: false
    }
    this.toggleVideo = this.toggleVideo.bind(this)
  }

  toggleVideo() {
    this.setState({
      showVideo: !this.state.showVideo
    })
  }

	render() {
		return (
			<Router>
        <>
  				<Switch>
  					<Route exact path="/" render={(props) =>
              <IntroPage toggleVideo={this.toggleVideo} showVideo={this.state.showVideo} {...props} />}
               />
  					<Route path="/tour" render={(props) =>
              <TourPage toggleVideo={this.toggleVideo} showVideo={this.state.showVideo} {...props} />}
               />
  					<Route path="/about" component={AboutPage} />
  					<Route path="/resources" component={ResourcesPage} />
  				</Switch>
          <VideoModal toggleVideo={this.toggleVideo} showVideo={this.state.showVideo} />
        </>
			</Router>
		)
	}
}
export default App

import React from "react"
import MediaQuery from "react-responsive"
import PropTypes from "prop-types"

const MOBILE_BREAKPOINT = 813

class ResponsiveWrapper extends React.Component {
  
  static propTypes = {
		children: PropTypes.any.isRequired,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <MediaQuery minWidth={MOBILE_BREAKPOINT}>
      {(matches) => {
        const isMobile = !!!matches
        const respChildren = React.cloneElement(this.props.children, {
          isMobile: isMobile,
        })
        return respChildren
      }}
      </MediaQuery> 
    )
  }
}

export default ResponsiveWrapper
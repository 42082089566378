import React from "react"
import ScrollPercentage from "react-scroll-percentage"
import cx from "classnames"

import "./animated-illustration.css"

const AnimatedIllustration = ({src, alt, flipped}) => (
	<ScrollPercentage className="animated-illustration-wrapper">
		{({percentage, inView}) => {
			const imgClasses = cx({
        "animated-illustration": true,
        "is-flipped": flipped? true : false,
				"is-in-view": inView && percentage > 0.2,
			})
			return (
				<img src={src} alt={alt} className={imgClasses}/>
			)
		}}
	</ScrollPercentage>
)

export default AnimatedIllustration
import React, { Component } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import OptionsMenu from "../common/components/options-menu/options-menu"

import "./intro-start.css"

import TourIntroModel from "../../config/tour/intro/tour-intro-model"
const Copy = TourIntroModel.StartSection

class IntroStart extends Component {
	static propTypes = {
		isMobile: PropTypes.bool.isRequired,
	}

	render() {
		const startClasses = cx({
			"full-page-w-menu": true,
			"w-sidebar": true,
			"is-mobile": this.props.isMobile,
			"is-desktop": !this.props.isMobile,
		})

		return (
			<section id="intro-start" className={startClasses}>
				<div>
					<div className="content">
						<div className="scroll-anchor scroll-anchor-impl"/>
						{Copy.promptHeadline}
						<OptionsMenu />
					</div>
				</div>
			</section>
		)
	}
}

export default IntroStart


import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let TourEconomy = {}

// Tax Reform

TourEconomy.TaxReform = {
  headline: (
    <h2>
      Tax Reform
    </h2>
  ),

  body: (
    <React.Fragment>
      <p>
        Lower tax rates will give businesses incentive to invest in new machines, factories, and buildings, which will make workers more productive. Cutting taxes, however, could also increase deficits.
      </p>
      <p>
        Eliminating tax expenditures could offset the revenue loss from rate reductions, but that would likely raise taxes on middle class families. We could replace income taxes with consumption taxes (e.g., sales and <Tooltip value="VATs are consumption taxes that are levied on businesses. Business pay a VAT on the difference between the sale price and the cost of materials for a product. While businesses are responsible for collecting the taxes, most economists believe the tax is ultimately paid by consumers in the form of higher prices.">value-added taxes</Tooltip>). Many economists on the left and the right endorse consumption-based taxes as a more efficient way to raise revenue, but these could burden low-income families buying food and gas.
      </p>
      <p className="what-else-prompt">
        Aside from tax reform, what policies might increase economic growth?
      </p>
    </React.Fragment>
  ),

  illustrationAltDescription: "A line graph creates the shape of a factory",
}

// Regulatory Reform 

TourEconomy.RegulatoryReform = {
  headline: (
    <h2>
      Regulatory Reform
    </h2>
  ),

  body: (
    <React.Fragment>
      <p>
        Regulations are intended to protect consumers and workers from harm, physically and financially, but they often lower productivity and reduce employment. Financial laws often increase businesses' borrowing and labor regulations can raise hiring costs.
      </p>
      <p>
        Determining which rules do more harm than good isn't easy. There are proposals to limit the economic costs of regulations, but often these recommendations only deal with new rules. 
      </p>
      <p className="what-else-prompt">
        What other policies might increase economic growth?
      </p>
    </React.Fragment>
  ),

  illustrationAltDescription: "A loupe with the white house as a handle",
}

// Entitlement Reform

TourEconomy.EntitlementReform = {
  headline: (
    <h2>
      Entitlement Reform
    </h2>
  ),

  body: (
    <React.Fragment>
      <p>
        Low-income assistance programs (e.g., food stamps, cash welfare programs, disability) can discourage work. For those receiving assistance, starting a job or increasing hours worked may mean losing access to assistance. Nevertheless, reducing these disincentives would mean cutting benefit levels, ending assistance for some deserving recipients, or increasing public-funded outlays for these programs to reduce the penalties faced by recipients who work.
      </p>
      <p className="what-else-prompt">
        What other policies might increase economic growth?
      </p>
    </React.Fragment>
  ),

  illustrationAltDescription: "Two scissors form the outline of a paper bag full of groceries",
}

// Increase Immigration

TourEconomy.IncreaseImmigration = {
  headline: (
    <h2>
      Increase Immigration
    </h2>
  ),

  body: (
    <React.Fragment>
      <p>
        Immigration reforms aimed at expanding the labor force can grow the economy. A 2013 proposal would have added 6 million workers by 2023 and 9 million by 2033, increasing revenue by $2 trillion. Critics argued it would increase spending on low-income programs and lower wages of citizens, and the bill died in Congress.
      </p>
      <p>
        The CBO estimated spending would increase by $1 trillion over the first 20 years, but revenue growth would double that amount. Other critics noted that while average wages would be slightly higher in the long run, some domestic workers would see their wages fall. The bill died in Congress.
      </p>
      <p className="what-else-prompt">
        What other policies might increase economic growth?
      </p>
    </React.Fragment>
  ),

  illustrationAltDescription: "People walking into a doorway in the shape of the US map",
}

// Repair Infrastructure

TourEconomy.RepairInfrastructure = {
  headline: (
    <h2>
      Repair Infrastructure
    </h2>
  ),

  body: (
    <React.Fragment>
      <p>
        Improvements to the nation's infrastructure (e.g., energy production, transportation, and communication systems) could lower businesses' costs. However, these would be costly, and it's hard to determine which projects offer the best economic returns. 
      </p>
      <p>
        Attention-grabbing projects that offer little benefit may be chosen over less glamorous ones like routine highway maintenance and safety improvements.
        Adding to the issue, states can also use federal money to pay for projects that they could have funded themselves.
      </p>
      <p className="what-else-prompt">
        What other policies might increase economic growth?
      </p>
    </React.Fragment>
  ),

  illustrationAltDescription: "Excavator with bent arm digging a hole in the ground",
}

export default TourEconomy
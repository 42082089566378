import React from "react"
import { max, last } from "lodash"

import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"
import BackgroundGraph from "../common/components/background-graph/background-graph"

import Facts from "../../config/facts"
import Format from "../../common/utils/format"

import "./spending-landing.css"

import IntroModel from "../../config/tour/spending/tour-spending-intro-model"
const Model = IntroModel.LandingSection

const spendingTrillions = Facts.econ.projectedSpendingTrillions

const m = max(spendingTrillions)
const points = spendingTrillions.map(x => x / m)

const projectedSpending = last(Facts.econ.projectedSpendingTrillions) * Facts.const.trillion
const projectedSpendingLabel = Format(projectedSpending, true, 0)

const SpendingLanding = ({isMobile}) => {
  return (
		<ScrollIndicatorWrapper id="spending-landing-wrapper" label={Model.scrollIndicatorLabel}>
			<section id="spending-landing" className="full-page-w-menu is-first-page has-scroll-indicator v-center">
				<div className="content">
					{Model.headline}
					{Model.body}
				</div>
				<BackgroundGraph 
					points={points}
					fromYear={Facts.econ.year}
					toYear={Facts.econ.projectedYear}
					graphLabel="Total Spending"
					isMobile={isMobile}
					toValueLabel={projectedSpendingLabel}
				/>
			</section>
		</ScrollIndicatorWrapper>
  )
}
export default SpendingLanding

import React from "react"

import Facts from "../../facts"
import Tooltip from "../../../common/components/tooltip/tooltip"

let SocialSecurityReformModel = {}

// Landing Section

SocialSecurityReformModel.LandingSection = {
	headline: (
    <h2>
      How does Social Security work?
    </h2>
  ),

  body: (
    <p>
			This program provides money to retirees, spouses, dependents, and disabled workers who have paid into the Social Security system during their careers. Retirees are eligible at age 62 but benefits are larger if a retiree waits to collect. Monthly benefits are based on a retiree's earnings history. Monthly benefits tend to be larger for retirees with higher lifetime earnings.
    </p>
  ),

  scrollIndicatorLabel: "Why is Social Security spending rising?",
}

// Social Security Reforms

SocialSecurityReformModel.ReformsAccordionTitle = "Proposed Social Security reforms:"

SocialSecurityReformModel.ReformsAccordionRows = [
	{
		title: "Increase the full retirement age to 70",
		body: (
			<p>
        The "Full Retirement Age" (FRA) is the age that people are eligible to receive their full Social Security benefits. The FRA is currently set at 66 but it is scheduled to grow to 67. Benefits are reduced for retirees who choose to collect benefits before this age. One proposal would increase the FRA by two months every year until it reaches 70 in 2046, but the savings are minor, especially in the immediate future. Debt would still exceed 100% of GDP in 2034 and would fall by only 7% of GDP by {Facts.econ.projectedYear}. The change would disrupt future retirees' plans.
			</p>
		)
	},
	{
		title: "Reduce COLAs for all recipients",
		body: (
			<p>
        <Tooltip value="Specifically, COLAs would be based on the chained-CPI, which is lower than the current metric used.">This reduces annual cost of living adjustments (COLAs) for all Social Security recipients</Tooltip>. COLAs would be reduced by about 0.25 percentage points. No benefits would be cut, but benefits wouldn't grow as fast as projected. The effects are fairly small - debt falls by just 5% of GDP in {Facts.econ.projectedYear}. Debt would exceed 100% of GDP in 2035.
			</p>
		)
	},
	{
		title: "Reduce initial benefits for all new recipients",
		body: (
			<p>
        This reform calculates new retirees' benefits to inflation rather than wage growth. No one would receive smaller benefits, but they would grow more slowly. The effect is larger than the other reforms, but still only reduces {Facts.econ.projectedYear} debt by 9% of GDP. Debt would surpass 100% of GDP in 2034.
			</p>
		)
	},
]

// Poll

const cp = Facts.econ.projectedNetDebtGDP * 100
const cpNominal = Facts.econ.projectedNetDebt / Facts.const.trillion

SocialSecurityReformModel.SocialSecurityReformPoll = {

	options: ["10%", "15%", "20%", "25%"],

  answer: 2,

  GDPPercentForOptions: [cp, cp, 123.0, cp],

  nominalDebtForOptions: [cpNominal, cpNominal, 82.4, cpNominal],

  // Prompt

  promptHeadline: (
    <h2>
      What if we enacted all of these proposed Social Security reforms?
    </h2>
  ),

  promptBody: (
    <p>
      How much would the U.S. debt be reduced by in {Facts.econ.projectedYear}?
    </p>
  ),

  // Wrong Answer

	// - too little
  wrongAnswerTooLittleHeadline: (answer) => (
    <h2>
      Good guess. However, {answer} is too low.
    </h2>
  ),

  wrongAnswerTooLittleBody: (answer) => (
    <p>
      Social Security consumes a sizeable portion of the budget.
    </p>
  ),

	// - too much
  wrongAnswerTooMuchHeadline: (answer) => (
    <h2>
      Good guess. However, {answer} is too high.
    </h2>
  ),

  wrongAnswerTooMuchBody: (answer) => (
    <p>
      Social Security consumes more of the budget than any other program. However, even in the long-term, the savings are less than {answer}.
    </p>
	),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }Enacting these proposed Social Security reforms would reduce debt by {answer} of GDP.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
      The large savings wouldn't happen until much later in the 30-year budget window. In fact, Social Security spending would fall by only 2% during the 2020s. Social Security consumes more of the budget than any other program - $982 billion in {Facts.econ.year} - but cuts here won't be sufficient to reach our goal. Debt as a share of GDP would exceed 100% in 2036.
		</p>
  ),

  answerScrollIndicatorLabel: "What else can we try cutting?",
}

export default SocialSecurityReformModel

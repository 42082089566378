import React, { Component } from "react"
import ScrollPercentage from "react-scroll-percentage"
import Splash from "./intro-splash"
import Landing from "./intro-landing"
import Start from "./intro-start"
import StickySidebarWrapper from "../common/components/sticky-sidebar-wrapper/sticky-sidebar-wrapper"
import GDPGraph from "../common/components/gdp-graph/gdp-graph"
import "./intro-page.css"
import Facts from "../../config/facts"

class TourIntroPage extends Component {
  render() {
    const { isMobile, style } = this.props;
  	const sidebar = (
  		<ScrollPercentage threshold={0}>
  		{({percentage}) => {
  			return(
  				<GDPGraph
  				isMobile={isMobile}
  				gdp={Facts.econ.projectedNetDebtGDP * 100}
  				debt={Facts.econ.projectedNetDebt}
  				adjustedDebt={Facts.econ.projectedNetDebt}
  				adjustedGdp={Facts.econ.projectedNetDebtGDP * 100}
  				isHidden={!isMobile &&  (percentage < 0.8 || percentage === 1.0)}
  				/>
  			)
  		}}
  	</ScrollPercentage>
  	)
  	return (
  		<div id="tour-intro-page" className="tour-section intro" style={style}>
  			<Splash toggleVideo={this.props.toggleVideo} />
  			<Landing isMobile={isMobile}/>
  			<StickySidebarWrapper sidebar={sidebar} isMobile={isMobile}>
  				<Start isMobile={isMobile}/>
  			</StickySidebarWrapper>
  		</div>
  	)
  }
}

export default TourIntroPage

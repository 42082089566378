import React, { Component } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import ScrollPercentage from "react-scroll-percentage"
import ReactTooltip from "react-tooltip"

import PollContainerType from "./poll-container-type"
import StickySidebarWrapper from "../sticky-sidebar-wrapper/sticky-sidebar-wrapper"
import GDPGraph from "../gdp-graph/gdp-graph"
import Facts from "../../../../config/facts"

import "./poll-container.css"

class PollContainer extends Component {
	static propTypes = {
    isMobile: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    correctAnswer: PropTypes.number.isRequired,
		debtGDPPercentForOptions: PropTypes.array.isRequired,
		nominalDebtForOptions: PropTypes.array.isRequired,
		componentForType: PropTypes.func.isRequired,
		nextSection: PropTypes.arrayOf(PropTypes.element),
	}

	constructor(props) {
		super(props)
		this.debtGDPPercent = Facts.econ.projectedNetDebtGDP * 100
		this.debt = Facts.econ.projectedNetDebt
		this.state = {
			stack: [PollContainerType.POLL], 
			answer: null,
			correctAnswer: this.props.correctAnswer,
			isWrong: false,
			isDone: false,
			adjustedDebtGDPPercent: this.debtGDPPercent,
			adjustedDebt: this.debt
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.stack !== this.state.stack) {
			this.scrollToActive()
			ReactTooltip.rebuild() 
		}
	}

	recordAnswer = (e) => {
		const answer = parseInt(e.target.value, 10)
		const isCorrect = (answer === this.state.correctAnswer)
		const newStack = [...this.state.stack]
		if (isCorrect) {
			newStack.push(PollContainerType.ANSWER)
		} else {
			newStack.push(PollContainerType.WRONGANSWER)
		}

		this.setState({
			answer: answer,
			stack: newStack,
			isWrong: !isCorrect,
			isDone: isCorrect,
			adjustedDebtGDPPercent: this.props.debtGDPPercentForOptions[answer],
			adjustedDebt: this.props.nominalDebtForOptions[answer] * Facts.const.trillion,
		})
	}

	showCorrectAnswer = (e) => {
		const newStack = [...this.state.stack, PollContainerType.ANSWER]
		this.setState({
			stack: newStack,
			isWrong: false,
			isDone: true,
			adjustedDebtGDPPercent: this.props.debtGDPPercentForOptions[this.state.correctAnswer],
			adjustedDebt: this.props.nominalDebtForOptions[this.state.correctAnswer] * Facts.const.trillion,
		})
	}

	getNextAnchor = () => {
		const anchors = Array.from(document.querySelectorAll(".row-content.active .scroll-anchor"))
		for (let i = anchors.length - 1; i >= 0; i--) {
			const a = anchors[i]
			if (a.closest(".tour-poll-container").classList.contains("is-dirty")) {
				return a
			}
		}
		return anchors[anchors.length - 1]
	}

	scrollToActive = () => {
		const el = this.getNextAnchor()
		if (el) {
			// Slow it down, there is a race condition where scrollIntoView doesn't
			// do anything:
			setTimeout(() => {
				// const t = el.getBoundingClientRect().top + window.scrollY
				// window.scrollTo({top: t, behavior: "smooth"});
				el.scrollIntoView({block: "start", behavior: "smooth", inline: "start" })
			}, 50)
		} else {
			console.error("Couldn't find scroll anchor", el);
		}
	}

	handleWhatElseOnScreenChange = (p) => {
		if (this.state.adjustedDebtGDPPercent === this.debtGDPPercent) {
			return
		}
		if (p > 0.3) {
			this.setState({
				adjustedDebtGDPPercent: this.debtGDPPercent,
				adjustedDebt: this.debt,
			})
		}
	}

	render() {
		const sidebar = (
			<ScrollPercentage threshold={0}>
			{({percentage, inView}) => {
				return(
					<GDPGraph 
					isMobile={this.props.isMobile} 
					gdp={this.debtGDPPercent}
					adjustedGdp={this.state.adjustedDebtGDPPercent}
					debt={this.debt}
					adjustedDebt={this.state.adjustedDebt}
					isHidden={!this.props.isMobile &&  (percentage < 0.8 || percentage === 1.0)}
					/>
				)
			}}
		</ScrollPercentage>
		)

		const activeIndex = this.state.stack.length - 1

		const containerClasses = cx({
			"tour-poll-container": true,
			"is-wrong": this.state.isWrong,
			"is-dirty": activeIndex === 0 ? false : true,
		})

		return (
			<article className={containerClasses}>
				<StickySidebarWrapper sidebar={sidebar} isMobile={this.props.isMobile}>
					{
						this.state.stack.map((row, i) => {
							const props = {
								key: `row-${i}`,
								isMobile: this.props.isMobile,
								options: this.props.options,
								debtGDPPercent: this.debtGDPPercent,
								debtGDPPercentForOptions: this.props.debtGDPPercentForOptions,
								nominalDebtForOptions: this.props.nominalDebtForOptions,
								answer: this.state.answer,
								correctAnswer: this.state.correctAnswer,
								className: activeIndex === i ? "active" : "disabled",
							}
							
							switch(row) {
								case PollContainerType.POLL:
									const poll = this.props.componentForType(PollContainerType.POLL)
									const pollProps = {
										onChange: this.recordAnswer,
									}
									return React.createElement(poll, {...props, ...pollProps})
								case PollContainerType.WRONGANSWER:
									const wrongAnswer = this.props.componentForType(PollContainerType.WRONGANSWER)
									const wrongAnswerProps = {
										isMobile: this.props.isMobile,
										onClick: this.showCorrectAnswer,
									}
									return React.createElement(wrongAnswer, {...wrongAnswerProps, ...props})
								case PollContainerType.ANSWER:
									const answer = this.props.componentForType(PollContainerType.ANSWER)
									const answerElement = React.createElement(answer, props)
									const whatElse = this.props.componentForType(PollContainerType.WHATELSE)
									// What else section is optional:
									if (!whatElse) {
										return answerElement
									}
									const whatElseProps = {
										key: "what-else",
										isMobile: this.props.isMobile,
										onViewPercentageChange: this.handleWhatElseOnScreenChange,
									}
									return [
										answerElement,
										React.createElement(whatElse, {...props, ...whatElseProps}),
									]
								default:
									throw new Error("Unknown row component!");
							}
						})
					}
				</StickySidebarWrapper>
				{ this.state.isDone && this.props.nextSection &&
					<section>
						{this.props.nextSection}
					</section>
				}
			</article>
		)
	}
}

export { PollContainer, PollContainerType }
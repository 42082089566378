import React from "react"
import cx from "classnames"

import "./main-menu-right.css"

const MainMenuRight = ({isMobile, isInverted, children}) => {
	const leftClasses = cx({
		"right": true,
		"is-mobile": isMobile,
		"is-desktop": !isMobile,
		"is-inverted": isInverted,
  })

  return (
		<div className={leftClasses}>
      {children}
		</div>
	)
}

export default MainMenuRight
import React from "react"

import { PollContainer, PollContainerType } from "../../common/components/poll-container/poll-container"
import PollSection from "../../common/components/poll-container/poll-section"
import WrongAnswerSection from "../../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../../common/components/poll-container/answer-section"
import WhatElse from "../taxes-what-else-menu"
import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

import Model from "../../../config/tour/taxes/tour-tax-corporations-model"

const Poll = (props) => (
	<PollSection isFirstPage {...props}>
		{Model.promptHeadline}
		{Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
				if (notEnough) {
					return (
						<div>
							{Model.wrongAnswerHeadline(props.options[props.answer])}
							{Model.wrongAnswerBody(props.options[props.answer])}
					</div>
					)
				}
			}
		}
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel={Model.answerScrollIndicatorLabel} {...props}>
		{ gotItRight => (
			<div>
				{Model.correctAnswerHeadline(gotItRight, props.options[props.correctAnswer])}
				{Model.correctAnswerBody(props.options[props.correctAnswer])}
			</div>
		)}
	</AnswerSection>
)

const Page = ({isMobile}) => (
	<SideMenuWrapper key="tax-corporations-page-wrapper">
		<div id="tour-taxes-container">
			<PollContainer 
				isMobile={isMobile}
				options={Model.options}
				nominalDebtForOptions={Model.nominalDebtForOptions}
				correctAnswer={Model.answer}
				debtGDPPercentForOptions={Model.GDPPercentForOptions}
				componentForType={ type => {
					switch(type) {
						case PollContainerType.POLL:
							return Poll
						case PollContainerType.WRONGANSWER:
							return WrongAnswer
						case PollContainerType.ANSWER:
							return Answer
						case PollContainerType.WHATELSE:
							return WhatElse
						default:
							throw new Error("Unknown poll container type: " + type);		
					}
				}}
			/>
		</div>
	</SideMenuWrapper>
)

export default Page

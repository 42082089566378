import React from "react"
import { PickerMenu, PickerMenuButton, SkipToSpendingMenuButton } from "../common/components/picker-menu/picker-menu"
import { Link } from "react-router-dom"

import TourTaxesIntroModel from "../../config/tour/taxes/tour-taxes-intro-model"
const Model = TourTaxesIntroModel.WhatNextSection

const TaxesWhatNext = ({ isMobile }) => {
	return (
		<section 
			id="taxes-start" 
			className="row-content full-page-w-menu v-center w-sidebar">
			<div>
				<div className="scroll-anchor scroll-anchor-impl" />
				<div className="content">
					{Model.headline}
				</div>
				<PickerMenu isMobile={isMobile}>
					<Link to="/tour/taxes/tax-top-25">
						<PickerMenuButton label="Only raise taxes on the top 25%" />
					</Link>
					<Link to="/tour/taxes/tax-top-1">
						<PickerMenuButton label="Only raise taxes on the top 1%" />
					</Link>
					<Link to="/tour/taxes/tax-corporations">
						<PickerMenuButton label="Only raise taxes on corporations" />
					</Link>
					<Link to="/tour/taxes/close-loopholes">
						<PickerMenuButton label="Close tax loopholes" />
					</Link>
					<SkipToSpendingMenuButton />
				</PickerMenu>
			</div>
		</section>
	)
}

export default TaxesWhatNext

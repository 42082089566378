
import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars
import Format from "../../../common/utils/format"

let TourTaxTop1Model = {}

const YearDebtExceedsGDP = [2039, 2044, 2050, 2050]
const Options = ["17%", "27%", "37%", "47%"]

TourTaxTop1Model = {
  options: Options,

  answer: 2,

  GDPPercentForOptions: [123, 111, 99, 87],

  nominalDebtForOptions: [81.8, 74.3, 66.6, 59.0],

  // Prompt

  promptHeadline: (
    <h2>
      Raising taxes on the top 1%
    </h2>
  ),

  promptBody: (
		<p>
      Estimated federal tax revenue for {Facts.econ.projectedYear} is {Format(Facts.econ.projectedTaxRevenue)}. <Tooltip value="The top 1% receive 19% of pretax income.">The top 1% of taxpayers pay 36% of all federal personal income taxes</Tooltip>. How much would we need to raise federal taxes on the 1% only to keep the debt from passing 100% of GDP? The 1% currently face a top federal marginal tax rate of 37%.
    </p>
  ),

  // Wrong Answer

  // - too little
  wrongAnswerTooLittleHeadline: (answer) => (
    <h2>
      Nope. {answer} wouldn't be enough.
    </h2>
  ),

  wrongAnswerTooLittleBody: (answer) => (
    <p>
      We would need even higher federal taxes on the top 1% to reach our goal. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // - too much

  wrongAnswerTooMuchHeadline: (answer) => (
    <h2>
      Nope. {answer} would be more than enough.
    </h2>
  ),

  wrongAnswerTooMuchBody: (answer) => (
		<p>
      This tax increase would be more than enough to keep debt below 100% of GDP but debt as a percent of GDP would still rise from today's levels.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }We would need to raise federal taxes by {answer}.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
      If limited to the top 1%, we would need a {answer} increase in federal income taxes to raise enough revenue to meet this goal:
    </p>
  ),

  correctAnswerBodyGoal1: (
    <p>
      66 cents of every dollar earned above $500,000 would go to the federal government.
    </p>
  ),

  correctAnswerBodyGoal2: (
    <p>
      With Medicare and state taxes, some taxpayers would face <Tooltip value="The tax rate you face on the next dollar you earn.">marginal tax rates</Tooltip> over 80%.
    </p>

  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default TourTaxTop1Model

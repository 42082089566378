import React from "react"
import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"

import "./keep-in-mind.css"

import TourTaxesIntroModel from "../../config/tour/taxes/tour-taxes-intro-model"
const Model = TourTaxesIntroModel.KeepInMindSection

const KeepInMind = () => (
  <ScrollIndicatorWrapper 
    id="keep-in-mind-wrapper" 
    label={Model.scrollIndicatorLabel}>
    <section 
      id="keep-in-mind" 
      className="full-page has-scroll-indicator v-center">
			<div className="content">
        {Model.headline}
        {Model.body}
			</div>
		</section>
	</ScrollIndicatorWrapper>
)

export default KeepInMind

import React from "react"
import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"

import "./why-is-spending-rising.css"

import MedicareReformModel from "../../../config/tour/spending/tour-medicare-reform-model"
const Model = MedicareReformModel.WhyIsSpendingRisingSection

const WhyIsSpendingRising = () => (
  <ScrollIndicatorWrapper 
    id="why-is-medicare-spending-rising-wrapper" 
    label={Model.scrollIndicatorLabel}>
    <section 
      id="why-is-medicare-spending-rising" 
      className="full-page has-scroll-indicator v-center">
			<div>
        {Model.headline}
        {Model.body}
			</div>
		</section>
	</ScrollIndicatorWrapper>
)
export default WhyIsSpendingRising

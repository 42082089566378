import React from "react"
import SideMenu from "./main-side-menu"

const MainSideMenuWrapper = ({isMobile, children}) => {
  return (
    [
      <SideMenu key="main-side-menu" isMobile={isMobile} />,
      React.cloneElement(children, {key: "main-side-menu-wrapper-content"})
    ]
  )
}

export default MainSideMenuWrapper
function SVGLine3Landscape([p1, p2]) {

		const cx1 = p1.x
		const cy1 = p1.y

		const cx2 = p2.x * 1.5
		const cy2 = p2.y * 0.1

		return {
			x1: p1.x,
			y1: p1.y,
			cx1: cx1,
			cy1: cy1,
			x2: p2.x,
			y2: p2.y,
			cx2: cx2,
			cy2: cy2,
			commands: [
				['M', p1.x, p1.y],
				['C', cx1, cy1, cx2, cy2, p2.x, p2.y]
			]
		}
}

function SVGLine3Portrait([p1, p2]) {

		const cx1 = p1.x
		const cy1 = p1.y

		const cx2 = p2.x * 1.6
		const cy2 = p2.y * 0.8

		return {
			x1: p1.x,
			y1: p1.y,
			cx1: cx1,
			cy1: cy1,
			x2: p2.x,
			y2: p2.y,
			cx2: cx2,
			cy2: cy2,
			commands: [
				['M', p1.x, p1.y],
				['C', cx1, cy1, cx2, cy2, p2.x, p2.y]
			]
		}
}

export { SVGLine3Landscape, SVGLine3Portrait }

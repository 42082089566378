import React from "react"
import AnimatedIllustration from "../common/components/animated-illustration/animated-illustration"

import "./calculator-landing.css"

import TheWayForwardPNG from "./illustrations/16.2.png"

import TourConclusionModel from "../../config/tour/conclusion/tour-conclusion-model"
const Model = TourConclusionModel.CalculatorLandingSection


const CalculatorLanding = () => (
  <div id="calculator-landing-wrapper">
    <section id="calculator-landing" className="full-page-w-menu v-center">
      <div className="split-layout">
        <div className="split-layout-column">
          <div className="scroll-anchor scroll-anchor-impl" />
          {Model.headline}
          {Model.body}
          <div className="picker-menu">
            <a href="https://calculator.americaoffbalance.org/new">
              <button className="picker-menu-btn">
                {Model.buttonLabel}
              </button>
            </a>
          </div>
        </div>
        <div className="split-layout-column">
          <AnimatedIllustration src={TheWayForwardPNG} alt={Model.illustrationAltDescription}/>
        </div>
      </div>
		</section>
	</div>
)
export default CalculatorLanding

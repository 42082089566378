import React from "react"

import { PollContainer, PollContainerType } from "../../common/components/poll-container/poll-container"
import PollSection from "../../common/components/poll-container/poll-section"
import WrongAnswerSection from "../../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../../common/components/poll-container/answer-section"

import FamilyIconPNG from "./family-icon.png"
import FamilyIcon2xPNG from "./family-icon@2x.png"
import PayrollIconPNG from "./payroll-icon.png"
import PayrollIcon2xPNG from "./payroll-icon@2x.png"
import BusinessIconPNG from "./business-icon.png"
import BusinessIcon2xPNG from "./business-icon@2x.png"

import "./tax-everyone-answer.css"
import Facts from "../../../config/facts";

import TourTaxesIntroModel from "../../../config/tour/taxes/tour-taxes-intro-model"
const Model = TourTaxesIntroModel.TaxEveryonePoll

const Poll = (props) => (
	<PollSection isFirstPage {...props}>
		{Model.promptHeadline}
		{Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
				if (notEnough) {
					return (
						<div>
							{Model.wrongAnswerTooLittleHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooLittleBody(props.options[props.answer])}
						</div>
					)
				} else {
					return (
						<div>
							{Model.wrongAnswerTooMuchHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooMuchBody(props.options[props.answer])}
						</div>
					)
				}
			}
		}
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel={Model.answerScrollIndicatorLabel} {...props}>
		{ gotItRight => (
			<div id="tax-everyone-answer">
				{Model.correctAnswerPromptHeadline(gotItRight, props.options[props.correctAnswer])}
				{Model.correctAnswerPromptBody}
				<ul>
					<li>
						<img src={FamilyIconPNG} className="icon" srcSet={`${FamilyIconPNG}, ${FamilyIcon2xPNG} 2x`} alt=""/>
						{Model.correctAnswerPromptBodyEffectsFamily}
					</li>
					<li>
						<img src={PayrollIconPNG} className="icon" srcSet={`${PayrollIconPNG}, ${PayrollIcon2xPNG} 2x`} alt=""/>
						{Model.correctAnswerPromptBodyEffectsPayroll}
						</li>
					<li>
						<img src={BusinessIconPNG} className="icon" srcSet={`${BusinessIconPNG}, ${BusinessIcon2xPNG} 2x`} alt=""/>
						{Model.correctAnswerPromptBodyEffectsBusiness}
					</li>
				</ul>
				{Model.correctAnswerPromptBodyEffectsConclusion}
			</div>
		)}
	</AnswerSection>
)

const TaxEveryone = ({isMobile, nextSection}) => (
	<PollContainer
		isMobile={isMobile}
		options={Model.options}
		nominalDebtForOptions={Model.nominalDebtForOptions}
		correctAnswer={Model.answer}
		debtGDPPercent={Facts.econ.projectedNetDebtGDP}
		debtGDPPercentForOptions={Model.GDPPercentForOptions}
		componentForType={ type => {
			switch(type) {
				case PollContainerType.POLL:
					return Poll
				case PollContainerType.WRONGANSWER:
					return WrongAnswer
				case PollContainerType.ANSWER:
					return Answer
				case PollContainerType.WHATELSE:
					return null
				default:
					throw new Error("Unknown poll container type: " + type);
			}
		}}
		nextSection={nextSection}
	/>
)

export default TaxEveryone

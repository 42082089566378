import React from "react"

import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"
import Accordion from "../../common/components/accordion/accordion"

import MedicaidReformModel from "../../../config/tour/spending/tour-medicaid-reform-model"

const MedicaidReformsAccordion = () => {
	return (
    <ScrollIndicatorWrapper 
      id="medicaid-reforms-accordion-wrapper" 
      label="What if we enacted all proposed reforms?">
      <section 
        id="medicaid-reforms-accordion" 
        className="full-page-w-menu row-content v-center">
				<div className="content">
					<h2>{MedicaidReformModel.ReformsAccordionTitle}</h2>
					<Accordion 
						className="scroll-anchor scroll-middle"
						rows={MedicaidReformModel.ReformsAccordionRows}
					/>
				</div>
			</section>
		</ScrollIndicatorWrapper>
	)
}

export default MedicaidReformsAccordion


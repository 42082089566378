import React from "react"
import ScrollPercentage from "react-scroll-percentage"
import WhatElseMenu from "./spending-what-else-menu"

import IntroModel from "../../config/tour/spending/tour-spending-intro-model"
const Model = IntroModel.WhatNextSection

const SendingWhatNext = ({ onViewPercentageChange, ...rest }) => (
  <ScrollPercentage onChange={onViewPercentageChange || null}>
    <section className="row-content full-page-w-menu v-center w-sidebar">
      <div>
        <div className="content">
          <div className="scroll-anchor scroll-anchor-impl"/>
          {Model.headline}
        </div>
        <WhatElseMenu {...rest} />
      </div>
    </section>
  </ScrollPercentage>
)

export default SendingWhatNext

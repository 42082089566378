import React from "react"

import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

import IllustrationSidebarWrapper from "../illustration-sidebar-wrapper"
import WhatElseMenu from "../economy-what-else-menu"
import RegulatoryReformPNG from "./illustrations/11.2.png"

import TourEconomyModel from "../../../config/tour/economy/tour-economy"
const Model = TourEconomyModel.RegulatoryReform

const RegulatoryReformPage = ({isMobile}) => {
	return (
    <SideMenuWrapper key="regulatory-reform-page-wrapper">
      <IllustrationSidebarWrapper key="regulatory-reform-wrapper" src={RegulatoryReformPNG} alt={Model.illustrationAltDescription} isMobile={isMobile}>
        <div id="tour-economy-container">
          <div className="full-page-w-menu is-first-page row-content v-center">
            <div className="content">
              {Model.headline}
              {Model.body}
              <WhatElseMenu isMobile={isMobile}/>
            </div>
          </div>
        </div>
      </IllustrationSidebarWrapper>
    </SideMenuWrapper>
	)
}

export default RegulatoryReformPage
import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import AnimatedIllustration from "../common/components/animated-illustration/animated-illustration"

import "./illustration-sidebar-wrapper.css"

const IllustrationSidebarWrapper = ({src, alt, isMobile, children}) => {
	const parentClasses = cx({
		"illustration-sidebar-wrapper": true,
		"is-mobile": isMobile,
		"is-desktop": !isMobile,
	})

	const contentClasses = cx({
		"illustration-sidebar-content": true,
		"column-70": !isMobile,
	})

	const sidebarClasses = cx({
		"illustration-sidebar": true,
		"column-30": !isMobile,
	})

	return (
		<div className="illustration-sidebar-wrapper-background">
			<div className={parentClasses}>
				<aside className={sidebarClasses}>
					<AnimatedIllustration src={src} alt={alt} />
				</aside>
				<main className={contentClasses}>
					{children}
				</main>
			</div>
		</div>
	)
}

IllustrationSidebarWrapper.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
	isMobile: PropTypes.bool.isRequired,
}

export default IllustrationSidebarWrapper

import React, { Component } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Transition } from "react-transition-group";
import { Link } from "react-router-dom";

import HamburgerMenu from "../../../tour/common/components/hamburger-menu/hamburger-menu"
import LeftMenu from "../../../common/components/main-menu/main-menu-left"
import RightMenu from "../../../common/components/main-menu/main-menu-right"

import "../../../common/components/main-menu/main-menu.css"
import "./main-menu.css"

class MainMenu extends Component {
	static propTypes = {
		isMobile: PropTypes.bool.isRequired,
	}

	constructor(props) {
		super(props)
		this.unlisten = null
		this.state = {
			showHamburgerMenu: false,
		}
	}

	render() {
		const mainMenuClasses = cx({
			"is-mobile": this.props.isMobile,
			"is-desktop": !this.props.isMobile,
		})

		const transitionStyles = {
			entered:  { transform: "translateX(0)" },
			exiting: { transform: "translateX(-100%)" },
		};

		return (
			<nav id="main-menu" className={mainMenuClasses}>
				<div id="main-menu-bar">
					<LeftMenu
						isMobile={this.props.isMobile}
						onHamburgerClick={this.handleHamburgerClick}
					/>
					<RightMenu
						isMobile={this.props.isMobile}
					>
						<Link to="/tour" className="explore-btn">Tour the Budget</Link>
						<a href="https://calculator.americaoffbalance.org" className="build-btn">Fix the Budget</a>
					</RightMenu>
				</div>

				<Transition
					in={this.state.showHamburgerMenu}
					timeout={200}
					unmountOnExit
				>
					{ state => {
						return (
							<HamburgerMenu
								style={{
									...transitionStyles[state]
								}}
								isMobile={this.props.isMobile}
								onDismissClick={this.handleHamburgerClick}
							/>
						)
					}}
				</Transition>
			</nav>
		)
	}

	handleHamburgerClick = (e) => {
		e.preventDefault()
		const show = !this.state.showHamburgerMenu
		this.setState({
			showHamburgerMenu: show,
		})
	}
}

export default MainMenu

import React  from "react"
import cx from "classnames"

import ScrollIndicatorWrapper from "../scroll-indicator/scroll-indicator"

const AnswerSection = ({ isMobile, scrollIndicatorLabel, options, answer, correctAnswer, debtGDPPercent, debtGDPPercentForOptions, nominalDebtForOptions, className, children, ...rest }) => {

	const rowClasses = [cx({
		"row-content": true,
		"full-page-w-menu": true,
		"w-sidebar": true,
  }), className].join(" ")

	return (
		<ScrollIndicatorWrapper label={scrollIndicatorLabel}>
			<section className={rowClasses} {...rest}>
				<div className="content">
					<div className="scroll-anchor scroll-anchor-impl" />
          { children(answer === correctAnswer) }
				</div>
			</section>
		</ScrollIndicatorWrapper>
	)
}

export default AnswerSection
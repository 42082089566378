import React from "react"
import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"

import "./what-do-we-mean-by-cuts.css"
import WhatDoWeMeanByCutsSVG from "./illustrations/what-do-we-mean-by-cuts.svg"

import IntroModel from "../../config/tour/spending/tour-spending-intro-model"
const Model = IntroModel.WhatDoWeMeanByCutsSection

const WhatDoWeMeanByCuts = () => (
  <ScrollIndicatorWrapper 
    id="what-do-we-mean-by-cuts-wrapper" 
    label={Model.scrollIndicatorLabel}>
    <section 
      id="what-do-we-mean-by-cuts" 
      className="full-page has-scroll-indicator v-center">
				<div className="split-layout">
					<div className="split-layout-column">
						{Model.headline}
						{Model.body}
					</div>
					<div className="split-layout-column">
						<img src={WhatDoWeMeanByCutsSVG} alt={Model.illustrationAltDescription} />
					</div>
				</div>
		</section>
	</ScrollIndicatorWrapper>
)
export default WhatDoWeMeanByCuts

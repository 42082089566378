
import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let TourTaxesIntro = {}

// Landing Section

TourTaxesIntro.LandingSection = {
  headline: (
    <h2>
      Let's take a look at tax revenue over the next 30 years
    </h2>
  ),

  body: (
    <p>
      The U.S. has separate federal, state, and local governments with taxes imposed at each of these levels. Federal taxes are levied on wages, salaries, capital gains, dividends, estates and gifts, and many other sources of income.
    </p>
  ),

  scrollIndicatorLabel: "Let's try raising taxes",
}

// Keep in Mind Section

TourTaxesIntro.KeepInMindSection = {
  headline: (
    <h2>
      Keep in Mind
    </h2>
  ),

  body: (
    <p>
      Increasing tax rates or cutting loopholes will raise less money than these calculations predict. Businesses owners would hire fewer workers. Workers might work less, and investors would change their investment strategies. Corporations might shift production overseas. <Tooltip value="Economists debate how much businesses and earners would change their behavior. One estimate finds that a $1 increase in revenue costs the economy $1.40.">These changes will reduce taxable income.</Tooltip>
    </p>
  ),

  scrollIndicatorLabel: "Let's try raising taxes",
}

// Poll Section

const YearDebtExceedsGDP = [2035, 2041, 2050, 2050]
const Options = ["1%", "4%", "7%", "10%"]

TourTaxesIntro.TaxEveryonePoll = {
  options: Options,

  answer: 2,

  GDPPercentForOptions: [137, 118, 99, 80],

  nominalDebtForOptions: [90.6, 78.6, 66.5, 54.3],

  // Prompt
  promptHeadline: (
    <h2>
      Raise taxes on everyone
    </h2>
  ),

  promptBody: (
    <p>
      The estimated tax revenue for {Facts.econ.projectedYear} is $13 trillion. With the national debt at $99.4 trillion, how much would federal taxes need to increase to keep the debt from passing 100% of GDP?
    </p>

  ),

  // Wrong Answer

  // - too little
  wrongAnswerTooLittleHeadline: (answer) => (
    <h2>
      Nope. {answer} wouldn't be enough.
    </h2>
  ),

  wrongAnswerTooLittleBody: (answer) => (
    <React.Fragment>
      <p>
        While significant, a {answer} increase on everyone's taxes would still not cover the national debt. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
      </p>
    </React.Fragment>
  ),

  // - too much
  wrongAnswerTooMuchHeadline: (answer) => (
    <h2>
      Nope. {answer} would be more than enough.
    </h2>
  ),

  wrongAnswerTooMuchBody: (answer) => (
    <p>
      A {answer} increase would keep debt below 100% of GDP but debt would still rise from today's levels.
    </p>
  ),

  // Correct Answer

  correctAnswerPromptHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }Raise taxes on everyone by {answer}.
    </h2>
  ),

  correctAnswerPromptBody: (
    <p>
      It may not seem like much, but this tax increase would need to apply to all types of federal taxes: payroll, income, and corporate taxes.
    </p>
  ),

  correctAnswerPromptBodyEffectsFamily: (
    <p>
      The average family's post-tax income would fall by about $1,000.
    </p>
  ),

  correctAnswerPromptBodyEffectsPayroll: (
    <p>
      <Tooltip value="Payroll taxes are levied on wages and earnings. The Social Security payroll tax is currently at 12.4% and the Medicare payroll tax is at 2.9%.  These taxes are officially split evenly between employees and employers but most economists believe employees pay their share plus the employer's share in the form of lower wages.">Payroll tax</Tooltip> rates would rise to 16.4%.
    </p>
  ),

  correctAnswerPromptBodyEffectsBusiness: (
    <p>
      Some families and businesses would face a top federal tax rate of 43.7%.
    </p>
  ),

  correctAnswerPromptBodyEffectsConclusion: (
    <p>
      That would be quite extreme.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

TourTaxesIntro.WhatNextSection = {
  headline: (
    <h2>
      Balance the budget through tax increases. Let's explore some more options:
    </h2>
  )
}

export default TourTaxesIntro

import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import cx from "classnames"
import scrollIntoView from "smooth-scroll-into-view-if-needed"

import "./jump-to-menu.css"
import TaxesIconSVG from "./taxes-icon.svg"
import SpendingIconSVG from "./spending-icon.svg"
import EconomyIconSVG from "./economy-icon.svg"
import ConclusionIconSVG from "./conclusion-icon.svg"


const HereIconSVG = () => (
	<svg height="24" viewBox="0 0 23 24" width="23">
		<path d="m11.6947002 20.3636364h-.10467l.052335.052335zm.8507543-.8507544 8.7392759-8.7392758 1.4142136 1.4142135-11.0565788 11.0565788-11.05657876-11.0565788 1.41421356-1.4142135 8.5454545 8.5454545v-19.3190607h2z"/>
	</svg>
)

const JumpButton = ({to, path, isDivider, children}) => {
	const isHere = (to === path)

	const className = cx({
		"jump-button": true,
		"is-here": isHere,
		"is-divider": isDivider,
		"is-visited": window.historyStack.indexOf(to) >= 0 ? true : false,
	})

	return (
		<Link to={to} className={className}>
				<div className="label">{children}</div>
				<HereIconSVG />
		</Link>
	)
}

class JumpToMenu extends Component {
	static propTypes = {
		isMobile: PropTypes.bool.isRequired,
		path: PropTypes.string.isRequired,
	}

	componentDidMount() {
		this.scrollToCurrent()
	}

	scrollToCurrent = () => {
		const query = "#jump-to-menu a.is-here"
		const el = document.querySelector(query)
		if (el) {
			// el.scrollIntoView({behavior: "instant", block: "end", inline: "nearest"})
			scrollIntoView(el)
		}
	}

	render() {
		const jumpToMenuClasses = cx({
			"is-mobile": this.props.isMobile,
			"is-desktop": !this.props.isMobile,
		})

		return (
			<nav id="jump-to-menu" className={jumpToMenuClasses}>
				<div className="menu-rows-wrapper">
					<div className="menu-row taxes">
						<div className="title-icon-wrapper">
							<div className="icon"><img src={TaxesIconSVG} alt=""/></div>
							<div className="title">Raise Taxes</div>
						</div>
						<div className="section-menu">
							<div className="section-menu-row">
								<JumpButton to="/tour/taxes" isDivider path={this.props.path}>Introduction</JumpButton>
								<JumpButton to="/tour/taxes/tax-top-25" path={this.props.path}>On the Top 25%</JumpButton>
								<JumpButton to="/tour/taxes/tax-top-1" path={this.props.path}>On the Top 1%</JumpButton>
								<JumpButton to="/tour/taxes/tax-corporations" path={this.props.path}>On Corporations</JumpButton>
								<JumpButton to="/tour/taxes/close-loopholes" path={this.props.path}>Close Loopholes</JumpButton>
							</div>
						</div>
					</div>
					<div className="menu-row spending">
						<div className="title-icon-wrapper">
							<div className="icon"><img src={SpendingIconSVG} alt=""/></div>
							<div className="title">Cut Spending</div>
						</div>
						<div className="section-menu">
							<div className="section-menu-row">
								<JumpButton to="/tour/spending" isDivider path={this.props.path}>Introduction</JumpButton>
								<JumpButton to="/tour/spending/cut-discretionary-spending" isDivider path={this.props.path}>Discretionary Spending</JumpButton>
								<JumpButton to="/tour/spending/cut-defense" path={this.props.path}>Defense</JumpButton>
								<JumpButton to="/tour/spending/cut-foreign-aid" path={this.props.path}>Foreign Aid</JumpButton>
								<JumpButton to="/tour/spending/cut-epa" path={this.props.path}>EPA</JumpButton>
								<JumpButton to="/tour/spending/cut-amtrak" path={this.props.path}>Amtrak</JumpButton>
								<JumpButton to="/tour/spending/cut-mandatory-spending" isDivider path={this.props.path}>Mandatory Spending</JumpButton>
								<JumpButton to="/tour/spending/reform-social-security" path={this.props.path}>Social Security</JumpButton>
								<JumpButton to="/tour/spending/reform-medicaid" path={this.props.path}>Medicaid</JumpButton>
								<JumpButton to="/tour/spending/reform-medicare" path={this.props.path}>Medicare</JumpButton>
							</div>
						</div>
					</div>
					<div className="menu-row economy">
						<div className="title-icon-wrapper">
							<div className="icon"><img src={EconomyIconSVG} alt=""/></div>
							<div className="title">Grow the Economy</div>
						</div>
						<div className="section-menu">
							<div className="section-menu-row">
								<JumpButton to="/tour/economy" isDivider path={this.props.path}>Introduction</JumpButton>
								<JumpButton to="/tour/economy/tax-reform" path={this.props.path}>Tax Reform</JumpButton>
								<JumpButton to="/tour/economy/regulatory-reform" path={this.props.path}>Regulatory Reform</JumpButton>
								<JumpButton to="/tour/economy/entitlement-reform" path={this.props.path}>Entitlement Reform</JumpButton>
								<JumpButton to="/tour/economy/increase-immigration" path={this.props.path}>Increase Immigration</JumpButton>
								<JumpButton to="/tour/economy/infrastructure" path={this.props.path}>Infrastructure</JumpButton>
							</div>
						</div>
					</div>
					<div className="menu-row give-up">
						<div className="title-icon-wrapper">
							<div className="icon"><img src={ConclusionIconSVG} alt=""/></div>
							<div className="title">A Way Forward</div>
						</div>
						<div className="section-menu">
							<div className="section-menu-row">
								<JumpButton to="/tour/a-way-forward" isDivider path={this.props.path}>What Now?</JumpButton>
								<a href="https://calculator.americaoffbalance.org" className="jump-button is-divider">Fix the Budget</a>
							</div>
						</div>
					</div>
				</div>
			</nav>
		)
	}
}

export default JumpToMenu


import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

const YearDebtExceedsGDP = [2042, 2045, 2050, 2050]
const Options = ["10%", "13%", "17%", "20%"]

let CutMostSpendingPoll = {
  options: Options,

  answer: 2,

  GDPPercentForOptions: [117, 109, 99, 91],

  nominalDebtForOptions: [78.2, 73.3, 66.7, 61.8],

  // Prompt

  promptHeadline: (
    <h2>
      Cut all spending except Social Security and Health Care programs.
    </h2>
  ),

  promptBody: (
    <p>
			Cutting Social Security and health care programs would be politically difficult. Could we reach our goal without reforms to these popular programs? Protecting them would instead mean cutting defense spending, low income assistance programs, agricultural programs, and many others. How big would these cuts need to be?
    </p>
  ),

  // Wrong Answer

  // - too little
  wrongAnswerTooLittleHeadline: (answer) => (
    <h2>
      Nope. {answer} wouldn't be enough.
    </h2>
  ),

  wrongAnswerTooLittleBody: (answer) => (
    <p>
			Cutting {answer} wouldn't be enough. Even these small cuts would be very painful, impacting important government responsibilities like national security and education. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // - too much
  wrongAnswerTooMuchHeadline: (answer) => (
    <h2>
      Nope. Too high.
    </h2>
  ),

  wrongAnswerTooMuchBody: (answer) => (
    <p>
			A {answer} cut would be more than enough to lower debt below 100% of GDP. Even with these large cuts, however, the nation's debt would still grow from today's levels.
    </p>
  ),

  // Correct Answer

  correctAnswerPromptHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }We would need a {answer} cut in all government programs except Social Security and federal health care programs.
    </h2>
  ),

  correctAnswerPromptBody: (answer) => (
    <p>
      This cut would be painful. Spending would fall relative to earlier years. The defense budget would be immediately cut by about $100 billion. We would need to reduce cash and food assistance to low-income families, the disabled, and low-income seniors. Veterans' programs would also be cut. Federal spending on roads, air, and rail would be cut, as well as spending on K-12 and higher education. We could protect some of these programs, but that would just mean even bigger cuts to the others.
    </p>
  ),

  answerScrollIndicatorLabel: "Why so much?",
}

export default CutMostSpendingPoll

import Circle from "../models/circle"
import Point from "../models/point"
import { LineSegment } from "../models/line"
import CircleLineSegmentIntersection from "./circle-line-segment-intersection"
import CircleCircleIntersectionPoints from "./circle-intersection"
import PartialCircle from "./svg-partial-circle.js"

const safePointAngle = (point, originPoint) => {
	let y = point.y - originPoint.y
	let x = point.x - originPoint.x
	let rad = Math.atan2(y, x)
	return rad
}

function SVGMeltingCircle(w, h, c1, roundCornerRadius, barHeight) {
	const r = c1.r
	let overlapDistance = 0
	if (c1.y + r >= h/2 - barHeight) {
		overlapDistance = c1.y + r - (h/2 - barHeight)
	}

	const overlapsBottomBar = c1.y + r > h/2 - barHeight ? true : false
	let path = []
	let intersect = false
	if (overlapsBottomBar) {
		const segP1 = new Point(w/2 * -1, h/2 - barHeight - 0.25 * overlapDistance)
		const segP2 = new Point(w/2, h/2 - barHeight - 0.75 * overlapDistance)
		const segment = new LineSegment(segP1, segP2)
		const [ intP1, intP2 ] = CircleLineSegmentIntersection(segment, c1)

		intersect = (intP1 && intP2) ? true : false

		if (intersect) {
			const intC1 = new Circle(intP1.x, intP1.y, roundCornerRadius)
			const intC2 = new Circle(intP2.x, intP2.y, roundCornerRadius)
			// Intersect each intersection circle with the line segment and the main circle
			// intC1
			const [ segmentIntC1P1, segmentIntC1P2 ] = CircleLineSegmentIntersection(segment, intC1)
			const [ c1IntC1P1, c1IntC1P2 ] = CircleCircleIntersectionPoints(c1, intC1) // eslint-disable-line no-unused-vars
			// intC2
			const [ segmentIntC2P1, segmentIntC2P2 ] = CircleLineSegmentIntersection(segment, intC2)
			const [ c1IntC2P1, c1IntC2P2 ] = CircleCircleIntersectionPoints(c1, intC2) // eslint-disable-line no-unused-vars

			if (segmentIntC1P1 && segmentIntC1P2 && segmentIntC2P1 && segmentIntC2P2) {


				// Calculate intersection points arcangles relative to c2
				// to use in recreating the partial arc...
				let angIntC1 = safePointAngle(c1IntC1P1, c1)
				let angIntC2 = safePointAngle(c1IntC2P2, c1)

				path = PartialCircle(
					c1.x, c1.y,
					c1.r,
					angIntC2, angIntC1
				).concat(
					[
						['S', intP1.x, intP1.y, segmentIntC1P1.x, segmentIntC1P1.y],
						['L', segP2.x, segP2.y],
						['L', w/2, h/2],
						['L', w/2 * -1, h/2],
						['L', segP1.x, segP1.y],
						['L', segmentIntC2P2.x, segmentIntC2P2.y],
						['S', intP2.x, intP2.y, c1IntC2P2.x, c1IntC2P2.y],
					]
				)
			} else {
				intersect = false
			}
		}

		// We don't have an intersection but we do overlap
		// this is the fully melted state
		if(!intersect) {
			path = [
				['M', w/2 * -1, h/2], // bottom left
				['H', w],
				['L', w/2, segP2.y],
				['L', w/2 * -1, segP1.y],
				['Z'],
			]
		}
	} else {
		// Doesn't overlap with the bottom bar
		path = [
			['M', w/2 * -1, h/2], // bottom left
			['H', w],
			['V', h/2 - barHeight],
			['H', w * -1],
			['Z'],
		]
	}

	return {
		intersect: intersect,
		intersectProgress: Math.max(overlapDistance  / c1.r, 0), 
		commands: path,
	}
}

export default SVGMeltingCircle

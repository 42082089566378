import React, { Component } from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import "./spending-graph.css"

class GDPGraph extends Component {

	static propTypes = {
		isMobile: PropTypes.bool.isRequired,
		model: PropTypes.object.isRequired,
		isHidden: PropTypes.bool,
  }

  capShares(shares, cap) {
    for(let i = 0; i < shares.length; i++) {
      this.capSharesForIndex(shares, i, cap)
    }
  }

  capSharesForIndex(shares, capIndex, cap) {
    if (shares[capIndex] >= cap) {
      return
    }
    const distributedDiff = (cap - shares[capIndex]) / (shares.length - 1)
    for(let i = 0; i < shares.length; i++) {
      if (i !== capIndex) {
        shares[i] -= distributedDiff
      } else {
        shares[i] = cap
      }
    }
  }

	render() {
		const isMobile = this.props.isMobile
		const graphClasses = cx({
			"spending-graph": true,
			"is-mobile": isMobile,
			"is-desktop": !isMobile,
			"is-hidden": this.props.isHidden,
		})

    const m = this.props.model
    const breakdown = [
      m.defense, 
      m.nonDefenseDiscretionary, 
      m.socialSecurity, 
      m.medicare + m.otherHealthCarePrograms, 
      m.otherEntitlements
    ]
    const sum = breakdown.reduce((a, c) => a + c)
    const shares = breakdown.map((c) => (c / sum) * 100)

    const cappedShares = shares.slice(0)
    this.capShares(cappedShares, 6.0)

		// Swap keys on orientation change to avoid animations
		const key = isMobile ? "spending-graph-mobile" : "spending-graph-desktop"

		// Fade out percent labels for default model
		const isDefaultModel = (m.socialSecurity === m.nonDefenseDiscretionary)
		const percentClasses = cx({
			"percent-label": true,
			"is-visible": !isDefaultModel,
		})
	
		return (
			<div className={graphClasses} key={key}>
				<div className="graph">
					<div className="bar-segment" style={isMobile ? {width: `${cappedShares[3]}%`} : {height: `${cappedShares[3]}%`}}>
						<div className="label">{isMobile ? "Health Care Programs" : "Medicare & Other Health Programs"}<span className={percentClasses}> — {Math.round(shares[3])}%</span></div>
					</div>
					<div className="bar-segment" style={isMobile ? {width: `${cappedShares[2]}%`} : {height: `${cappedShares[2]}%`}}>
						<div className="label">Social Security<span className={percentClasses}> — {Math.round(shares[2])}%</span></div>
					</div>
					<div className="bar-segment" style={isMobile ? {width: `${cappedShares[4]}%`} : {height: `${cappedShares[4]}%`}}>
						<div className="label">Other Entitlements<span className={percentClasses}> — {Math.round(shares[4])}%</span></div>
					</div>
					<div className="bar-segment" style={isMobile ? {width: `${cappedShares[1]}%`} : {height: `${cappedShares[1]}%`}}>
						<div className="label">Non Defense<span className={percentClasses}> — {Math.round(shares[1])}%</span></div>
					</div>
					<div className="bar-segment" style={isMobile ? {width: `${cappedShares[0]}%`} : {height: `${cappedShares[0]}%`}}>
						<div className="label">Defense<span className={percentClasses}> — {Math.round(shares[0])}%</span></div>
					</div>
				</div>
			</div>
		)
	}
}

export default GDPGraph

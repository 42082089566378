
import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars
import Format from "../../../common/utils/format"

const YearDebtExceedsGDP = [2038, 2041, 2045, 2050]
const Options = ["18%", "28%", "38%", "48%"]

const CutDefense = {
  options: Options,

  answer: 3,

  GDPPercentForOptions: [126, 117, 108, 99],

  nominalDebtForOptions: [84.2, 78.5, 72.7, 66.9],

  // Prompt

  promptHeadline: (
    <h2>
      Cut the defense budget
    </h2>
  ),

  promptBody: (
    <p>
      In {Facts.econ.year}, defense spending was about {Format(Facts.econ.defenseSpending)}. How much must we cut defense spending by to meet our goal?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Nope. Cutting defense by {answer} would be insufficient.
    </h2>
  ),

  wrongAnswerBody: (answer, diffP) => (
    <p>
      This would lower the {Facts.econ.projectedYear} debt by {diffP}% of GDP. A significant savings but not enough by itself. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }We would need to cut the defense budget by {answer}.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
			A {answer} cut would push the defense budget to new lows. As a share of GDP, the U.S. would spend less on defense than the spending in China, Russia, France, and many more countries.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default CutDefense

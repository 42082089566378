import React from "react"

import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"
import SideMenuWrapper from "../common/components/main-side-menu/main-side-menu-wrapper"
import CalculatorLanding from "./calculator-landing"
import AnimatedIllustration from "../common/components/animated-illustration/animated-illustration"

import "./conclusion-page.css"
import GiveUpPNG from "./illustrations/15.1.png"
import BalancingActPNG from "../intro/illustrations/8.2.png"
import DontMakeItWorsePNG from "./illustrations/17.2.png"
import GrowTheEconomyPNG from "./illustrations/18.1.png"
import ReformEntitlementProgramsPNG from "./illustrations/19.3.png"
import StartReformsNowPNG from "./illustrations/20.2.png"

import Model from "../../config/tour/conclusion/tour-conclusion-model"

const ConclusionLanding = () => (
	<ScrollIndicatorWrapper id="tour-conclusion-landing-wrapper" label={Model.LandingSection.scrollIndicatorLabel}>
		<section id="tour-conclusion-landing" className="full-page-w-menu is-first-page has-scroll-indicator v-center">
			<div className="split-layout">
				<div className="split-layout-column">
					{Model.LandingSection.headline}
					{Model.LandingSection.body}
				</div>
				<div className="split-layout-column illustration">
					<AnimatedIllustration src={GiveUpPNG} alt={Model.LandingSection.illustrationAltDescription} />
				</div>
			</div>
		</section>
	</ScrollIndicatorWrapper>
)

const AWayForward = () => (
	<ScrollIndicatorWrapper label={Model.AWayForwardSection.scrollIndicatorLabel}>
		<section id="a-way-forward" className="full-page has-scroll-indicator v-center">
			<div className="split-layout flipped">
				<div className="split-layout-column copy">
					<div className="scroll-anchor scroll-anchor-impl" />
					{Model.AWayForwardSection.headline}
					{Model.AWayForwardSection.body}
				</div>
				<div className="split-layout-column illustration">
					<AnimatedIllustration src={BalancingActPNG} alt={Model.AWayForwardSection.illustrationAltDescription} flipped/>
				</div>
			</div>
		</section>
	</ScrollIndicatorWrapper>
)

const DontMakeItWorse = () => (
	<ScrollIndicatorWrapper label={Model.DontMakeItWorseSection.scrollIndicatorLabel}>
		<section id="dont-make-it-worse" className="full-page has-scroll-indicator v-center">
			<div className="split-layout">
				<div className="split-layout-column copy">
					<div className="scroll-anchor scroll-anchor-impl" />
					{Model.DontMakeItWorseSection.headline}
					{Model.DontMakeItWorseSection.body}
				</div>
				<div className="split-layout-column illustration">
					<AnimatedIllustration src={DontMakeItWorsePNG} alt={Model.DontMakeItWorseSection.illustrationAltDescription} />
				</div>
			</div>
		</section>
	</ScrollIndicatorWrapper>
)

const GrowTheEconomy = () => (
	<ScrollIndicatorWrapper label={Model.GrowTheEconomySection.scrollIndicatorLabel}>
		<section id="grow-the-economy" className="full-page has-scroll-indicator v-center">
			<div className="split-layout flipped">
				<div className="split-layout-column copy">
					<div className="scroll-anchor scroll-anchor-impl" />
					{Model.GrowTheEconomySection.headline}
					{Model.GrowTheEconomySection.body}
				</div>
				<div className="split-layout-column illustration">
					<AnimatedIllustration src={GrowTheEconomyPNG} alt={Model.GrowTheEconomySection.illustrationAltDescription} flipped/>
				</div>
			</div>
		</section>
	</ScrollIndicatorWrapper>
)

const ReformEntitlementPrograms = () => (
	<ScrollIndicatorWrapper label={Model.ReformEntitlementProgramsSection.scrollIndicatorLabel}>
		<section id="reform-entitlement-programs" className="full-page has-scroll-indicator v-center">
			<div className="split-layout">
				<div className="split-layout-column copy">
					<div className="scroll-anchor scroll-anchor-impl" />
					{Model.ReformEntitlementProgramsSection.headline}
					{Model.ReformEntitlementProgramsSection.body}
				</div>
				<div className="split-layout-column illustration">
					<AnimatedIllustration src={ReformEntitlementProgramsPNG} alt={Model.ReformEntitlementProgramsSection.illustrationAltDescription} />
				</div>
			</div>
		</section>
	</ScrollIndicatorWrapper>
)

const StartReformsNow = () => (
	<ScrollIndicatorWrapper label={Model.StartReformsNowSection.scrollIndicatorLabel}>
		<section id="start-reforms-now" className="full-page has-scroll-indicator v-center">
			<div className="split-layout flipped">
				<div className="split-layout-column copy">
					<div className="scroll-anchor scroll-anchor-impl"/>
					{Model.StartReformsNowSection.headline}
					{Model.StartReformsNowSection.body}
				</div>
				<div className="split-layout-column illustration">
					<AnimatedIllustration src={StartReformsNowPNG} alt={Model.StartReformsNowSection.illustrationAltDescription} />
				</div>
			</div>
		</section>
	</ScrollIndicatorWrapper>
)

const ConclusionContent = () => (
	<section id="tour-conclusion-content">
		<AWayForward />
		<DontMakeItWorse />
		<GrowTheEconomy />	
		<ReformEntitlementPrograms />
		<StartReformsNow />
	</section>
)

const ConclusionPage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="conclusion-page-wrapper">
			<div key="tour-conclusion-page" id="tour-conclusion-page">
				<ConclusionLanding />
				<ConclusionContent />
				<CalculatorLanding />
			</div>
		</SideMenuWrapper>
	)
}

export default ConclusionPage

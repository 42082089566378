import React from "react"
import { max, last } from "lodash"

import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"
import BackgroundGraph from "../common/components/background-graph/background-graph"

import Facts from "../../config/facts"
import Format from "../../common/utils/format"

import "./economy-landing.css"

import EconomyModel from "../../config/tour/economy/tour-economy-intro"
const Model = EconomyModel.LandingSection

const gdp = Facts.econ.historicalGDPTrillions
const m = max(gdp)
const points = gdp.map(x => x / m)

const projectedGDP = last(Facts.econ.historicalGDPTrillions) * Facts.const.trillion
const projectedGDPLabel = Format(projectedGDP, true, 1)

const EconomyLanding = ({isMobile}) => (
	<ScrollIndicatorWrapper id="economy-landing-wrapper" label={Model.scrollIndicatorLabel}>
		<section id="economy-landing" className="full-page-w-menu is-first-page has-scroll-indicator v-center">
			<div className="content">
				{Model.headline}
				{Model.body}
			</div>
			<BackgroundGraph 
				points={points}
				fromYear={Facts.historicalGDPFromYear}
				toYear={Facts.historicalGDPToYear}
				graphLabel="GDP"
				isMobile={isMobile}
				toValueLabel={projectedGDPLabel}
			/>
		</section>
	</ScrollIndicatorWrapper>
)
export default EconomyLanding
import React, { Component } from "react"
import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"
import AnimatedIllustration from "../common/components/animated-illustration/animated-illustration"

import "./intro-splash.css"
import BalancingActPNG from "./illustrations/8.2.png"

import TourIntroModel from "../../config/tour/intro/tour-intro-model"
const Copy = TourIntroModel.SplashSection

class IntroSplash extends Component {
	render() {
		return (
			<ScrollIndicatorWrapper id="intro-splash-wrapper" label={Copy.scrollIndicatorLabel}>
				<div id="intro-splash" className="full-page-w-menu is-first-page has-scroll-indicator v-center">
					<div className="split-layout">
						<div className="split-layout-column">
							{Copy.headline}
							{Copy.body}
              <button className="PlayVideo" onClick={this.props.toggleVideo}>
                <div className="PlayIcon" /> Watch the video
              </button>
						</div>
						<div className="split-layout-column">
							<AnimatedIllustration src={BalancingActPNG} alt={Copy.illustrationAltDescription}/>
						</div>
					</div>
				</div>
			</ScrollIndicatorWrapper>
		)
	}
}

export default IntroSplash

import React from "react"

import Landing from "./economy-landing"
import ProductivityEmployment from "./economy-productivity-employment"
import Start from "./economy-start"
import SideMenuWrapper from "../common/components/main-side-menu/main-side-menu-wrapper"

import "./tour-economy-container.css"

const EconomyPage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="economy-page-wrapper">
			<div id="tour-economy-container">
				<div id="tour-economy-page">
					<Landing isMobile={isMobile}/>
					<ProductivityEmployment />
					<Start isMobile={isMobile}/>
				</div>
			</div>
		</SideMenuWrapper>
	)
}

export default EconomyPage

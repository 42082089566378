import Circle from "../models/circle"
import remap from "./remap-number"
import CircleCircleIntersectionPoints from "./circle-intersection"
import PartialCircle from "./svg-partial-circle.js"

// Extract to circle util?
const  overlapDistance = (c1, c2) => {
	// Compute distance between two circles
	const dx = Math.abs(c1.x - c2.x)
	const dy = Math.abs(c1.y - c2.y)
	const d = Math.sqrt(dx*dx + dy*dy)
	// Overlap distance is the distance between circles minus the combined radii of
	// the circles, inverted
	return (d - c1.r - c2.r) * -1
}

const overlapProgress = (c1, c2) => {
	// Compute distance between two circles
	const dx = Math.abs(c1.x - c2.x)
	const dy = Math.abs(c1.y - c2.y)
	const d = Math.sqrt(dx*dx + dy*dy)
	const maxD = (c1.r + c2.r)
	return 1 - (d / maxD)
}

// restOfPath(c2IntC2P1, c2IntC1P2, c1IntC1P2, w, h, intC1, intC2, c2)
const restOfPath = (p1, p2, p3, intC1, intC2, blueCircle, safeToRoundCorners) => {
	if (!safeToRoundCorners) {
		return [
			['A', blueCircle.r, blueCircle.r, 0, 0, 1, p3.x, p3.y],
		]
	}

	return [
		['S', intC2.x, intC2.y, p1.x, p1.y],
		['A', blueCircle.r, blueCircle.r, 0, 0, 0, p2.x, p2.y],
		['S', intC1.x, intC1.y, p3.x, p3.y],
	]
}

function SVGSubstractedCircle(oC1, oC2, targetR, roundCornerRadius, maxDivider){

	const r = oC1.r
	const blueR = oC2.r
	const r2 = blueR + maxDivider
	const ratio = 1 - r / blueR

	const targetC1 = new Circle(oC1.x, oC1.y, r)
	const targetC2 = new Circle(oC2.x, oC2.y, r2)

	const targetOverlap = overlapDistance(targetC1, targetC2) > 0 ? 1 : 0

	const remapperRadius = remap({
		from: { low: 0, high: r },
		to: { low: targetR, high: r }
	})

	const remapperRadius2 = remap({
		from: { low: 0, high: r2 },
		to: { low: targetR, high: r2 }
	})

	const remapperRadiusBlue = remap({
		from: { low: 0, high: blueR },
		to: { low: targetR, high: blueR }
	})

	const remapper = remap({
		from: { low: ratio, high: 1 },
		to: { low: 0, high: 1 }
	})

	let progressRadius = r
	let progressRadius2 = r2
	let progressRadiusBlue = blueR

	// Logic: once it starts overlapping, blue circle shrinks until
	// they're almost the same radius and then they both start to shrink
	// until we get to target size

	if (targetOverlap > 0) {
		const op = overlapProgress(targetC1, targetC2)
		progressRadius2 = remapperRadius2(r2 - r2 * op)
		progressRadiusBlue = remapperRadiusBlue(blueR - blueR * op)
		if (op > ratio) {
			progressRadius = remapperRadius(r - r * remapper(op))
		}
	}

	const c1 = new Circle(oC1.x, oC1.y, progressRadius)
	const c2 = new Circle(oC2.x, oC2.y, progressRadius2)

	const blueC = new Circle(oC2.x, oC2.y, progressRadiusBlue)

	let [p1, p2] = CircleCircleIntersectionPoints(c1, c2)

	const overlapTreshold = 2
	const overlap = overlapDistance(c1, c2)
	const circlesOverlap = (p1 && p2) ? true : false

	let substractedCirclePath = []
	if (circlesOverlap) {
		const intC1 = new Circle(p1.x, p1.y, roundCornerRadius)
		const intC2 = new Circle(p2.x, p2.y, roundCornerRadius)

		// Intersect first intersect circle with both main circles
		const [ c1IntC1P1, c1IntC1P2 ] = CircleCircleIntersectionPoints(c1, intC1) // eslint-disable-line no-unused-vars
		const [ c2IntC1P1, c2IntC1P2 ] = CircleCircleIntersectionPoints(c2, intC1) // eslint-disable-line no-unused-vars
		// Intersect second intersect circle with both main circles
		const [ c1IntC2P1, c1IntC2P2 ] = CircleCircleIntersectionPoints(c1, intC2) // eslint-disable-line no-unused-vars
		const [ c2IntC2P1, c2IntC2P2 ] = CircleCircleIntersectionPoints(c2, intC2) // eslint-disable-line no-unused-vars

		// Calculate intersection points arcangles relative to c2
		// to use in recreating the partial arc...
		const safePointAngle = (point, originPoint) => {
			let y = point.y - originPoint.y
			let x = point.x - originPoint.x
			let rad = Math.atan2(y, x)
			return rad
		}
		let angIntC1 = safePointAngle(c1IntC1P2, c1)
		let angIntC2 = safePointAngle(c1IntC2P1, c1)

		// c1, c2, 
		// angIntC1, angIntC2, - big arc angle
		// c2IntC2P1, c2IntC1P2, c1IntC1P2 - small intersection points for inner curve
		// intC1, intC2 - the two small intersection circles
		// safeToRoundCorners flag - because when we're have a really small overlap
		// and large intersection circle radius it inverts the path
		substractedCirclePath = PartialCircle(
				c1.x, c1.y,
				c1.r,
				angIntC1, angIntC2
			).concat(restOfPath(
				c2IntC2P1, 
				c2IntC1P2, 
				c1IntC1P2, 
				intC1, intC2, 
				c2, 
				overlap >= overlapTreshold
			))
	}

	return {
		c1: c1, // first circle - one we're replacing anyways?
		c2: c2, // big circle
		blueC: blueC, // actual c2
		circlesOverlap: circlesOverlap,
		commands: substractedCirclePath,
	}
}

export default SVGSubstractedCircle

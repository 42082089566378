import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

import Logo from "../../../../common/components/logo/dynamic-logo-svg"

import "./hamburger-menu.css"

import FooterHooverLogo from "./footer-logo-hoover.png"
import FooterStanfordLogo from "./footer-logo-stanford.png"
import FacebookSVG from "./facebook.svg"
import TwitterSVG from "./twitter.svg"
import InstagramSVG from "./instagram.svg"

const HamburgerCloseIconSVG = () => (
	<svg height="14" viewBox="0 0 14 14" width="14">
		<path d="m7 5.58578644 4.9497475-4.94974747c.3905243-.39052429 1.0236892-.39052429 1.4142135 0s.3905243 1.02368927 0 1.41421356l-4.94974744 4.94974747 4.94974744 4.9497475c.3905243.3905243.3905243 1.0236892 0 1.4142135s-1.0236892.3905243-1.4142135 0l-4.9497475-4.94974744-4.94974747 4.94974744c-.39052429.3905243-1.02368927.3905243-1.41421356 0-.39052429-.3905243-.39052429-1.0236892 0-1.4142135l4.94974747-4.9497475-4.94974747-4.94974747c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356.39052429-.39052429 1.02368927-.39052429 1.41421356 0z"/>
	</svg>
)

const HamburgerMenu = ({isMobile, onDismissClick, style}) => {
	const menuClasses = cx({
		"is-mobile": isMobile,
		"is-desktop": !isMobile,
	})
	return (
		<aside id="hamburger-menu" className={menuClasses} style={style}>
			<nav className="hamburger-menu-bar">
        <Link to="/"><Logo /></Link>
        <a href="#menu" className="hamburger-close-btn" onClick={onDismissClick}>
          {HamburgerCloseIconSVG()}
        </a>
      </nav>
      <div className="hamburger-content-wrapper">
        <nav className="links-wrapper">
          <a href="https://americaoffbalance.org">Home</a>
          <a href="https://americaoffbalance.org/tour">Tour the Budget</a>
          <a href="https://calculator.americaoffbalance.org">Fix the Budget</a>
          <a href="https://budgetmatters.americaoffbalance.org">Budget Matters</a>
          <a href="https://americaoffbalance.org/about">About</a>
          <div style={{ fontSize: "0.75em", paddingLeft: "1em" }}>
            <a href="https://americaoffbalance.org/resources">Additional Resources</a>
            <a href="https://www.policyed.org" target="_blank" rel="noopener noreferrer">PolicyEd</a>
            <a href="mailto:americaoffbalance@stanford.edu" target="_blank" rel="noopener noreferrer">Email Us</a>
            <a href="http://eepurl.com/ge-lvP" target="blank" rel="noopener noreferrer">Subscribe</a>
            <a href="https://twitter.com/AOBalance" target="blank" rel="noopener noreferrer">Twitter</a>
          </div>
        </nav>
        <div className="more-info-wrapper">
          <div className="logo-wrapper">
            <a className="hoover-logo" href="https://www.hoover.org" target="_blank" rel="noopener noreferrer">
              <img src={FooterHooverLogo} alt="Hoover Institute logo"/>
            </a>
            <a className="stanford-logo" href="https://www.stanford.edu" target="_blank" rel="noopener noreferrer">
              <img src={FooterStanfordLogo} alt="Stanford University logo"/>
            </a>
          </div>
          <div className="social-wrapper">
            <span className="connect-label">Connect:</span>
            <a href="https://www.facebook.com/policyed/" target="_blank" rel="noopener noreferrer"><img src={FacebookSVG} alt="Facebook logo" className="social-icon"/></a>
            <a href="https://twitter.com/AOBalance" target="_blank" rel="noopener noreferrer"><img src={TwitterSVG} alt="Twitter logo" className="social-icon"/></a>
            <a href="https://www.instagram.com/policy_ed/" target="_blank" rel="noopener noreferrer"><img src={InstagramSVG} alt="Instagram logo" className="social-icon"/></a>
          </div>
          <div className="copyright-wrapper">
            © 2018 by the Board of Trustees of Leland Stanford Junior University
          </div>
          <nav className="additional-links-wrapper">
            <a href="https://adminguide.stanford.edu/chapter-1/subchapter-6/policy-1-6-1" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </nav>
        </div>
      </div>
		</aside>
	)
}

export default HamburgerMenu

import React from "react"

import Facts from "../../facts"

let MedicareReformModel = {}

// Landing Section

MedicareReformModel.LandingSection = {
	headline: (
    <h2>
      How does Medicare work?
    </h2>
  ),

  body: (
    <React.Fragment>
			<p>
				Medicare provides health subsidies to seniors (65 and older) and the disabled. The program has three  parts:
			</p>
			<ol>
				<li><p>Part A (Hospital Insurance) pays for inpatient hospital services.</p></li>
				<li><p>Part B (Supplemental Medicare Insurance) pays for about 80% non-hospital health expenses.</p></li>
				<li><p>Part D (Prescription drugs) pays a portion of prescription drug expenses.</p></li>
			</ol>
			<p>
				Part A is funded primarily by the Hospital Insurance payroll tax.  Part B and Part D are financed through general tax revenue and premiums on recipients. These premiums vary by income levels.
			</p>
    </React.Fragment>
  ),

  scrollIndicatorLabel: "Why is Medicare spending rising?",
}

// Why Is Spending Rising Section

MedicareReformModel.WhyIsSpendingRisingSection = {
	headline: (
    <h2>
      Why is Medicare spending rising?
    </h2>
  ),

  body: (
    <React.Fragment>
			<p>
				Similar to Social Security, demographics play a big role in Medicare spending growth. Many baby boomers are now eligible for Medicare, and enrollment in Social Security Disability Insurance has increased in recent years, further increasing Medicare enrollment. Per enrollee spending is on the rise due to increased medical costs.
			</p>
			<p>
				Learn more about the individual proposed reforms below.
			</p>
    </React.Fragment>
  ),

  scrollIndicatorLabel: "Learn more about Medicare reforms",
}

// Proposed Medicare Reforms

MedicareReformModel.ReformsAccordionTitle = "Proposed Medicare reforms:"

MedicareReformModel.ReformsAccordionRows = [
	{
		title: "Increase the age of eligibility",
		body: (
			<p>
        This reform increases the eligibility age for Medicare to 67 beginning in 2020, and would increase the age of eligibility by two months every year. Medicare costs would fall, but other health care programs would see expenditures rise so there would be little change in total spending. The reform would lower {Facts.econ.projectedYear} debt by 2% of GDP. As a share of GDP, debt would exceed 100% in 2034.
			</p>
		)
	},
	{
		title: "Increase Parts B and D premiums",
		body: (
			<p>
        This option would increase premiums for most enrollees. Middle-income recipients would see premiums rise by 40%. That is an extra $800 per year. A related reform would label more Medicare recipients as high-income, increasing their premiums by even more than 40%. This reform lowers the {Facts.econ.projectedYear} debt by 10% of GDP. Debt would surpass 100% of GDP in 2036.
			</p>
		)
	},
	{
		title: "Increase out-of-pocket payments",
		body: (
			<p>
        Medicare only pays for a portion of recipients' healthcare costs. Any remaining out-of-pocket expenses are paid by the individual or by supplemental insurance plans. This reform would limit supplemental plans and increase out-of-pocket expenses. The hope is that recipients will think twice before undergoing unnecessary medical procedures. The proposal would reduce {Facts.econ.projectedYear} debt by 3% of GDP. Debt would still exceed 100% of GDP in 2034.
			</p>
		)
	},
]

// Poll

const cp = Facts.econ.projectedNetDebtGDP * 100
const cpNominal = Facts.econ.projectedNetDebt / Facts.const.trillion

MedicareReformModel.MedicareReformPoll = {

	options: ["5%", "10%", "15%", "20%"],

  answer: 2,

  GDPPercentForOptions: [cp, cp, 128.0, cp],

  nominalDebtForOptions: [cpNominal, cpNominal, 85.4, cpNominal],

  // Prompt

  promptHeadline: (
    <h2>
      What if we enacted all proposed Medicare reforms?
    </h2>
  ),

  promptBody: (
    <p>
      How much would the U.S. debt be reduced by in {Facts.econ.projectedYear}?
    </p>
  ),

  // Wrong Answer

	// - too little
  wrongAnswerTooLittleHeadline: (answer) => (
    <h2>
      Close. However, {answer} is too low.
    </h2>
  ),

  wrongAnswerTooLittleBody: (answer) => (
    <p>
      Medicare spending is about as large as defense spending and is growing much more rapidly at a projected rate of 7% over the next decade.
    </p>
  ),

	// - too much
  wrongAnswerTooMuchHeadline: (answer) => (
    <h2>
      Close. However, {answer} is too high.
    </h2>
  ),

  wrongAnswerTooMuchBody: (answer) => (
    <p>
      Medicare spending is about as large as defense spending and is growing much more rapidly at a projected rate of 7% over the next decade. But these reforms aren't big enough to solve our problems.
    </p>
  ),
  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }Enacting these proposed Medicare reforms would reduce debt by only {answer} of GDP.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
			The impact on Medicare recipients would be drastic, though. Annual premiums would increase by $800, and the larger out-of-pocket payments would have the biggest impact on the sickest patients. Debt as a share of GDP would exceed 100% in 2037.
		</p>
  ),

  answerScrollIndicatorLabel: "What else can we try cutting?",
}

export default MedicareReformModel

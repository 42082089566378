import React from "react"
import { Link } from "react-router-dom"

import "./main-side-menu.css"

const MainSideMenu = ({isMobile}) => (
	<nav id="main-side-menu">
		<ul>
			<li className="raise-taxes">
        <Link to="/tour/taxes"><div className="label">Raise Taxes</div></Link>
      </li>
			<li className="cut-spending">
        <Link to="/tour/spending"><div className="label">Cut Spending</div></Link>
      </li>
			<li className="grow-the-economy">
        <Link to="/tour/economy"><div className="label">Grow the Economy</div></Link>
      </li>
			<li className="a-way-forward">
        <Link to="/tour/a-way-forward"><div className="label">A Way Forward</div></Link>
      </li>
		</ul>
	</nav>
)

export default MainSideMenu
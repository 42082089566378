import React from "react"
import { PickerMenu, PickerMenuButton, SkipToSpendingMenuButton } from "../common/components/picker-menu/picker-menu"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import ScrollPercentage from "react-scroll-percentage"

import "./taxes-what-else-menu.css"

const LinkedPickerMenuButton = ({label, to, path, ...rest}) => {
	if (path === to) { 
		return null
	}

	const isVisited = window.historyStack.indexOf(to) >= 0 ? true : false

	return (
		<Link to={to}>
			<PickerMenuButton label={label} isVisited={isVisited} {...rest}/>
		</Link>
	)
}

const TaxesWhatElseMenu = ({ isMobile, match, onViewPercentageChange }) => {
	return (
		<ScrollPercentage onChange={onViewPercentageChange || null}>
			<section className="full-page-w-menu w-sidebar row-content v-center w-sidebar">
				<div>
					<div className="content">
						<div className="scroll-anchor scroll-anchor-impl"/>
						<h2>What else can we do?</h2>
					</div>
					<PickerMenu isMobile={isMobile}>
						<LinkedPickerMenuButton label="Only raise taxes on the top 25%" to="/tour/taxes/tax-top-25" path={match.path} />
						<LinkedPickerMenuButton label="Only raise taxes on the top 1%" to="/tour/taxes/tax-top-1" path={match.path} />
						<LinkedPickerMenuButton label="Only raise taxes on corporations" to="/tour/taxes/tax-corporations" path={match.path} />
						<LinkedPickerMenuButton label="Close tax loopholes" to="/tour/taxes/close-loopholes" path={match.path} />
						<SkipToSpendingMenuButton />
					</PickerMenu>
				</div>
			</section>
		</ScrollPercentage>
	)
}

export default withRouter(TaxesWhatElseMenu)

import React from "react"

const HamburgerIconSVG = () => (
	<svg height="15" viewBox="0 0 18 15" width="18">
		<g transform="translate(-1)">
			<rect height="2" rx="1" transform="matrix(.99756405 .06975647 -.06975647 .99756405 .196731 -.694339)" width="18" x="1.040002" y="1.469653"/>
			<rect height="2" rx="1" transform="matrix(.99862953 .05233596 -.05233596 .99862953 .710519 -.506653)" width="18" x="1.029417" y="12.313486"/>
			<rect height="2" rx="1" transform="matrix(.9781476 .20791169 -.20791169 .9781476 1.88147 -1.903132)" width="18" x=".994282" y="6.99893"/>
		</g>
	</svg>
)

export default HamburgerIconSVG
import React from "react"

import Landing from "./spending-landing"
import WhatDoWeMeanByCuts from "./what-do-we-mean-by-cuts"
import CutAllSpending from "./spending-start"
import CutMostSpending from "./cut-most-spending/cut-most-spending-start"
import SpendingHistory from "./spending-history/spending-history"
import SideMenuWrapper from "../common/components/main-side-menu/main-side-menu-wrapper"

import "./tour-spending-container.css"

const SpendingPage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="spending-page-wrapper">
			<div key="tour-spending-container" id="tour-spending-container">
				<div id="tour-spending-page">
					<Landing isMobile={isMobile}/>
					<WhatDoWeMeanByCuts />
					<CutAllSpending isMobile={isMobile} nextSection={[
						<CutMostSpending key="cut-most-spending" isMobile={isMobile} nextSection={[
							<SpendingHistory key="spending-history" isMobile={isMobile} />
						]}/>
					]}/>
				</div>
			</div>
		</SideMenuWrapper>
	)
}

export default SpendingPage

import React from "react"
import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"

import "./reform-medicare-landing.css"

import MedicareReformModel from "../../../config/tour/spending/tour-medicare-reform-model"
const Model = MedicareReformModel.LandingSection

const ReformMedicareLanding = () => (
  <ScrollIndicatorWrapper 
    id="reform-medicare-landing-wrapper" 
    label={Model.scrollIndicatorLabel}>
    <section 
      id="reform-medicare-landing" 
      className="full-page-w-menu is-first-page has-scroll-indicator v-center is-landing">
			<div className="content">
        {Model.headline}
        {Model.body}
			</div>
		</section>
	</ScrollIndicatorWrapper>
)
export default ReformMedicareLanding

import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let SpendingHistoryModel = {
  headline: (
    <h2>
      Why do we need to cut these programs by so much?
    </h2>
  ),

  body: (
    <p>
      Let's examine the history of U.S. spending.
    </p>
  ),
}

const PastTitle = (<h2>How we spent in</h2>)
const CurrentTitle = (<h2>How we are spending today in</h2>)
const FutureTitle = (<h2>How we will be spending in</h2>)

class SpendingModel {
  constructor({socialSecurity, medicare, otherHealthCarePrograms, otherEntitlements, defense, nonDefenseDiscretionary}) {
    this.socialSecurity = socialSecurity
    this.medicare = medicare
    this.otherHealthCarePrograms = otherHealthCarePrograms
    this.otherEntitlements = otherEntitlements
    this.defense = defense
    this.nonDefenseDiscretionary = nonDefenseDiscretionary
  }
}

const SpendingBreakdownModels = [
  // 60s
  new SpendingModel({
    socialSecurity: 0.02723656,
    medicare: 0.005508518,
    otherHealthCarePrograms: 0.002346221,
    otherEntitlements: 0.027746608,
    defense: 0.08436193,
    nonDefenseDiscretionary: 0.0353,
  }),
  // 70s
  new SpendingModel({
    socialSecurity: 0.039990645,
    medicare: 0.009939196,
    otherHealthCarePrograms: 0.004833177,
    otherEntitlements: 0.038353601,
    defense: 0.045525413,
    nonDefenseDiscretionary: 0.0480,
  }),
  // 80s
  new SpendingModel({
    socialSecurity: 0.041478388,
    medicare: 0.014888293,
    otherHealthCarePrograms: 0.006228959,
    otherEntitlements: 0.03285506,
    defense: 0.054728428,
    nonDefenseDiscretionary: 0.0333,
  }),
  // 90s
  new SpendingModel({
    socialSecurity: 0.040825369,
    medicare: 0.019800831,
    otherHealthCarePrograms: 0.011393126,
    otherEntitlements: 0.027185265,
    defense: 0.029063021,
    nonDefenseDiscretionary: 0.0313,
  }),
  // 2000s
  new SpendingModel({
    socialSecurity: 0.046958799,
    medicare: 0.029455785,
    otherHealthCarePrograms: 0.017385219,
    otherEntitlements: 0.057664325,
    defense: 0.045503679,
    nonDefenseDiscretionary: 0.0402,
  }),
  // 2010s
  new SpendingModel({
    socialSecurity: 0.048625965,
    medicare: 0.029539348,
    otherHealthCarePrograms: 0.022537181,
    otherEntitlements: 0.025649104,
    defense: 0.031211838,
    nonDefenseDiscretionary: 0.031099739,
  }),
  // 2020s
  new SpendingModel({
    socialSecurity: 0.059737487,
    medicare: 0.041665421,
    otherHealthCarePrograms: 0.025694612,
    otherEntitlements: 0.022955742,
    defense: 0.025142709,
    nonDefenseDiscretionary: 0.025006036,
  }),
  // 2030s
  new SpendingModel({
    socialSecurity: 0.063122505,
    medicare: 0.052071798,
    otherHealthCarePrograms: 0.030054767,
    otherEntitlements: 0.021183939,
    defense: 0.025436637,
    nonDefenseDiscretionary: 0.025298366,
  }),
  // 2040s
  new SpendingModel({
    socialSecurity: 0.06139847,
    medicare: 0.061071601,
    otherHealthCarePrograms: 0.033791182,
    otherEntitlements: 0.019659447,
    defense: 0.025912217,
    nonDefenseDiscretionary: 0.02577136,
  }),
]

class Step {
  constructor(year, body, spendingBreakdown) {
    if (year < Facts.econ.year) {
      this.title = PastTitle
    } else if (year === Facts.econ.year) {
      this.title = CurrentTitle
    } else {
      this.title = FutureTitle
    }
    this.year = year
    this.body = body
    this.spendingBreakdown = spendingBreakdown
  }
}

SpendingHistoryModel.defaultSpendingBreakdown = new SpendingModel({
  socialSecurity: 1,
  medicare: 0.5,
  otherHealthCarePrograms: 0.5,
  otherEntitlements: 1,
  defense: 1,
  nonDefenseDiscretionary: 1,
})

SpendingHistoryModel.steps = [
  new Step(1969, (
    <p>
      We used to spend money on defense and other discretionary programs like education, justice, transportation, and international affairs.
    </p>
  ), SpendingBreakdownModels[0]),
  new Step(1979, (
    <p>
      By the late 1970s, Social Security and other entitlement programs - unemployment insurance and food stamps - expanded.
    </p>
  ), SpendingBreakdownModels[1]),
  new Step(1989, (
    <p>
      In the 1980s, rising healthcare prices and enrollment increased Medicare spending.
    </p>
  ), SpendingBreakdownModels[2]),
  new Step(1999, (
    <p>
      After the end of the Cold War, defense spending fell, while Social Security and healthcare programs continued to grow.
    </p>
  ), SpendingBreakdownModels[3]),
  new Step(2009, (
    <p>
      The "War on Terror" reversed the decline in defense spending, but it remained a smaller share of the budget than during the Cold War.
    </p>
  ), SpendingBreakdownModels[4]),
  new Step(2019, (
    <p>
      As of today, Social Security, Medicare, and other healthcare programs (e.g., Medicaid, the ACA) account for more than half of all non-interest spending.
    </p>
  ), SpendingBreakdownModels[5]),
  new Step(2029, (
    <p>
      By 2029, retiring baby boomers will push Medicare and Social Security spending to new heights. The two programs will consume half of all non-interest spending.
    </p>
  ), SpendingBreakdownModels[6]),
  new Step(2039, (
    <p>
      By 2039, two-thirds of non-interest spending will go to just Social Security and health care programs.
    </p>
  ), SpendingBreakdownModels[7]),
  new Step(2049, (
    <p>
      By 2049, Social Security spending will be steady, but healthcare will consume more and more of the budget. In the coming years, these programs will account for nearly two-thirds of it.
    </p>
  ), SpendingBreakdownModels[8]),
]

export default SpendingHistoryModel

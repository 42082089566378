import React from "react"

const FederalDebtLabel = ({year, label, x, y, ...rest}) => {
  return (
    <g {...rest}>
			<text x={x} y={y} alignmentBaseline="middle" textAnchor="middle" dy="1">
        <tspan className="title">{year ? year + " " : ""}Federal Debt&nbsp;&nbsp;</tspan>
        <tspan>{label}</tspan>
      </text>
    </g>
  )
}

export default FederalDebtLabel

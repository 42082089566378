import Point from "../models/point"
import { max } from "lodash"
import Facts from "../../config/facts"

const netDebt = Facts.econ.projectedDebtPercentages
const m = max(netDebt)
const NetDebtPoints = netDebt.map(x => x / m)

function GenerateLines(points) {
  let path = []
  for (let i = 0; i < points.length; i++) {
    const p = points[i]
    path.push(['L', p.x, p.y],)
  }
  return path
}

function PointOnLine(p1, p2, index, total) {
	const xlen = p2.x - p1.x
	const ylen = p2.y - p1.y
	const hlen = Math.sqrt(Math.pow(xlen , 2) + Math.pow(ylen , 2))
	const smallerLen = (hlen / total) * index
	const ratio = smallerLen / hlen
	const smallerXLen = xlen * ratio
	const smallerYLen = ylen * ratio
	const smallerX = p1.x + smallerXLen
	const smallerY = p1.y + smallerYLen
	return new Point(smallerX, smallerY)
}

function ProgressPoint(point, targetPoint, progress) {
	const x = point.x + ((targetPoint.x - point.x) * progress)
	const y = point.y + ((targetPoint.y - point.y) * progress)
	return new Point(x, y)
}

function GeneratePointsForProgress(p1, p2, w, h, data, progress) {
	const total = data.length
  const spacing = w/(total - 1)
	let points = []

	const startPoint = PointOnLine(p1, p2, 0, total)
	const targetStartPoint = new Point(0, h - data[0] * h)
	const progressStartPoint = ProgressPoint(startPoint, targetStartPoint, progress)
	points.push(progressStartPoint)

  for (let i = 1; i < total; i++) {
		const originPoint = PointOnLine(p1, p2, i, total)
		const targetPoint = new Point(i * spacing, h - data[i] * h)
		const progressPoint = ProgressPoint(originPoint, targetPoint, progress)
    points.push(progressPoint)
	}
	
	const endPoint = PointOnLine(p1, p2, total, total)
	const targetEndPoint = new Point(w, h - data[total - 1] * h)
	const progressEndPoint = ProgressPoint(endPoint, targetEndPoint, progress)
	points.push(progressEndPoint)

  return points
}

function SVGSteepSlope(w, h, r, barHeight, progress) {
	// Recreate final state of SVGMeltingCircle, has to stay in sync
	const overlapDistance = h + r - (h - barHeight)
	const p1 = new Point(0, h - barHeight - 0.25 * overlapDistance)
	const p2 = new Point(w, h - barHeight - 0.75 * overlapDistance)

	// Genearate 30 points from this line
	const adjustedPoints = GeneratePointsForProgress(p1, p2, w, h, NetDebtPoints, progress)
  const lines = GenerateLines(adjustedPoints)

	const path = [
		['M', w, h], // bottom right
    ['H', w],
    ['L', 0, h],
		...lines,
		['Z'],
	]

	return {
		commands: path,
	}
}

export default SVGSteepSlope

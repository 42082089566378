import React, { Component } from 'react';
import YouTube from 'react-youtube';
import './video-modal.css';

export default class VideoModal extends Component {
  render() {
    return (
      <div className="VideoModal-wrap">
        {this.props.showVideo && <div className="VideoModal" onClick={this.props.toggleVideo}>
          <YouTube videoId={"G5yhfU4R8NU"} opts={{ playerVars: { autoplay: 1 }}} />
        </div>}
        {this.props.showVideo && <div className="VideoModal-close"></div>}
      </div>
    );
  }
}
import React from "react"

import MedicareLanding from "./reform-medicare-landing"
import WhyIsSpendingRising from "./why-is-spending-rising"
import ReformMedicareStart from "./reform-medicare-start"
import MedicareReformAccordion from "./medicare-reforms-accordion"
import SpendingWhatElse from "../spending-what-else"
import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

const ReformMedicarePage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="cut-medicare-page-wrapper">
			<div id="tour-spending-container">
				<div id="tour-reform-medicare-page">
					<MedicareLanding />
					<WhyIsSpendingRising />
					<MedicareReformAccordion key="medicare-reform-accordion" isMobile={isMobile}/>
					<ReformMedicareStart isMobile={isMobile} nextSection={[
						<SpendingWhatElse key="spending-what-else" isMobile={isMobile}/>
					]}/>
				</div>
			</div>
		</SideMenuWrapper>
	)
}

export default ReformMedicarePage

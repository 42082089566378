import React, { Component } from "react"
import cx from "classnames"
import {round} from "lodash"
import PropTypes from "prop-types"
import remap from "../../../../intro/utils/remap-number"

import "./gdp-graph.css"
import Facts from "../../../../config/facts"

const DesktopBR = ({isMobile}) => {
	if (isMobile) {
		return <span>&nbsp;</span>
	}
	return (<br />)
}

class GDPGraph extends Component {

	static propTypes = {
		isMobile: PropTypes.bool.isRequired,
		gdp: PropTypes.number.isRequired,
		adjustedGdp: PropTypes.number.isRequired,
		debt: PropTypes.number.isRequired,
		adjustedDebt: PropTypes.number.isRequired,
		isHidden: PropTypes.bool,
	}

	formatTrillions = (dollars, isMobile) => {
		const units = isMobile? "T" : " trillion"
		return "$" + round(dollars / 1000000000000, 1) + units
	}

	renderTargetLabel = (targetGdpP, isMobile) => {
		if (targetGdpP <= 100) {
			return <span>Reached:<DesktopBR isMobile={isMobile}/>{targetGdpP}% GDP</span>
		}
	 	return <span>{targetGdpP}% GDP</span>
	}

	render() {
		const isMobile = this.props.isMobile
		const graphClasses = cx({
			"gdp-graph": true,
			"is-mobile": isMobile,
			"is-desktop": !isMobile,
			"is-hidden": this.props.isHidden,
		})

		// Constants
		const gdpProjectedP = this.props.gdp || 100
		const adjustedGdpProjectedP = this.props.adjustedGdp || 100
		const gdpDiff = gdpProjectedP - adjustedGdpProjectedP

		const maxP = 205
		const scaleP = {low: 200 - maxP, high: maxP}

		const topScaleP = scaleP.high - 5
		const middleScaleP = 100

		// Normalize
		const remapper = remap({
			from: { low: scaleP.low, high: scaleP.high},
			to: { low: 0, high: 100 }
		})

		const topScalePNormal = remapper(topScaleP)
		const invTopScalePNormal = 100 - topScalePNormal
		const middleScalePNormal = remapper(middleScaleP)
		const invMiddleScalePNormal = 100 - middleScalePNormal

		const gdpPNormal = remapper(gdpProjectedP)

		const adjustedGdpPNormal = remapper(adjustedGdpProjectedP)
		const invAdjustedGdpNormal = 100 - adjustedGdpPNormal

		// We need to remap diff to get % of the bar itself
		const barRemapper = remap({
			from: { low: 0, high: gdpPNormal },
			to: { low: 0, high: 100 }
		})

		// Hide middle scale label if we get too close to it (desktop)
		const scale100LabelClasses = cx({
			"scale-100": true,
			"label": true,
			"hidden": (Math.abs(invAdjustedGdpNormal - invMiddleScalePNormal) < 3)
		})

		const diffBarP = 100 - barRemapper(adjustedGdpPNormal)
		const targetGdpP = gdpProjectedP - gdpDiff

		// Swap keys on orientation change to avoid animations
		const key = isMobile ? "gdp-graph-mobile" : "gdp-graph-desktop"
		return (
			<div className={graphClasses} key={key}>
				<div className="graph">
					<div className="bar-target" style={isMobile ? {width: `${gdpPNormal}%`} : {height: `${gdpPNormal}%`}}>
						<div className="difference" style={isMobile ? {width: `${diffBarP}%`} : {height: `${diffBarP}%`}}/>
						<div className="label">{Facts.econ.projectedYear} U.S. Debt: {this.formatTrillions(this.props.adjustedDebt, isMobile)}</div>
					</div>
					<div className="scale-100" style={isMobile ? {width: `${middleScalePNormal}%`} : {height: `${middleScalePNormal}%`}}/>
					<div className="scale-195" style={isMobile ? {width: `${topScalePNormal}%`} : {height: `${topScalePNormal}%`}}/>
				</div>
				<div className="legend">
					<div className={scale100LabelClasses} style={isMobile ? {right: `${invMiddleScalePNormal}%`} : {top: `${invMiddleScalePNormal}%`}}>Goal:<DesktopBR isMobile={isMobile}/>{middleScaleP}% GDP</div>
					<div className="scale-195 label" style={isMobile ? {right: `${invTopScalePNormal}%`} : {top: `${invTopScalePNormal}%`}}>{topScaleP}% GDP</div>
					<div className="target label" style={isMobile ? {right: `${invAdjustedGdpNormal}%`} : {top: `${invAdjustedGdpNormal}%`}}>{this.renderTargetLabel(targetGdpP, isMobile)}</div>
				</div>
			</div>
		)
	}
}

export default GDPGraph

import React from "react"
import cx from "classnames"
import ScrollPercentage from "react-scroll-percentage"
import scrollIntoView from "smooth-scroll-into-view-if-needed"
import ScrollIndicatorIcon from "../../../../common/components/scroll-indicator-icon/scroll-indicator-icon"
import "./scroll-indicator.css"

const ScrollIndicatorBar = ({label, isHidden, isDark, onClick}) => {
		const barClasses = cx({
			"scroll-indicator": true,
			"is-hidden": isHidden,
			"is-visible": !isHidden,
			"is-dark": isDark,
		})
		const iconClasses = cx({
			"scroll-indicator-icon": true,
			"should-bounce": !isHidden,
		})
		const labelClasses = cx({
			"scroll-indicator-label": true,
		})
		return (
			<footer className={barClasses} onClick={onClick}>
				<div className={iconClasses}>
					<ScrollIndicatorIcon />
				</div>
				<div className={labelClasses}>
					{label}
				</div>
			</footer>
		)
}

const scrollToNextSection = (e) => {
	// Find the next section and scroll it onto the screen
	// We look and scroll to elements in this order:
	// section .scroll-anchor.scroll-middle
	// section .scroll-anchor
	// section
	// Defaults to scroll to top.
	const tag = "section"
	const sections = [...document.getElementsByTagName(tag)]
	const pSection = e.target.previousSibling
	const i = sections.indexOf(pSection)
	const nSection = sections[i + 1]

	if (nSection) {
		// Look for a middle scroll anchor
		const anchorMiddle = nSection.querySelector(".scroll-anchor.scroll-middle")
		if (anchorMiddle) {
			scrollIntoView(anchorMiddle)
		} else {
			// Look for a top scroll anchor
			const anchorTop = nSection.querySelector(".scroll-anchor")
			if (anchorTop) {
				scrollIntoView(anchorTop, { block: "start", inline: "start" }) 
			} else {
				// Scroll section to top if we don't have an anchor
				scrollIntoView(nSection, { block: "start", inline: "start" })
			}
		}
	}
}

const ScrollIndicatorWrapper = ({label, isDark, onClick, children, ...rest}) => {
	return (
		<ScrollPercentage threshold={0}>
			{({percentage, inView}) => {
				return(
					<div {...rest}>
						{children}
						<ScrollIndicatorBar
							label={label}
							isHidden={percentage < 0.3 || percentage > 0.6}
							isDark={isDark || false}
							onClick={scrollToNextSection}/>
					</div>
				)
			}}
		</ScrollPercentage>
	)
}

export default ScrollIndicatorWrapper

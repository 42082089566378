import Point from "../../../../intro/models/point"
  
function generatePoints(w, h, data) {
  const total = data.length
  const spacing = w/(total - 1)
  let points = []
  for (let i = 0; i < total; i++) {
    points.push(new Point(i * spacing, h - data[i] * h))
  }

  return points
}

function generateLines(points) {
  let path = []
  for (let i = 0; i < points.length; i++) {
    const p = points[i]
    path.push(['L', p.x, p.y],)
  }
  return path
}

function SVGBackgroundGraph(w, h, data) {
  const points = generatePoints(w, h, data)
  const lines = generateLines(points)

  const path = [
		['M', w, h], // bottom right
    ['H', w],
    ['L', 0, h],
    ...lines,
    ['Z'],
	]

	return {
		commands: path,
	}
}

export default SVGBackgroundGraph

import React from "react"

import { PollContainer, PollContainerType } from "../../common/components/poll-container/poll-container"
import PollSection from "../../common/components/poll-container/poll-section"
import WrongAnswerSection from "../../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../../common/components/poll-container/answer-section"
import WhatElse from "../spending-what-else"

import DiscretionarySpendingModel from "../../../config/tour/spending/tour-discretionary-spending-model"
const Model = DiscretionarySpendingModel.CutDiscretionarySpendingPoll

const Poll = (props) => (
	<PollSection isFirstPage {...props}>
		{Model.promptHeadline}
		{Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
        return (
          <React.Fragment>
						{Model.wrongAnswerHeadline(props.options[props.answer])}
						{Model.wrongAnswerBody(props.options[props.answer])}
          </React.Fragment>
        )
			}
		}
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel={Model.answerScrollIndicatorLabel} {...props}>
		{ gotItRight => {
      return (
        <React.Fragment>
					{Model.correctAnswerHeadline(gotItRight, props.options[props.correctAnswer])}
					{Model.correctAnswerBody(props.options[props.correctAnswer])}
        </React.Fragment>
      )
		}}
	</AnswerSection>
)

const DiscretionarySpendingStart = ({isMobile}) => (
	<div key="cut-discretionary-spending-start">
		<PollContainer 
			isMobile={isMobile}
			options={Model.options}
			nominalDebtForOptions={Model.nominalDebtForOptions}
			correctAnswer={Model.answer}
			debtGDPPercentForOptions={Model.GDPPercentForOptions}
			componentForType={ type => {
				switch(type) {
					case PollContainerType.POLL:
						return Poll
					case PollContainerType.WRONGANSWER:
						return WrongAnswer
					case PollContainerType.ANSWER:
						return Answer
					case PollContainerType.WHATELSE:
						return WhatElse
					default:
						throw new Error("Unknown poll container type: " + type);	
				}
			}}
		/>
	</div>
)

export default DiscretionarySpendingStart

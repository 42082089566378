import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let MedicaidReformModel = {}

MedicaidReformModel.LandingSection = {
	headline: (
    <h2>
      How does Medicaid work?
    </h2>
  ),

  body: (
    <React.Fragment>
			<p>
				Medicaid provides grants to pay for the health care of poor Americans, with each state administering <Tooltip value="Spending is divided among the following groups: 33% goes to disabled Americans; 14% to Seniors; 20% to Children; and 33% to Non-Disabled Adults.">its own program</Tooltip>. Disabled citizens, children, seniors, high-cost patients, and more receive federal support, and low and middle-income families are assisted with private plans under the Affordable Care Act.
			</p>
			<p>
				Learn more about the individual proposed reforms below.
			</p>
    </React.Fragment>
  ),

  scrollIndicatorLabel: "Learn more about Medicaid reforms",
}

MedicaidReformModel.ReformsAccordionTitle = "Proposed Medicaid reforms:"

MedicaidReformModel.ReformsAccordionRows = [
	{
		title: "Medicaid block grants",
		body: (
			<p>
        Many Republicans favor changing the method to finance Medicaid with block grants. The size of the grants would based on a state's population and only grow at the rate of inflation. Any state expanding its program over the limit would pay 100% of the added costs. This could reduce the {Facts.econ.projectedYear} debt by as much as 33% of GDP but it would increase the number of uninsured Americans and increase health care costs for states. Debt would exceed 100% of GDP in 2040.
			</p>
		)
	},
	{
		title: "Eliminate ACA subsidies",
		body: (
			<p>
				This reform repeals the ACA provisions directly related to coverage. It wouldn't affect ACA's Medicare cuts but would eliminate most ACA taxes. Repealing all ACA coverage provisions reduces the {Facts.econ.projectedYear} debt by 4% of GDP, but would increase the number of uninsured Americans by several million. Debt would pass 100% of GDP by 2037.
			</p>
		)
	},
]

const cp = Facts.econ.projectedNetDebtGDP * 100
const cpNominal = Facts.econ.projectedNetDebt / Facts.const.trillion

MedicaidReformModel.MedicaidReformPoll = {

	options: ["5%", "18%", "27%", "40%"],

  answer: 3,

  GDPPercentForOptions: [cp, cp, cp, 103.0],

  nominalDebtForOptions: [cpNominal, cpNominal, cpNominal, 69.8],

  // Prompt

  promptHeadline: (
    <h2>
      What if we enacted all proposed Medicaid reforms?
    </h2>
  ),

  promptBody: (
    <p>
      How much would the U.S. debt be reduced by in {Facts.econ.projectedYear}?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Close. However, {answer} is too low.
    </h2>
  ),

  wrongAnswerBody: (answer) => (
    <p>
			Spending on Medicaid is projected to grow dramatically in coming decades.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }Enacting these proposed Medicaid reforms could reduce debt by as much as {answer} of GDP.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
			In reality, this is likely too optimistic as it assumes no increase in Medicaid recipiency. It would also increase the number of uninsured Americans by several million. Debt as a share of GDP would exceed 100% in 2046.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we try cutting?",
}

export default MedicaidReformModel

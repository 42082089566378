
import React from "react"

import Facts from "../../facts" // eslint-disable-line
import Tooltip from "../../../common/components/tooltip/tooltip"// eslint-disable-line

let TourIntroModel = {}

// Splash Section

TourIntroModel.SplashSection = {
  headline: (
    <h1>
      THE BALANCING ACT
    </h1>
  ),

  body: (
    <p>
      Federal debt will exceed <Tooltip value="Debt crossing 100% of GDP means the debt will exceed the size of the nation's economy. These high debt levels will weaken the economy and make us less safe.">100% of GDP</Tooltip> in {Facts.econ.projectedOverflowYear}. Can you save us from this crisis? The challenge is more daunting than you can imagine.
    </p>
  ),

  illustrationAltDescription: "A person pushing a quarter up a teeter-totter",

  scrollIndicatorLabel: "",
}

// Landing Section

TourIntroModel.LandingSection = {
  headline: (
    <h1>
      YOUR OBJECTIVE
    </h1>
  ),

  body: (
    <p>
      Keep the debt below 100% of GDP over the next 30 years.
    </p>
  ),

  scrollIndicatorLabel: "Let's begin",
}

// Start Section

TourIntroModel.StartSection = {
  promptHeadline: (
    <h2>
      What do you think would make the biggest impact?
    </h2>
  ),
}

export default TourIntroModel
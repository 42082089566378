import React from "react"

import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"
import Accordion from "../../common/components/accordion/accordion"

import MedicareReformModel from "../../../config/tour/spending/tour-medicare-reform-model"

const MedicareReformsAccordion = () => {
	return (
    <ScrollIndicatorWrapper 
      id="medicare-reforms-accordion-wrapper"
      label="What if we enacted all proposed reforms?">
      <section 
        id="medicare-reforms-accordion" 
        className="full-page-w-menu row-content v-center">
				<div className="content">
					<h2>{MedicareReformModel.ReformsAccordionTitle}</h2>
					<Accordion 
						className="scroll-anchor scroll-middle"
						rows={MedicareReformModel.ReformsAccordionRows}
					/>
				</div>
			</section>
		</ScrollIndicatorWrapper>
	)
}

export default MedicareReformsAccordion


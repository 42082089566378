import Point from "./point"
import { GridModelLandscape, GridModelPortrait } from "./grid"
import ScrollLayout from "./scroll-layout"
import remap from "../utils/remap-number"

class StepsModel {

	constructor() {
		this.setCanvasSize(0, 0)
		this.setScrollPageOffset(0)
	}

	setEconomicFacts(facts) {
		this.year = facts.year
		this.projectedYear = facts.projectedYear
		this.income = facts.income
		this.spent = facts.spent
		this.deficit = facts.deficit
		this.projectedDeficit = facts.projectedDeficit
		this.econRatio = facts.spent / facts.income
		this.projectedDeficitRatio = facts.projectedDeficit / facts.deficit
		this.netDebtGDP = facts.netDebtGDP
		this.projectedNetDebtGDP = facts.projectedNetDebtGDP

		this.projectedDeficitsTrillions = facts.projectedDeficitsTrillions
		this.projectedDebtTrillions = facts.projectedDebtTrillions
	}

	setCanvasSize(w, h) {
		this.w = w
		this.h = h
		if (w < h) {
			this.grid = new GridModelPortrait(w/2, h, w/2, 0)
			this.isPortrait = true
		} else {
			this.grid = new GridModelLandscape(w/2, h, w/2, 0)
			this.isPortrait = false
		}
		this.isLandscape = !this.isPortrait
		this.scrollLayout = new ScrollLayout(w, h)
	}

	setScrollPageOffset(offset) {
		const { step, progress } = this.scrollLayout.calculateProgress(offset, this.prevPageOffset || 0)
		// We need to keep track of previous page offset to determine direction
		this.prevPageOffset = offset
		this.step = step
		this.progress = progress
	}

	getProgress(step) {
		if (this.step === step) {
			const p = this.progress
			//Round the first percentile to simplify view logic
			if (p < 0.01) {
				return 0.0
			}
			// Round the rest to four decimal places
			// because we need enough detail so the springs
			// don't get a chance to rest
			return Math.round(p * 100000) / 100000
		}

		if (step < this.step) {
			return 1.0
		}

		return 0.0
	}

	getTargetDollarValue() {
		let value = this.spent

		if (this.step >= 3 && this.getProgress(3) > 0) {
			value = this.spent - this.spent * this.getProgress(3)
		}

		if (this.step >= 4 && this.getProgress(4) > 0) {
			value = (this.spent - this.income) * this.getProgress(4)
		}

		return value
	}

	getTargetDeficitDollarValue() {

		const yearRemapper = remap({
			from: { low: 0, high: 3.0 },
			to: { low: this.year + 1, high: this.projectedYear }
		})

		const year = Math.round(yearRemapper(this.getProgress(5) + this.getProgress(6) + this.getProgress(7)))
	
		const i = year - this.year
		const d = this.projectedDeficitsTrillions[i] * 1000000000000

		return d
	}

	getTargetDebtDollarValue() {

		const yearRemapper = remap({
			from: { low: 0, high: 3.0 },
			to: { low: this.year + 1, high: this.projectedYear }
		})

		const year = Math.round(yearRemapper(this.getProgress(5) + this.getProgress(6) + this.getProgress(7)))
	
		const i = year - this.year
		const d = this.projectedDebtTrillions[i] * 1000000000000

		return d
	}

	getTargetDollarColor() {
		let color = "#2E07A9"
		if (this.step >= 4 && this.getProgress(4) > 0) {
			color = "#EC654D"
		}
		return color
	}

	// Util

	pointToRelativePoint(p) {
		return new Point(p.x - this.w/2, p.y - this.h/2)
	}
}

export default StepsModel

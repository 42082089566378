import React, { Component } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Transition } from "react-transition-group";

import HamburgerMenu from "../../tour/common/components/hamburger-menu/hamburger-menu"
import LeftMenu from "../../common/components/main-menu/main-menu-left"
import RightMenu from "../../common/components/main-menu/main-menu-right"

import "../../common/components/main-menu/main-menu.css"
import "./main-menu.css"

class MainMenu extends Component {
	static propTypes = {
		step: PropTypes.number.isRequired,
		progress: PropTypes.number.isRequired,
		isMobile: PropTypes.bool.isRequired,
		isPortrait: PropTypes.bool.isRequired,
	}

	constructor(props) {
		super(props)
		this.unlisten = null
		this.state = {
			showHamburgerMenu: false,
		}
	}

	componentWillUnmount() {
		this.unlisten && this.unlisten()
	}

	handleLogoClick(e) {
		e.preventDefault()
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		})
	}

	isMenuHidden() {
		if (this.state.showHamburgerMenu) {
			return false
		}

		if (this.props.step === 0 ||
				(this.props.step === 1 && this.props.progress < 100) ||
				(this.props.step === 15 && this.props.progress > 80) ||
				(this.props.step >= 16)
			) {
			return true
		}

		return false
	}

	isMenuInverted() {
		if (this.props.step >= 10 && this.props.step < 16) {
			return true
		}
		return false
	}


	isMenuBarWhite() {
		if (this.props.step >= 3 && this.props.step <= 8) {
			return true
		}
		return false
	}

	isMenuBarBlue() {
		if ((this.props.step === 10 && this.props.progress >= 20) ||
				(this.props.step >= 11 && this.props.step < 16))
		{
			return true
		}
		return false
	}

	render() {
		const isInverted = this.isMenuInverted()
		const isHidden = this.isMenuHidden()
		const isBarWhite = this.isMenuBarWhite()
		const isBarBlue = this.isMenuBarBlue()

		const mainMenuClasses = cx({
			"is-mobile": this.props.isMobile,
			"is-desktop": !this.props.isMobile,
			"is-landscape": !this.props.isPortrait,
			"is-portrait": this.props.isPortrait,
			"is-inverted": isInverted,
			"is-hidden": isHidden,
		})

		const mainMenuBarClasses = cx({
			"is-white": isBarWhite,
			"is-blue": isBarBlue,
		})

		const transitionStyles = {
			entered:  { transform: "translateX(0)" },
			exiting: { transform: "translateX(-100%)" },
		};

		return (
			<nav id="main-menu" className={mainMenuClasses}>
				<div id="main-menu-bar" className={mainMenuBarClasses}>
					<LeftMenu
						isMobile={this.props.isMobile}
						isInverted={isInverted}
						onHamburgerClick={this.handleHamburgerClick}
						onLogoClick={this.handleLogoClick}
					/>
					<RightMenu
						isMobile={this.props.isMobile}
						isInverted={isInverted}
					>
						<a href="/tour" className="explore-btn">Tour the Budget</a>
            <a href="https://budgetmatters.americaoffbalance.org">Budget Matters</a>
						<a href="https://calculator.americaoffbalance.org" className="build-btn">Fix the Budget</a>
					</RightMenu>
				</div>

				<Transition
					in={this.state.showHamburgerMenu}
					timeout={200}
					unmountOnExit
				>
					{ state => {
						return (
							<HamburgerMenu
								style={{
									...transitionStyles[state]
								}}
								isMobile={this.props.isMobile}
								onDismissClick={this.handleHamburgerClick}
							/>
						)
					}}
				</Transition>
			</nav>
		)
	}

	handleHamburgerClick = (e) => {
		e.preventDefault()
		const show = !this.state.showHamburgerMenu
		this.setState({
			showHamburgerMenu: show,
		})
	}
}

export default MainMenu

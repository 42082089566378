import React from "react"
import { max } from "lodash"

import Facts from "../../config/facts"
import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"
import BackgroundGraph from "../common/components/background-graph/background-graph"

import "./intro-landing.css"

import TourIntroModel from "../../config/tour/intro/tour-intro-model"
const Copy = TourIntroModel.LandingSection

const netDebt = Facts.econ.projectedDebtPercentages
const m = max(netDebt)
const points = netDebt.map(x => x / m)

const IntroLanding = ({isMobile}) => {
	return (
		<ScrollIndicatorWrapper id="intro-landing-wrapper" label={Copy.scrollIndicatorLabel}>
			<section id="intro-landing" className="full-page-w-menu v-center">
				<div className="content">
					{Copy.headline}
					{Copy.body}
				</div>
				<svg>
					<linearGradient id="flame-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" stopColor="var(--debt-gradient-start-color)"/>
						<stop offset="20%" stopColor="var(--debt-gradient-start-color)"/>
						<stop offset="100%" stopColor="var(--debt-gradient-end-color)"/>
					</linearGradient>
				</svg>
				<BackgroundGraph
					points={points}
					fromYear={Facts.econ.year}
					toYear={Facts.econ.projectedYear}
					isMobile={isMobile}
					toValueLabel={`${Facts.econ.projectedNetDebtGDP * 100}% of GDP`}
					goalValueLabel="Goal: Below 100% GDP"
					hasNoSidebar
				/>
			</section>
		</ScrollIndicatorWrapper>
	)
}

export default IntroLanding

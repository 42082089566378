import React from "react"
import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"

import SocialSecurityReformModel from "../../../config/tour/spending/tour-social-security-reform-model"
const Model = SocialSecurityReformModel.LandingSection

const ReformSocialSecurityLanding = () => (
	<ScrollIndicatorWrapper id="reform-social-security-landing-wrapper" label={Model.scrollIndicatorLabel}>
		<section id="reform-social-security-landing" className="full-page-w-menu is-first-page has-scroll-indicator v-center is-landing">
			<div className="content">
				{Model.headline}
				{Model.body}
			</div>
		</section>
	</ScrollIndicatorWrapper>
)

export default ReformSocialSecurityLanding

import React from "react"

import Landing from "./cut-mandatory-spending-landing"
import Start from "./cut-mandatory-spending-start"
import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

const CutMandatorySpendingPage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="cut-mandatory-spending-page-wrapper">
			<div id="tour-spending-container">
				<div id="tour-cut-mandatory-spending-page">
					<Landing isMobile={isMobile}/>
					<Start isMobile={isMobile}/>
				</div>
			</div>
		</SideMenuWrapper>
	)
}

export default CutMandatorySpendingPage

import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Spring, animated, config } from "react-spring"
import cx from "classnames"

import "./conclusion-section.css"
import ShareOfDebtPNG from "./illustrations/share-of-debt.png"
import LowerWagesPNG from "./illustrations/lower-wages.png"
import SafetyAtRiskPNG from "./illustrations/safety-at-risk.png"
import DireOutlookPNG from "./illustrations/dire-outlook.png"
import HeadInTheSandPNG from "./illustrations/head-in-the-sand.png"
import DigOurWayOutPNG from "./illustrations/dig-our-way-out.png"
import SpendingHardToControlPNG from "./illustrations/spending-hard-to-control.png"

import IntroModel from "../../config/intro/intro-model"
const Copy = IntroModel.ConclusionSection

const NextArrowIcon = () => (
  <div className="next-arrow-icon">
    <svg height="25" viewBox="0 0 40 25" width="40" style={{stroke: "#000"}}>
      <g fill="none" fillRule="evenodd" strokeLinecap="square" strokeWidth="2" transform="matrix(0 -1 1 0 1 23)">
        <path d="m3.10660172 34.1066017h14.99999998v-15" transform="matrix(.70710678 .70710678 -.70710678 .70710678 21.92031 .292893)"/><path d="m10.5.5v34"/>
      </g>
    </svg>
  </div>
)

const FloatingContentWrapper = ({style, children}) => (
  <animated.div className="floating-content-copy-wrapper" style={style}>
    {children}
  </animated.div>
)

const ShareOfDebtCopy = ({style}) => (
  <FloatingContentWrapper style={style}>
    <div className="copy-wrapper">
      {Copy.shareOfDebt.headline}
      {Copy.shareOfDebt.body}
    </div>
  </FloatingContentWrapper>
)

const LowerWagesCopy = ({style}) => (
  <FloatingContentWrapper style={style}>
    <div className="copy-wrapper">
      {Copy.lowerWages.headline}
      {Copy.lowerWages.body}
    </div>
  </FloatingContentWrapper>
)

const SpendingHarderToControl = ({style}) => (
  <FloatingContentWrapper style={style}>
    <div className="copy-wrapper">
      {Copy.spendingHarderToControl.headline}
      {Copy.spendingHarderToControl.body}
    </div>
  </FloatingContentWrapper>
)

const SafetyAtRiskCopy = ({style}) => (
  <FloatingContentWrapper style={style}>
    <div className="copy-wrapper">
      {Copy.safetyAtRisk.headline}
      {Copy.safetyAtRisk.body}
    </div>
  </FloatingContentWrapper>
)

const DireOutlookCopy = ({style}) => (
  <FloatingContentWrapper style={style}>
    <div className="copy-wrapper">
      {Copy.direOutlook.headline}
    </div>
  </FloatingContentWrapper>
)

const HeadInTheSandCopy = ({style}) => (
  <FloatingContentWrapper style={style}>
    <div className="copy-wrapper">
      {Copy.headInTheSand.headline}
      {Copy.headInTheSand.body}
    </div>
  </FloatingContentWrapper>
)

const AnimatedIllustrations = ({step, model}) => {
  const shareOfDebtClasses = cx({
    "illustration-wrapper": true,
    "slide-in": model.getProgress(10) < 0.2,
  })

  const lowerWagesClasses = cx({
    "illustration-wrapper": true,
    "slide-in": (step === 10 && model.getProgress(10) > 0.8) || (step === 11 && model.getProgress(11) < 0.2),
  })

  const safetyAtRiskClasses = cx({
    "illustration-wrapper": true,
    "slide-in": (step === 11 && model.getProgress(11) > 0.8) || (step === 12 && model.getProgress(12) < 0.2),
  })

  const spendingHarderToControlClasses = cx({
    "illustration-wrapper": true,
    "slide-in": (step === 12 && model.getProgress(12) > 0.8) || (step === 13 && model.getProgress(13) < 0.2),
  })

  const direOutlookClasses = cx({
    "illustration-wrapper": true,
    "slide-in": (step === 13 && model.getProgress(13) > 0.8) || (step === 14 && model.getProgress(14) < 0.2),
  })

  const headInTheSandClasses = cx({
    "illustration-wrapper": true,
    "slide-in": model.getProgress(14) > 0.8,
  })

  return (
    [
      <div key="share-of-debt-illustration" className={shareOfDebtClasses}>
        <img src={ShareOfDebtPNG} alt={Copy.shareOfDebt.illustrationAltDescription} />
      </div>,
      <div key="lower-wages-illustration" className={lowerWagesClasses}>
        <img src={LowerWagesPNG} alt={Copy.lowerWages.illustrationAltDescription} />
      </div>,
      <div key="safety-at-risk-illustration" className={safetyAtRiskClasses}>
        <img src={SafetyAtRiskPNG} alt={Copy.safetyAtRisk.illustrationAltDescription} />
      </div>,
      <div key="spending-harder-to-control-illustration" className={spendingHarderToControlClasses}>
        <img src={SpendingHardToControlPNG} alt={Copy.spendingHarderToControl.illustrationAltDescription} />
      </div>,
      <div key="dire-outlook-illustration" className={direOutlookClasses}>
        <img src={DireOutlookPNG} alt={Copy.direOutlook.illustrationAltDescription} />
      </div>,
      <div key="head-in-the-sand-illustration" className={headInTheSandClasses}>
        <img src={HeadInTheSandPNG} alt={Copy.headInTheSand.illustrationAltDescription} />
      </div>
    ]
  )
}

class ConclusionContent extends React.Component {

  static propTypes = {
    w: PropTypes.number,
    h: PropTypes.number,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    model: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    style: PropTypes.object,
  }

  render() {
		if (!this.props.w) {
			return null
    }

    if (this.props.step < 9) {
      return <div id="conclusion-content" key="conclusion-content" style={this.props.style} />
    }

    return (
      <div id="conclusion-content" key="conclusion-content" style={this.props.style}>
        <div className="split-layout">
          <main className="split-layout-column">
            <div className="split-layout-vcenter-container">
            </div>
          </main>
          <aside className="split-layout-column">
            <div className="split-layout-vcenter-container">
              <div className="illustration-wrapper">
              </div>
            </div>
          </aside>
        </div>

        <div id="conclusion-illustration-fixed">
          <AnimatedIllustrations step={this.props.step} model={this.props.model} />
        </div>

        <div id="conclusion-content-fixed">
          { this.props.step <= 10 &&
            [
              <Spring
                native
                immediate={true}
                key="share-of-debt-spring"
                from={{
                  opacity: 1,
                  top: 0,
                }}
                to={{
                  opacity: 1 - this.props.model.getProgress(10),
                  top: 0 - this.props.model.getProgress(10) * this.props.h/2,
                }}
                config={config.default}>
                { style => (
                  <ShareOfDebtCopy style={style}/>
                )}
              </Spring>,
              <Spring
                native
                immediate={true}
                key="lower-wages-spring"
                from={{
                  opacity: 0,
                  top: this.props.h/2,
                }}
                to={{
                  opacity: this.props.model.getProgress(10),
                  top: this.props.h/2 - this.props.model.getProgress(10) * this.props.h/2,
                }}
                config={config.default}>
                { style => (
                  <LowerWagesCopy style={style}/>
                )}
              </Spring>
            ]
        }

        { this.props.step === 11 &&
          [
            <Spring
              native
              immediate={true}
              key="lower-wages-spring"
              from={{
                opacity: 1,
                top: 0,
              }}
              to={{
                opacity: 1 - this.props.model.getProgress(11),
                top: 0 - this.props.model.getProgress(11) * this.props.h/2,
              }}
              config={config.default}>
              { style => (
                <LowerWagesCopy style={style}/>
              )}
            </Spring>,
            <Spring
              native
              immediate={true}
              key="safety-at-risk-spring"
              from={{
                opacity: 0,
                top: this.props.h/2,
              }}
              to={{
                opacity: this.props.model.getProgress(11),
                top: this.props.h/2 - this.props.model.getProgress(11) * this.props.h/2,
              }}
              config={config.default}>
              { style => (
                <SafetyAtRiskCopy style={style}/>
              )}
            </Spring>
          ]
        }

        { this.props.step === 12 &&
          [
            <Spring
              native
              immediate={true}
              key="safety-at-risk-spring"
              from={{
                opacity: 1,
                top: 0,
              }}
              to={{
                opacity: 1 - this.props.model.getProgress(12),
                top: 0 - this.props.model.getProgress(12) * this.props.h/2,
              }}
              config={config.default}>
              { style => (
                <SafetyAtRiskCopy style={style}/>
              )}
            </Spring>,
            <Spring
              native
              immediate={true}
              key="spending-harder-to-control-spring"
              from={{
                opacity: 0,
                top: this.props.h/2,
              }}
              to={{
                opacity: this.props.model.getProgress(12),
                top: this.props.h/2 - this.props.model.getProgress(12) * this.props.h/2,
              }}
              config={config.default}>
              { style => (
                <SpendingHarderToControl style={style}/>
              )}
            </Spring>
          ]
        }

        { this.props.step === 13 &&
          [
            <Spring
              native
              immediate={true}
              key="spending-harder-to-control-spring"
              from={{
                opacity: 1,
                top: 0,
              }}
              to={{
                opacity: 1 - this.props.model.getProgress(13),
                top: 0 - this.props.model.getProgress(13) * this.props.h/2,
              }}
              config={config.default}>
              { style => (
                <SpendingHarderToControl style={style}/>
              )}
            </Spring>,
            <Spring
              native
              immediate={true}
              key="dire-outlook-spring"
              from={{
                opacity: 0,
                top: this.props.h/2,
              }}
              to={{
                opacity: this.props.model.getProgress(13),
                top: this.props.h/2 - this.props.model.getProgress(13) * this.props.h/2,
              }}
              config={config.default}>
              { style => (
                <DireOutlookCopy style={style}/>
              )}
            </Spring>
          ]
        }

        { this.props.step >= 14 &&
          [
            <Spring
              native
              immediate={true}
              key="dire-outlook-spring"
              from={{
                opacity: 1,
                top: 0,
              }}
              to={{
                opacity: 1 - this.props.model.getProgress(14),
                top: 0 - this.props.model.getProgress(14) * this.props.h/2,
              }}
              config={config.default}>
              { style => (
                <DireOutlookCopy style={style}/>
              )}
            </Spring>,
            <Spring
              native
              immediate={true}
              key="head-in-the-sand-spring"
              from={{
                opacity: 0,
                top: this.props.h/2,
              }}
              to={{
                opacity: this.props.model.getProgress(14),
                top: this.props.h/2 - this.props.model.getProgress(14) * this.props.h/2,
              }}
              config={config.default}>
              { style => (
                <HeadInTheSandCopy style={style}/>
              )}
            </Spring>
          ]
        }
      </div>
    </div>
    )
  }
}

class ConclusionSection extends React.Component {

	layoutStyle = (step) => {
    const l = this.props.model.scrollLayout.layouts[step]
		if (!l) {
			return null
		}
		return { height: l.h, marginBottom: l.bP }
	}

  render() {
    return (
      <React.Fragment>
        <section id="conclusion-section">
          <ConclusionContent {...this.props} id="part-10" className="part-10-handler" style={this.layoutStyle(10)} />

          <article id="part-11" className="part-11-handler" style={this.layoutStyle(11)}>
          </article>

          <article id="part-12" className="part-12-handler" style={this.layoutStyle(12)}>
          </article>

          <article id="part-13" className="part-13-handler" style={this.layoutStyle(13)}>
          </article>

          <article id="part-14" className="part-14-handler" style={this.layoutStyle(14)} >
          </article>

          <article id="part-15" className="part-15-handler" style={this.layoutStyle(15)} >
          </article>
        </section>

        <article id="part-16" className="part-16-handler" >
          <div className="top">
            <div className="copy">
              <h1>Things need to change, and fast</h1>
              <p>
                Politicians need to be held accountable. We need to take charge of our future. To better understand the issue, examine the ways that trade-offs of spending cuts, tax increases, and growing the economy - among many other policy changes - could help reduce the debt over the next 30 years. It will be eye-opening and tough, but it is necessary.
              </p>
              <button className="PlayVideo" onClick={this.props.toggleVideo}>
                <div className="PlayIcon" /> Watch the video
              </button>
            </div>
            <div className="dig-illustration-wrapper">
              <div className="filler" />
              <img src={DigOurWayOutPNG} alt="People filling a hole in the shape of a down arrow" />
            </div>
          </div>
          <nav className="bottom-menu">
              <Link to="/tour" className="nav-btn tour">
                <div className="btn-copy">
                  {Copy.tourButton.headline}
                  {Copy.tourButton.body}
                  <div className="arrow-label-wrapper">
                    <div className="label">{Copy.tourButton.title}</div><NextArrowIcon />
                  </div>
                </div>
              </Link>
            <a href="https://calculator.americaoffbalance.org" className="nav-btn calculator">
              <div className="btn-copy">
                {Copy.calculatorButton.headline}
                {Copy.calculatorButton.body}
                <div className="arrow-label-wrapper">
                    <div className="label">{Copy.calculatorButton.title}</div><NextArrowIcon />
                  </div>
              </div>
            </a>
          </nav>
        </article>
      </React.Fragment>
    )
  }
}
export default ConclusionSection
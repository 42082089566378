import React from "react"
import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"

import "./why-is-spending-rising.css"

const WhyIsSpendingRising = () => (
  <ScrollIndicatorWrapper 
    id="why-is-social-security-spending-rising-wrapper" 
    label="Learn more about Social Security reforms">
    <section 
      id="why-is-social-security-spending-rising" 
      className="full-page has-scroll-indicator v-center">
			<div>
				<h2>Why is Social Security spending rising?</h2>
				<p>
          The number of Americans collecting Social Security is quickly rising as baby boomers reach retirement age. Seniors are also living longer, which means they are collecting more benefits. In addition to demographic changes, average benefits are on the rise too. Current recipients receive annual cost-of-living-adjustments (COLAs) to offset inflation. New retirees receive larger benefits (even after adjusting for inflation) than previous retirees since their initial benefits are linked to overall wage growth.
        </p>
        <p>
          Learn more about the individual proposed reforms below.
        </p>
			</div>
		</section>
	</ScrollIndicatorWrapper>
)
export default WhyIsSpendingRising

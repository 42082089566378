import React from "react"

const RotateDeviceIconSVG = () => (
  <svg preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 100 125">
    <g>
      <path fill="#fff" stroke="none" d="M 65.65 21.55 Q 65.3 21.2 64.8 21.2 64.3 21.2 63.95 21.55 L 60.2 25.3 Q 60.15 25.35 60.1 25.4 59.85 25.7 59.85 26.15 59.85 26.2 59.85 26.25 L 59.85 26.35 Q 59.95 26.75 60.2 27 60.25 27.05 60.25 27.1 60.3 27.1 60.35 27.15 L 63.95 30.75 Q 64.35 31.1 64.8 31.1 65.3 31.1 65.65 30.75 66 30.35 66 29.9 66 29.4 65.6 29.05 L 63.85 27.3 Q 64.15 27.3 64.4 27.3 68.6 27.6 71.45 30.1 L 71.45 30.15 Q 75.35 33.4 75.15 38.85 75.15 39.35 75.45 39.7 75.8 40.05 76.3 40.1 76.8 40.1 77.15 39.8 77.5 39.45 77.55 38.95 77.85 32.3 73.05 28.3 L 73 28.3 Q 69.6 25.3 64.6 24.9 L 64.55 24.9 Q 64.3 24.9 64 24.9 L 65.65 23.25 Q 66 22.9 66 22.4 66 21.9 65.65 21.55 M 84.6 49.45 Q 84.6 45.85 82.75 44.1 81 42.25 77.4 42.25 L 57.75 42.25 57.75 22.6 Q 57.75 15.4 50.55 15.4 L 30.1 15.4 Q 22.9 15.4 22.9 22.6 L 22.9 42.25 22.6 42.25 Q 15.4 42.25 15.4 49.45 L 15.4 69.9 Q 15.4 77.1 22.6 77.1 L 22.9 77.1 22.9 77.4 Q 22.9 84.6 30.1 84.6 L 50.55 84.6 Q 57.75 84.6 57.75 77.4 L 57.75 77.1 77.4 77.1 Q 84.6 77.1 84.6 69.9 L 84.6 49.45 M 81.05 45.8 Q 82.2 47.05 82.2 49.45 L 82.2 69.9 Q 82.2 74.7 77.4 74.7 L 57.75 74.7 57.75 44.65 77.4 44.65 Q 79.8 44.65 81.05 45.8 M 50.55 17.8 Q 55.35 17.8 55.35 22.6 L 55.35 42.25 25.3 42.25 25.3 22.6 Q 25.3 17.8 30.1 17.8 L 50.55 17.8 M 17.8 49.45 Q 17.8 44.65 22.6 44.65 L 22.9 44.65 22.9 74.7 22.6 74.7 Q 17.8 74.7 17.8 69.9 L 17.8 49.45 M 30.1 82.2 Q 25.3 82.2 25.3 77.4 L 25.3 77.1 55.35 77.1 55.35 77.4 Q 55.35 82.2 50.55 82.2 L 30.1 82.2 M 25.3 44.65 L 55.35 44.65 55.35 74.7 25.3 74.7 25.3 44.65 Z"/>
    </g>
  </svg>
)

export default RotateDeviceIconSVG

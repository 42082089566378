import React from "react"

const ScrollIndicatorIconSVG = (props) => (
	<svg viewBox="0 0 23 37" {...props}>
		<g fill="none" fillRule="evenodd" strokeLinecap="square" strokeWidth="2" transform="translate(2 1)">
			<path d="m2.82418338 31.0060016h13.63636362v-13.6363637" transform="matrix(.70710678 .70710678 -.70710678 .70710678 19.927555 .266267)"/>
			<path d="m9.54545455.45454546v30.90909094"/>
		</g>
	</svg>
)

export default ScrollIndicatorIconSVG
import React from "react"

import { PollContainer, PollContainerType } from "../../common/components/poll-container/poll-container"
import PollSection from "../../common/components/poll-container/poll-section"
import WrongAnswerSection from "../../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../../common/components/poll-container/answer-section"

import Model from "../../../config/tour/spending/tour-cut-most-spending-model"

const Poll = (props) => (
	<PollSection isFirstPage {...props}>
		{Model.promptHeadline}
		{Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
        if (notEnough) {
          return (
            <div>
							{Model.wrongAnswerTooLittleHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooLittleBody(props.options[props.answer])}
            </div>
          )
        } else {
          return (
            <div>
							{Model.wrongAnswerTooMuchHeadline(props.options[props.answer])}
							{Model.wrongAnswerTooMuchBody(props.options[props.answer])}
            </div>
          )
        }
			}
	  }
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel={Model.answerScrollIndicatorLabel} {...props}>
		{ gotItRight => (
			<div>
				{Model.correctAnswerPromptHeadline(gotItRight, props.options[props.correctAnswer])}
				{Model.correctAnswerPromptBody(props.options[props.correctAnswer])}
			</div>
		)}
	</AnswerSection>
)

const CutMostSpendingStart = ({isMobile, nextSection}) => (
	<div key="cut-most-spending-page">
		<PollContainer 
			isMobile={isMobile}
			options={Model.options}
			nominalDebtForOptions={Model.nominalDebtForOptions}
			correctAnswer={Model.answer}
			debtGDPPercentForOptions={Model.GDPPercentForOptions}
			componentForType={ type => {
				switch(type) {
					case PollContainerType.POLL:
						return Poll
					case PollContainerType.WRONGANSWER:
						return WrongAnswer
					case PollContainerType.ANSWER:
						return Answer
					case PollContainerType.WHATELSE:
						return null
					default:
						throw new Error("Unknown poll container type: " + type);	
				}
      }}
      nextSection={nextSection}
		/>
	</div>
)

export default CutMostSpendingStart

// The root of the tour

import React, { Component } from "react"
import MediaQuery from "react-responsive"
import { Switch, Route, Redirect } from "react-router-dom"
import { withRouter } from "react-router-dom"
import cx from "classnames"
import ReactTooltip from "react-tooltip"
import { throttle } from "lodash"
import innerHeight from "ios-inner-height"

import Menu from "./common/components/main-menu/main-menu"
import IntroPage from "./intro/intro-page"
import ConclusionPage from "./conclusion/conclusion-page"

// Taxes
import TaxesPage from "./taxes/taxes-page"
import TaxTop25Page from "./taxes/tax-top-25/tax-top-25-page"
import TaxTop1Page from "./taxes/tax-top-1/tax-top-1-page"
import TaxCorporationsPage from "./taxes/tax-corporations/tax-corporations-page"
import CloseLoopholesPage from "./taxes/close-loopholes/close-loopholes-page"

// Spending
import SpendingPage from "./spending/spending-page"
import CutDefensePage from "./spending/cut-defense/cut-defense-page"
import CutEpaPage from "./spending/cut-epa/cut-epa-page"
import CutForeignAidPage from "./spending/cut-foreign-aid/cut-foreign-aid-page"
import CutAmtrakPage from "./spending/cut-amtrak/cut-amtrak-page"
import ReformSocialSecurityPage from "./spending/reform-social-security/reform-social-security-page"
import ReformMedicaidPage from "./spending/reform-medicaid/reform-medicaid-page"
import ReformMedicarePage from "./spending/reform-medicare/reform-medicare-page"
import CutMandatorySpendingPage from "./spending/cut-mandatory-spending/cut-mandatory-spending-page"
import CutDiscretionarySpendingPage from "./spending/cut-discretionary-spending/cut-discretionary-spending-page"

// Economy
import EconomyPage from "./economy/economy-page"
import TaxReformPage from "./economy/tax-reform/tax-reform-page"
import RegulatoryReformPage from "./economy/regulatory-reform/regulatory-reform-page"
import InfrastructurePage from "./economy/infrastructure/infrastructure-page"
import IncreaseImmigrationPage from "./economy/increase-immigration/increase-immigration-page"
import EntitlementReformPage from "./economy/entitlement-reform/entitlement-reform-page"

import "./common/css/common.css"
import "./tour-page.css"

import Const from "../config/constants"

class TourPage extends Component {

	constructor(props) {
		super(props);

		this.unlisten = null

		this.state = {
			isPortrait: undefined,
		}

		window.historyStack = []
	}

	handleHistoryEvent(pathName) {
		window.historyStack.push(pathName)
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize)
		this.handleHistoryEvent(this.props.location.pathname)
		window.scrollTo(0, 0)

		this.updateDimensions()

		this.unlisten = this.props.history.listen((location, action) => {
			if (action === "PUSH") {
				window.scrollTo(0, 0)
				this.handleHistoryEvent(location.pathname)
			}
		})

		window.addEventListener("keydown", this.handleKeyDown)
	}

	componentDidUpdate() {
		ReactTooltip.rebuild()
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize)
		window.removeEventListener("keydown", this.handleKeyDown)
		this.unlisten && this.unlisten()
	}

	handleResize = throttle(() => {
		this.updateDimensions()
	}, 20)

	updateDimensions = (callback) => {

		const w = window.innerWidth
		const h = innerHeight()
		const isPortrait = w < h ? true : false

		if (isPortrait !== this.state.isPortrait) {
			this.setState({
				isPortrait: isPortrait,
			}, callback)
		}
	}

	handleKeyDown = (e) => {
		if (e.code === "ArrowLeft") {
			e.preventDefault()
			this.scrollToPreviousStep()
		} else if (e.code === "ArrowRight") {
			e.preventDefault()
			this.scrollToNextStep()
		}
	}

	checkVisible(elm) {
		const rect = elm.getBoundingClientRect();
		const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
		return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
	}

	getClosestScrollIndicatorDown(elems) {
		for(let i = 0; i < elems.length; i++) {
			const el = elems[i]
			const rect = el.getBoundingClientRect();
			if(rect.top >= 0) {
				return el
			}
		}
	}

	getClosestScrollIndicatorUp(elems) {
		for (let i = elems.length - 1; i >= 0; i--) {
			const el = elems[i]
			const rect = el.getBoundingClientRect();
			if (rect.top <= 0) {
					const prevEl = elems[i - 1]
					if (prevEl) {
						return prevEl
					}
			}
		}
	}

	scrollToPreviousStep() {
		const indicators = Array.from(document.querySelectorAll(".scroll-indicator"))
		const prevIndicator = this.getClosestScrollIndicatorUp(indicators)
		if (prevIndicator) {
			prevIndicator.click();
		} else {
			// scroll to document top
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			})
		}
	}

	scrollToNextStep() {
		const visibleIndicator = document.querySelector(".scroll-indicator.is-visible")
		if (visibleIndicator) {
			visibleIndicator.click()
		} else {
			const indicators = Array.from(document.querySelectorAll(".scroll-indicator"))
			const nextIndicator = this.getClosestScrollIndicatorDown(indicators)
			if (nextIndicator) {
				nextIndicator.click();
			}
		}
	}

	render() {
		return (
			<MediaQuery minWidth={Const.MOBILE_BREAKPOINT}>
				{(matches) => {
					const isMobile = !!!matches
					const tourClasses = cx({
						"is-portrait": this.state.isPortrait,
						"is-landscape": !this.state.isPortrait,
						"is-mobile": isMobile,
						"is-desktop": !isMobile,
					})
					return (
						<Route render={({ location }) => (
							<div id="tour" className={tourClasses}>
								<Menu isMobile={isMobile} history={this.props.history} location={location}/>
								<Switch location={location}>
									<Route exact path="/tour" render={ props => <IntroPage {...props} toggleVideo={this.props.toggleVideo} showVideo={this.props.showVideo} isMobile={isMobile} />} />
									<Route exact path="/tour/a-way-forward" render={ props => ConclusionPage({ ...props, isMobile })}/>

									<Route exact path="/tour/taxes" render={ props => TaxesPage({ ...props, isMobile })}/>
									<Route exact path="/tour/taxes/tax-top-25" render={ props => <TaxTop25Page isMobile={isMobile} {...props} /> }/>
									<Route exact path="/tour/taxes/tax-top-1" render={ props => <TaxTop1Page isMobile={isMobile} {...props} /> }/>
									<Route exact path="/tour/taxes/tax-corporations" render={ props => <TaxCorporationsPage isMobile={isMobile} {...props} /> }/>
									<Route exact path="/tour/taxes/close-loopholes" render={ props => CloseLoopholesPage({ ...props, isMobile })}/>

									<Route exact path="/tour/spending" render={ props => SpendingPage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/cut-discretionary-spending" render={ props => CutDiscretionarySpendingPage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/cut-defense" render={ props => CutDefensePage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/cut-foreign-aid" render={ props => CutForeignAidPage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/cut-epa" render={ props => CutEpaPage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/cut-amtrak" render={ props => CutAmtrakPage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/cut-mandatory-spending" render={ props => CutMandatorySpendingPage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/reform-social-security" render={ props => ReformSocialSecurityPage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/reform-medicaid" render={ props => ReformMedicaidPage({ ...props, isMobile })}/>
									<Route exact path="/tour/spending/reform-medicare" render={ props => ReformMedicarePage({ ...props, isMobile })}/>

									<Route exact path="/tour/economy" render={ props => EconomyPage({ ...props, isMobile })}/>
									<Route exact path="/tour/economy/tax-reform" render={ props => TaxReformPage({ ...props, isMobile })}/>
									<Route exact path="/tour/economy/regulatory-reform" render={ props => RegulatoryReformPage({ ...props, isMobile })}/>
									<Route exact path="/tour/economy/entitlement-reform" render={ props => EntitlementReformPage({ ...props, isMobile })}/>
									<Route exact path="/tour/economy/increase-immigration" render={ props => IncreaseImmigrationPage({ ...props, isMobile })}/>
									<Route exact path="/tour/economy/infrastructure" render={ props => InfrastructurePage({ ...props, isMobile })}/>

									<Route render={() => <Redirect to="/tour" />} />
								</Switch>
								<ReactTooltip className="tooltip-bubble" effect="float" multiline border offset={{top: "20px"}}/>
							</div>
						)}/>
					)
				}}
			</MediaQuery>
		)
	}
}

export default withRouter(TourPage)

// Let EPS (epsilon) be a small value
const EPS = 0.0000001;

// Define a line with the equation: ax + by = c
export class Line {
	constructor(a, b, c) {
		this.a = a
		this.b = b
		this.c = c

		// Normalize line for good measure
		this._normalize()
	}

	_normalize() {
		if (Math.abs(this.b) < EPS) {
			this.c /= this.a; this.a = 1; this.b = 0;
		} else { 
			this.a = (Math.abs(this.a) < EPS) ? 0 : this.a / this.b;
			this.c /= this.b; this.b = 1; 
		}
	}
}

export class LineSegment {
	constructor(p1, p2) {
		var d = Math.sqrt((p1.x-p2.x)*(p1.x-p2.x) + (p1.y-p2.y)*(p1.y-p2.y));
		if (d < EPS) {
			throw new Error("A point is not a line segment");
		}
		this.x1 = p1.x
		this.y1 = p1.y
		this.x2 = p2.x
		this.y2 = p2.y
	}
}

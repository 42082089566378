
import React from "react"

import Facts from "../../facts" // eslint-disable-line
import Tooltip from "../../../common/components/tooltip/tooltip"// eslint-disable-line
//import Format from "../../../common/utils/format"

let TourConclusionModel = {}

// Landing Section

TourConclusionModel.LandingSection = {
  headline: (
    <h1>
      Is debt our destiny?
    </h1>
  ),

  body: (
    <React.Fragment>
      <p>
        Increasing taxes might have seemed like a good choice, but would require middle-class tax hikes. If we tried limiting the tax to high-income earners we would need very harsh tax rates that would likely harm the economy.
      </p>
      <p>
        While cutting spending was easy when we only considered unpopular programs, the magnitude of the debt crisis means beloved programs like defense and Social Security must also be cut.
      </p>
      <p>
        Increasing growth appeared to be a free lunch, but every economic policy has trade-offs and the reforms likely wouldn't be sufficient to stop increasing the debt.
      </p>
      <p>
        Keeping debt below 100% of the GDP is likely too much to ask, but there are actions we can take to better protect future generations...
      </p>
    </React.Fragment>
  ),

  illustrationAltDescription: "A person's hand attemts to pull a card from a carefully stacked deck tower",

  scrollIndicatorLabel: "A way forward",
}

// A Way Forward Section

TourConclusionModel.AWayForwardSection = {
  headline: (
    <React.Fragment>
      <h1>
        A way forward
      </h1>
      <h2>
        By now you get it, this is complicated! But seriously, what is the solution?
      </h2>
    </React.Fragment>
  ),

  body: (
    <p>
      There isn't one thing that can solve the debt crisis. There are, however, smaller changes and trade-offs we can make in order to shift the needle in the right direction. Like any budget, we need to prioritize.
    </p>
  ),

  illustrationAltDescription: "A person pushing a quarter up a teeter-totter",

  scrollIndicatorLabel: "Don't make it worse",
}

// Don't Make It Worse Section

TourConclusionModel.DontMakeItWorseSection = {
  headline: (
    <h1>
      Don't make it worse
    </h1>
  ),

  body: (
    <p>
			Expanding or creating new entitlements (e.g., universal Medicare, government-funded family leave) will compound an already dire situation. Large cuts in revenue (e.g., across-the-board cuts in taxes, increased child tax credits) will, likewise, expedite the crisis.
    </p>
  ),

  illustrationAltDescription: "A person holding a pitcher covered in the American flag covers it with their other hand to prevent another pitcher labeled with a red arrow from spilling in",

  scrollIndicatorLabel: "Grow the economy",
}

// Grow the Economy Section

TourConclusionModel.GrowTheEconomySection = {
  headline: (
    <h1>
      Grow the economy
    </h1>
  ),

  body: (
    <p>
			The nation's debt is more manageable the faster the economy grows. That means exploring tax reforms that lower marginal rates, expanding immigration, and reforming the regulatory state. These reforms won't be sufficient, but they are necessary.
    </p>
  ),

  illustrationAltDescription: "A person blowing a soap bubble in the shape of a bag of money labeled with a dollar sign",

  scrollIndicatorLabel: "Reform entitlement programs",
}

// Reform Entitlement Programs Section

TourConclusionModel.ReformEntitlementProgramsSection = {
  headline: (
    <h1>
			Reform entitlement programs
    </h1>
  ),

  body: (
    <p>
			With more than half of future spending dedicated to Social Security and federal health care programs, it is impossible to solve the debt crisis without reforms to these programs.
    </p>
  ),

  illustrationAltDescription: "A loupe burning a check for $5000 made out to you from Uncle Sam",

  scrollIndicatorLabel: "Start reforms now",
}

// Start Reforms Now Section

TourConclusionModel.StartReformsNowSection = {
  headline: (
    <h1>
			Start reforms now
    </h1>
  ),

  body: (
    <p>
	  	The longer we wait, the more painful reforms must be. Tax increases will need to be larger. Spending cuts will be more draconian. And the larger debt will make the economy even weaker.
    </p>
  ),

  illustrationAltDescription: "A person closing a tap in the shape of the US Capitol building",

  scrollIndicatorLabel: "Fix the Budget",
}

// Calculator Landing Section
// Note: this must stay in sync with the calculator!

TourConclusionModel.CalculatorLandingSection = {
  headline: (
    <h1>
			THE BUDGET CALCULATOR
    </h1>
  ),

  body: (
    <React.Fragment>
      <p>
        By {Facts.econ.projectedYear}, we're on pace to have a ${Math.round(Facts.econ.projectedNetDebt / Facts.const.trillion)} trillion federal debt that exceeds 150% of the nation's GDP. As a share of the economy, federal spending will be close to 30% and annual deficits will approach 10%.
      </p>
      <p>
        Can you balance the budget and save us from the debt crisis? Great ready to make some hard trade-offs.
      </p>
    </React.Fragment>
  ),

  buttonLabel: "Create your budget",

  illustrationAltDescription: "US Bald Eagle with a calculator in it's chest",
}


export default TourConclusionModel
import React from "react"

import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

import IllustrationSidebarWrapper from "../illustration-sidebar-wrapper"
import WhatElseMenu from "../economy-what-else-menu"
import InfrastructurePNG from "./illustrations/14.2.png"

import TourEconomyModel from "../../../config/tour/economy/tour-economy"
const Model = TourEconomyModel.RepairInfrastructure

const InfrastructurePage = ({isMobile}) => {
	return (
    <SideMenuWrapper key="infrastructure-page-wrapper">
      <IllustrationSidebarWrapper key="infrastructure-wrapper" src={InfrastructurePNG} alt={Model.illustrationAltDescription} isMobile={isMobile}>
        <div id="tour-economy-container">
          <div className="full-page-w-menu is-first-page row-content v-center">
            <div className="content">
              {Model.headline}
              {Model.body}
              <WhatElseMenu isMobile={isMobile}/>
            </div>
          </div>
        </div>
      </IllustrationSidebarWrapper>
    </SideMenuWrapper>
	)
}

export default InfrastructurePage

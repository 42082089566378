import React from "react"
import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"

import DiscretionarySpendingModel from "../../../config/tour/spending/tour-discretionary-spending-model"
const Model = DiscretionarySpendingModel.LandingSection

const CutDiscretionarySpendingLanding = ({isMobile}) => (
	<ScrollIndicatorWrapper id="cut-discretionary-spending-landing-wrapper" label={Model.scrollIndicatorLabel}>
		<section id="cut-discretionary-spending-landing" className="full-page-w-menu is-first-page has-scroll-indicator v-center is-landing">
			<div className="content">
				{Model.headline}
				{Model.body}
			</div>
		</section>
	</ScrollIndicatorWrapper>
)
export default CutDiscretionarySpendingLanding

import React from "react"
import cx from "classnames"

import HamburgerIconSVG from "./hamburger-icon-svg"
import Logo from "../logo/dynamic-logo-svg"

import "./main-menu-left.css"

const MainMenuLeft = ({isMobile, isInverted, onHamburgerClick, onLogoClick}) => {
	const leftClasses = cx({
		"left": true,
		"is-mobile": isMobile,
		"is-desktop": !isMobile,
		"is-inverted": isInverted,
	})

	return (
		<div className={leftClasses}>
			<a href="#menu" className="hamburger-btn" onClick={onHamburgerClick}>
				{HamburgerIconSVG()}
			</a>
			<a href="/" onClick={onLogoClick} id="main-logo"><Logo /></a>
		</div>
	)
}

export default MainMenuLeft

import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars
import Format from "../../../common/utils/format"

const cp = Facts.econ.projectedNetDebtGDP * 100
const cpNominal = Facts.econ.projectedNetDebt / Facts.const.trillion

const CutAmtrak = {
  options: ["0.3%", "1.2%", "5.0%", "7.2%"],

  answer: 0,

  GDPPercentForOptions: [142.9, cp, cp, cp],

  nominalDebtForOptions: [94.4, cpNominal, cpNominal, cpNominal],

  // Prompt

  promptHeadline: (
    <h2>
      Cut Amtrak
    </h2>
  ),

  promptBody: (
    <p>
    	As a share of GDP, how much lower would debt be in {Facts.econ.projectedYear} if we ended all Amtrak subsidies?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Nope. Too high.
    </h2>
  ),

  wrongAnswerBody: (answer) => (
    <p>
      The reality is that eliminating Amtrak subsidies barely makes a blip.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }Cutting all Amtrak subsidies would only reduce {Facts.econ.projectedYear} debt by {answer} of GDP.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
      At {Format(Facts.econ.amtrakBudget)} in {Facts.econ.year}, Amtrak was a tiny share of the budget. Debt as a share of GDP would exceed 100% in 2034.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default CutAmtrak

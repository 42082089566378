
import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let TourTaxTop25Model = {}

const YearDebtExceedsGDP = [2036, 2040, 2045, 2050]
const Options = ["4%", "8%", "12%", "15%"]

TourTaxTop25Model = {
  options: Options,

  answer: 3,

  GDPPercentForOptions: [131, 120, 108, 99],

  nominalDebtForOptions: [87.2, 79.9, 72.5, 66.9],

  // Prompt

  promptHeadline: (
    <h2>
      Raising taxes on the top 25%
    </h2>
  ),

  promptBody: (
		<p>
      The top 25% of American households earn above $75,000 per year. <Tooltip value="The top 25% receive 71% of pretax income.">The top 25% of taxpayers currently pay 88% of all federal personal income taxes.</Tooltip> How much would their federal taxes need to increase to keep the debt from passing 100% of GDP?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Nope. {answer} wouldn't be enough.
    </h2>
  ),

  wrongAnswerBody: (answer) => (
    <p>
      We would need much higher taxes on the top 25% to achieve our goal. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }We would need to raise taxes by {answer}.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
      We would need to raise federal taxes by {answer} to reach the goal. Unfortunately, that would mean a $1,500 tax hit for many middle class families.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default TourTaxTop25Model

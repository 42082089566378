import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import cx from "classnames"
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Transition } from "react-transition-group";

import JumpToMenu from "../jump-to-menu/jump-to-menu"
import HamburgerMenu from "../hamburger-menu/hamburger-menu"
import LeftMenu from "../../../../common/components/main-menu/main-menu-left"
import RightMenu from "../../../../common/components/main-menu/main-menu-right"

import "../../../../common/components/main-menu/main-menu.css"
import "./main-menu.css"

const JumpToIconSVG = (props) => (
	<svg height="9" viewBox="0 0 15 9" width="15" {...props}>
		<path d="m1167.65685 33.6568542h8v-8" strokeLinecap="square" strokeWidth="2" transform="matrix(.70710678 .70710678 -.70710678 .70710678 -799.85909 -847.800215)"/>
	</svg>
)

const ProgressIndicator = ({path}) => {
	let strokeColor = null
	let pathPrefix = null
	if (path.startsWith("/tour/taxes")) {
		strokeColor = "var(--green-color)"
		pathPrefix = "/tour/taxes"
	} else if (path.startsWith("/tour/spending")) {
		strokeColor = "var(--blue-color)"
		pathPrefix = "/tour/spending"
	} else if (path.startsWith("/tour/economy")) {
		strokeColor = "var(--teal-color)"
		pathPrefix = "/tour/economy"
	}

	if (!strokeColor) {
		return null
	}

	const unique = [...new Set(window.historyStack)];
	const result = unique.filter(p => p.startsWith(pathPrefix));

	let percentage = 0
	const shouldFake = (unique.length === 0)
	const total = shouldFake ? 1 : result.length

	if (pathPrefix === "/tour/taxes") {
		percentage = (total / 6) * 100
	} else if (pathPrefix === "/tour/spending") {
		percentage = (total / 11) * 100
	} else if (pathPrefix === "/tour/economy") {
		percentage = (total / 6) * 100
	}

	return (
    <div className="progress-indicator-wrapper">
      <CircularProgressbar
        percentage={percentage}
        strokeWidth={50}
        textForPercentage={null}
        styles={{
          path: { strokeLinecap: "butt", stroke: strokeColor },
          text: { fill: "#fff" },
        }}
      />
    </div>
	)
}

class MainMenu extends Component {
	static propTypes = {
		isMobile: PropTypes.bool.isRequired,
		history: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
		this.unlisten = null
		this.state = {
			showJumpToMenu: false,
			showHamburgerMenu: false,
		}
	}

	componentDidMount() {
		// Hide menus when route gets pushed to history stack:
		this.unlisten = this.props.history.listen((location, action) => {
			if (this.state.showJumpToMenu || this.state.showHamburgerMenu) {
				this.setState({
					showJumpToMenu: false,
					showHamburgerMenu: false,
				})
			}
		})
	}

	componentWillUnmount() {
		this.unlisten && this.unlisten()
	}

	render() {
		const mainMenuClasses = cx({
			"is-mobile": this.props.isMobile,
			"is-desktop": !this.props.isMobile,
		})

		const jumpToIconClasses = cx({
			"jump-to-icon": true,
			flipped: this.state.showJumpToMenu,
		})

		const transitionStyles = {
			entered:  { transform: "translateX(0)" },
			exiting: { transform: "translateX(-100%)" },
		};

		return (
			<nav id="main-menu" className={mainMenuClasses}>
				<div id="main-menu-bar">
					<LeftMenu
						isMobile={this.props.isMobile}
						onHamburgerClick={this.handleHamburgerClick}
					/>
					{ this.props.isMobile &&
					<RightMenu
						isMobile={this.props.isMobile}
					>
						<a href="#jumpto" className="jump-to-btn" onClick={this.handleJumpToClick}>
							<ProgressIndicator path={this.props.location.pathname} />
							<span className="jump-to-label">Shortcuts</span>
							<JumpToIconSVG className={jumpToIconClasses}/>
						</a>
					</RightMenu>
					}
					{ !this.props.isMobile &&
					<RightMenu
						isMobile={this.props.isMobile}
					>
						<Link to="/tour" className="explore-btn">Tour the Budget</Link>
						<a href="#jumpto" className="jump-to-btn" onClick={this.handleJumpToClick}>
							<ProgressIndicator path={this.props.location.pathname} />
							<span className="jump-to-label">Shortcuts</span>
							<JumpToIconSVG className={jumpToIconClasses}/>
						</a>
						<div className="divider" />
            <a href="https://budgetmatters.americaoffbalance.org" className="build-btn">Budget Matters</a>
						<a href="https://calculator.americaoffbalance.org" className="build-btn">Fix the Budget</a>
					</RightMenu>
					}
				</div>

				{ this.state.showJumpToMenu &&
					<JumpToMenu
						isMobile={this.props.isMobile}
						path={this.props.location.pathname}
					/>
				}
				<Transition
					in={this.state.showHamburgerMenu}
					timeout={200}
					unmountOnExit
				>
					{ state => {
						return (
							<HamburgerMenu
								style={{
									...transitionStyles[state]
								}}
								isMobile={this.props.isMobile}
								onDismissClick={this.handleHamburgerClick}
							/>
						)
					}}
				</Transition>
			</nav>
		)
	}

	handleJumpToClick = (e) => {
		e.preventDefault()
		const show = !this.state.showJumpToMenu
		this.setState({
			showJumpToMenu: show,
			showHamburgerMenu: false,
		})
	}

	handleHamburgerClick = (e) => {
		e.preventDefault()
		const show = !this.state.showHamburgerMenu
		this.setState({
			showHamburgerMenu: show,
			showJumpToMenu: false,
		})
	}
}

export default MainMenu


import React from "react"

import Facts from "../../facts" // eslint-disable-line no-unused-vars
import Tooltip from "../../../common/components/tooltip/tooltip" // eslint-disable-line no-unused-vars

let TourTaxCorporations = {}

const YearDebtExceedsGDP = [2040, 2043, 2046, 2050]
const Options = ["45%", "60%", "75%", "90%"]

TourTaxCorporations = {
  options: Options,

  answer: 3,

  GDPPercentForOptions: [121, 114, 107, 99],

  nominalDebtForOptions: [80.9, 76.3, 71.7, 67.0],

  // Prompt

  promptHeadline: (
    <h2>
      Raising taxes on corporations only
    </h2>
  ),

  promptBody: (
		<p>
		  The top federal tax rate for corporations is 21%. How much would we have to raise it to in order to meet our goal?
    </p>
  ),

  // Wrong Answer

  wrongAnswerHeadline: (answer) => (
    <h2>
      Nope. {answer} wouldn't be enough.
    </h2>
  ),

  wrongAnswerBody: (answer) => (
    <p>
			Corporate profits would need to be taxed even higher to keep debt in check. Debt as a share of GDP would exceed 100% in {YearDebtExceedsGDP[Options.findIndex(v => v === answer)]}.
    </p>
  ),

  // Correct Answer

  correctAnswerHeadline: (gotItRight, answer) => (
    <h2>
      { gotItRight ? "Correct! " : "" }We would need to raise corporate taxes by {answer}.
    </h2>
  ),

  correctAnswerBody: (answer) => (
    <p>
			The federal government is projected to collect $245 billion in corporate tax revenue in 2019. To raise enough revenue to keep the debt below 100%, you would have to increase that by approximately 90% - immediately. That means rates higher than any other country in the world.
    </p>
  ),

  answerScrollIndicatorLabel: "What else can we do?",
}

export default TourTaxCorporations

import React from "react"
import { PickerMenu, PickerMenuButton, SkipToAWayForwardMenuButton } from "../common/components/picker-menu/picker-menu"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"

import "./economy-what-else-menu.css"

const LinkedPickerMenuButton = ({label, to, path, ...rest}) => {
	if (path === to) { 
		return null
	}

	const isVisited = window.historyStack.indexOf(to) >= 0 ? true : false

	return (
		<Link to={to}>
			<PickerMenuButton label={label} isVisited={isVisited} {...rest}/>
		</Link>
	)
}

const EconomyWhatElseMenu = ({ isMobile, match }) => (
  <PickerMenu isMobile={isMobile}>
    <LinkedPickerMenuButton label="Tax Reform" to="/tour/economy/tax-reform" path={match.path} />
    <LinkedPickerMenuButton label="Regulatory Reform" to="/tour/economy/regulatory-reform" path={match.path} />
    <LinkedPickerMenuButton label="Entitlement Reform" to="/tour/economy/entitlement-reform" path={match.path} />
    <LinkedPickerMenuButton label="Increase Immigration" to="/tour/economy/increase-immigration" path={match.path} />
    <LinkedPickerMenuButton label="Repair Infrastructure" to="/tour/economy/infrastructure" path={match.path} />
    <SkipToAWayForwardMenuButton />
  </PickerMenu>
)

export default withRouter(EconomyWhatElseMenu)

import React from "react"
import { PickerMenu, PickerMenuButton, SkipToGrowingEconomyMenuButton } from "../common/components/picker-menu/picker-menu"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"

import "./spending-what-else-menu.css"

const LinkedPickerMenuButton = ({label, to, path, ...rest}) => {
	if (path === to) { 
		return null
	}

	const isVisited = window.historyStack.indexOf(to) >= 0 ? true : false

	return (
		<Link to={to}>
			<PickerMenuButton label={label} isVisited={isVisited} {...rest}/>
		</Link>
	)
}

const SpendingWhatElseMenu = ({ isMobile, match }) => (
  <PickerMenu isMobile={isMobile}>
    <div className="menu-row">
      <LinkedPickerMenuButton label="Discretionary Spending" to="/tour/spending/cut-discretionary-spending" className="is-divider" path={match.path} />
      <LinkedPickerMenuButton label="Defense" to="/tour/spending/cut-defense" path={match.path} />
      <LinkedPickerMenuButton label="Foreign Aid" to="/tour/spending/cut-foreign-aid" path={match.path} />
      <LinkedPickerMenuButton label="EPA" to="/tour/spending/cut-epa" path={match.path} />
      <LinkedPickerMenuButton label="Amtrak" to="/tour/spending/cut-amtrak" path={match.path} />
    </div>
    <div className="menu-row">
      <LinkedPickerMenuButton label="Mandatory Spending" to="/tour/spending/cut-mandatory-spending" path={match.path} />
      <LinkedPickerMenuButton label="Social Security" to="/tour/spending/reform-social-security" path={match.path} />
      <LinkedPickerMenuButton label="Medicaid" to="/tour/spending/reform-medicaid" path={match.path} />
      <LinkedPickerMenuButton label="Medicare" to="/tour/spending/reform-medicare" path={match.path} />
    </div>
    <div className="menu-row">
      <SkipToGrowingEconomyMenuButton />
    </div>
  </PickerMenu>
)

export default withRouter(SpendingWhatElseMenu)

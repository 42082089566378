import React from "react"
import ScrollIndicatorWrapper from "../../common/components/scroll-indicator/scroll-indicator"
import ScrollPercentage from "react-scroll-percentage"
import StickySidebarWrapper from "../../common/components/sticky-sidebar-wrapper/sticky-sidebar-wrapper"
import SpendingGraph from "./spending-graph/spending-graph"
import SendingWhatNext from "../spending-what-next"

import Model from "../../../config/tour/spending/tour-spending-history-model"

import "./spending-history.css"

const SpendingHistoryStep = ({model, nextModel, onViewChange}) => {
  const scrollIndicatorLabel = nextModel ? nextModel.year : "What else can we do?"
  return (
    <ScrollPercentage onChange={onViewChange}>
      <ScrollIndicatorWrapper label={scrollIndicatorLabel}>
        <section className="row-content full-page-w-menu w-sidebar v-center">
          <div className="content">
            <div className="scroll-anchor scroll-anchor-impl" />
            {model.title}
            <h1>{model.year}</h1>
            {model.body}
          </div>
        </section>
      </ScrollIndicatorWrapper>
    </ScrollPercentage>
  )
}

class SpendingHistory extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      activeModelIndex: null,
    }
  }

  buildOnStepViewChange(i) {
    return (percent, inView) => {
      if (inView) {
        if (i === null && percent > 0.3 && percent < 0.7) {
          // Intro screen is visible
          this.setActiveModelIndex(null)
        } else if (i !== null && percent > 0.3 && percent < 0.7) {
          this.setActiveModelIndex(i)
        }
      }
    }
  }

  setActiveModelIndex(i) {
    if (this.state.activeModelIndex !== i) {
      this.setState({
        activeModelIndex: i,
      })
    }  
  }

  renderSteps(steps) {
    return steps.map((s, i) => {
      const nextModel = i + 1 < steps.length ? steps[i + 1] : null
      return (
        <SpendingHistoryStep key={"step-" + i} model={s} nextModel={nextModel} onViewChange={this.buildOnStepViewChange(i)} />
      )
    })
  }

  getActiveModelBreakdown() {
    if (this.state.activeModelIndex !== null) {
      const am = Model.steps[this.state.activeModelIndex]
      if (am) {
        return am.spendingBreakdown
      }
    }

    return Model.defaultSpendingBreakdown
  }

  render() {
    const steps = Model.steps

		const sidebar = (
			<ScrollPercentage threshold={0}>
			{({percentage}) => {
				return(
          <SpendingGraph 
            isMobile={this.props.isMobile} 
            model={this.getActiveModelBreakdown()} 
            isHidden={!this.props.isMobile &&  (percentage < 0.8 || percentage === 1.0)}
            />
				)
			}}
		  </ScrollPercentage>
    )

    return (
      <StickySidebarWrapper key="spending-history-wrapper" sidebar={sidebar} isMobile={this.props.isMobile}>
        <ScrollPercentage onChange={this.buildOnStepViewChange(null)}>
          <ScrollIndicatorWrapper label={steps[0].year}>
            <section className="row-content full-page-w-menu w-sidebar v-center">
              <div className="content">
                <div className="scroll-anchor scroll-anchor-impl" />
                {Model.headline}
                {Model.body}
              </div>
            </section>
          </ScrollIndicatorWrapper>
        </ScrollPercentage>
        {this.renderSteps(steps)}
      </StickySidebarWrapper>
    )
  }
}

const SpendingHistorySection = ({isMobile}) => {
	return [
    <div id="spending-history" key="spending-history">
      <SpendingHistory isMobile={isMobile} />
    </div>,
    <SendingWhatNext key="spending-what-else" isMobile={isMobile}/>
  ]
}

export default SpendingHistorySection
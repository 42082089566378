// Note: must stay in sync with common.css

export default {
 GREEN_COLOR: "#22A750",
 BLUE_COLOR: "#2E07A9",
 RED_COLOR: "#EC654D",
 WHITE_COLOR: "#FFF",
 BLACK_COLOR: "#000",

 MOBILE_BREAKPOINT: 813,
 MOBILE_BAR_HEIGHT: 48,
 DESKTOP_BAR_HEIGHT: 80,
}
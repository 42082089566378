import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import "./sticky-sidebar-wrapper.css"

const StickySidebarWrapper = ({sidebar, isMobile, children}) => {
	const parentClasses = cx({
		"sticky-sidebar-wrapper": true,
		"is-mobile": isMobile,
		"is-desktop": !isMobile,
	})

	const contentClasses = cx({
		"sticky-sidebar-content": true,
		"column-70": !isMobile,
	})

	const sidebarClasses = cx({
		"sticky-sidebar": true,
		"column-30": !isMobile,
	})

	return (
		<div className={parentClasses}>
			<aside className={sidebarClasses}>
				{sidebar}
			</aside>
			<main className={contentClasses}>
				{children}
			</main>
		</div>
	)
}

StickySidebarWrapper.propTypes = {
	sidebar: PropTypes.element.isRequired,
	isMobile: PropTypes.bool.isRequired,
}

export default StickySidebarWrapper

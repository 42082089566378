import React from "react"

import { PollContainer, PollContainerType } from "../../common/components/poll-container/poll-container"
import PollSection from "../../common/components/poll-container/poll-section"
import WrongAnswerSection from "../../common/components/poll-container/wrong-answer-section"
import AnswerSection from "../../common/components/poll-container/answer-section"
import WhatElse from "../spending-what-else"
import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

import Model from "../../../config/tour/spending/tour-cut-defense-model"

const Poll = (props) => (
	<PollSection isFirstPage {...props}>
		{Model.promptHeadline}
		{Model.promptBody}
	</PollSection>
)

const WrongAnswer = (props) => (
	<WrongAnswerSection {...props}>
		{ notEnough => {
        const d = props.debtGDPPercent - props.debtGDPPercentForOptions[props.answer]
        const diffP = Math.round(d*10)/10
        return (
          <React.Fragment>
						{Model.wrongAnswerHeadline(props.options[props.answer])}
						{Model.wrongAnswerBody(props.options[props.answer], diffP)}
        	</React.Fragment>
        )
			}
		}
	</WrongAnswerSection>
)

const Answer = (props) => (
	<AnswerSection scrollIndicatorLabel="What else can we do?" {...props}>
		{ gotItRight => (
			<React.Fragment>
				{Model.correctAnswerHeadline(gotItRight, props.options[props.correctAnswer])}
				{Model.correctAnswerBody(props.options[props.correctAnswer])}
			</React.Fragment>
		)}
	</AnswerSection>
)

const Page = ({isMobile}) => (
	<SideMenuWrapper key="cut-defense-page-wrapper">
		<div key="cut-defense-page" id="tour-spending-container">
			<PollContainer 
				isMobile={isMobile}
				options={Model.options}
				nominalDebtForOptions={Model.nominalDebtForOptions}
				correctAnswer={Model.answer}
				debtGDPPercentForOptions={Model.GDPPercentForOptions}
				componentForType={ type => {
					switch(type) {
						case PollContainerType.POLL:
							return Poll
						case PollContainerType.WRONGANSWER:
							return WrongAnswer
						case PollContainerType.ANSWER:
							return Answer
						case PollContainerType.WHATELSE:
							return WhatElse
						default:
							throw new Error("Unknown poll container type: " + type);		
					}
				}}
			/>
		</div>
	</SideMenuWrapper>
)

export default Page

import React from "react"
import { max, last } from "lodash"

import ScrollIndicatorWrapper from "../common/components/scroll-indicator/scroll-indicator"
import BackgroundGraph from "../common/components/background-graph/background-graph"

import Facts from "../../config/facts"
import Format from "../../common/utils/format"

import "./taxes-landing.css"

import TourTaxesIntroModel from "../../config/tour/taxes/tour-taxes-intro-model"
const Model = TourTaxesIntroModel.LandingSection

const taxIncomeTrillions = Facts.econ.projectedRevenueTrillions
const m = max(taxIncomeTrillions)
const points = taxIncomeTrillions.map(x => x / m)

const projectedTaxIncome = last(Facts.econ.projectedRevenueTrillions) * Facts.const.trillion
const projectedTaxIncomeLabel = Format(projectedTaxIncome, true, 0)

const TaxesLanding = ({isMobile}) => (
	<ScrollIndicatorWrapper 
		id="taxes-landing-wrapper" 
		label={Model.scrollIndicatorLabel}>
		<section 
			id="taxes-landing" 
			className="full-page-w-menu is-first-page has-scroll-indicator v-center">
			<div className="content">
				{Model.headline}
				{Model.body}
			</div>
			<BackgroundGraph 
				points={points}
				fromYear={Facts.econ.year}
				toYear={Facts.econ.projectedYear}
				graphLabel="Total Revenue"
				isMobile={isMobile}
				toValueLabel={projectedTaxIncomeLabel}
			/>
		</section>
	</ScrollIndicatorWrapper>
)
export default TaxesLanding

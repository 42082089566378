import React, { Component } from "react"
import PropTypes from "prop-types"

import "./poll.css"

const PollRow = ({ label, index, checked, onChange }) => {
	const key = label + "-" + index
	return (
		<li key={key}>
			<input type="radio" id={key} name="option" value={index} defaultChecked={checked} onChange={onChange}/>
			<label htmlFor={key}>{label}</label>
		</li>
	)
}

class Poll extends Component {

	static propTypes = {
		options: PropTypes.array.isRequired,
		answer: PropTypes.number,
		onChange: PropTypes.func.isRequired,
	}

	render() {
		return (
			<form>
				<ul className="poll">
					{this.props.options.map((label, index) => {
						const checked = (index === this.props.answer ? true : false)
						return PollRow({label, index, checked, onChange: this.props.onChange })
					})}
				</ul>
			</form>
		)
	}
}

export default Poll

import React from "react"
import cx from "classnames"
import { Link } from "react-router-dom"

import "./picker-menu.css"

const PickerMenu = ({ isMobile, children }) => {
	const pickerMenuClasses = cx({
		"picker-menu": true,
		"is-mobile": isMobile,
		"is-desktop": !isMobile,
	})
	return (
		<nav className={pickerMenuClasses}>
			{children}
		</nav>
	)
}

const PickerMenuButton = ({ label, isVisited, onClick }) => {
	const btnClasses = cx({
		"picker-menu-btn": true,
		"is-visited": isVisited,
	})

	return(
		<button className={btnClasses} onClick={onClick}>
			{label}
		</button>
	)
}

const SkipToSpendingMenuButton = () => {
	const btnClasses = cx({
		"picker-menu-btn": true,
		"is-skip-btn": true,
		"spending": true,
	})

	return(
		<Link to="/tour/spending">
			<button className={btnClasses}>
				Jump to Cutting Spending
			</button>
		</Link>
	)
}

const SkipToGrowingEconomyMenuButton = () => {
	const btnClasses = cx({
		"picker-menu-btn": true,
		"is-skip-btn": true,
		"economy": true,
	})

	return(
		<Link to="/tour/economy">
			<button className={btnClasses}>
				Jump to Growing the Economy
			</button>
		</Link>
	)
}

const SkipToAWayForwardMenuButton = () => {
	const btnClasses = cx({
		"picker-menu-btn": true,
		"is-skip-btn": true,
		"a-way-forward": true,
	})

	return(
		<Link to="/tour/a-way-forward">
			<button className={btnClasses}>
				Jump to A Way Forward
			</button>
		</Link>
	)
}

export { PickerMenu, PickerMenuButton, SkipToSpendingMenuButton, SkipToGrowingEconomyMenuButton, SkipToAWayForwardMenuButton }

import React from "react"

import Landing from "./reform-social-security-landing"
import WhyIsSpendingRising from "./why-is-spending-rising"
import ReformSocialSecurityStart from "./reform-social-security-start"
import SocialSecurityReformAccordion from "./social-security-reforms-accordion"
import SpendingWhatElse from "../spending-what-else"
import SideMenuWrapper from "../../common/components/main-side-menu/main-side-menu-wrapper"

const ReformSocialSecurityPage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="cut-social-security-page-wrapper">
			<div id="tour-spending-container">
				<div id="tour-reform-social-security-page">
					<Landing />
					<WhyIsSpendingRising />
					<SocialSecurityReformAccordion key="social-security-reform-accoreon" isMobile={isMobile}/>
					<ReformSocialSecurityStart isMobile={isMobile} nextSection={[
						<SpendingWhatElse key="spending-what-else" isMobile={isMobile}/>
					]}/>
				</div>
			</div>
		</SideMenuWrapper>
	)
}

export default ReformSocialSecurityPage

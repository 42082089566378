import React from "react"

import Landing from "./taxes-landing"
import TaxEveryonePage from "./tax-everyone/tax-everyone"
import TaxesWhatNext from "./taxes-what-next"
import SideMenuWrapper from "../common/components/main-side-menu/main-side-menu-wrapper"
import KeepInMind from "./keep-in-mind"

import "./tour-taxes-container.css"

const TaxesPage = ({isMobile}) => {
	return (
		<SideMenuWrapper key="taxes-page-wrapper">
			<div id="tour-taxes-container">
				<div id="tour-taxes-page" className="tour-poll-container">
					<Landing isMobile={isMobile}/>
					<KeepInMind />
					<TaxEveryonePage isMobile={isMobile} nextSection={[
						<TaxesWhatNext key="taxes-what-else" isMobile={isMobile}/>
					]}/>
				</div>
			</div>
		</SideMenuWrapper>
	)
}

export default TaxesPage
